import { useEffect, useState } from "react";
import { CheckboxCustom, Img, Input, List, Radio, Text } from "components";
import DesignMyOwnCakeQuestionOneHeader from "components/DesignMyOwnCakeQuestionOneHeader";
import Footer1 from "components/Footer1";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import StepProgress from "components/StepProgress";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import * as API from "../../utils/helper/Enum";
import { setSnackbar } from "../../redux/reducers/snackbar";
import * as CUSTOM from "../../utils/helper/custom";
import { InputAdornment } from "@material-ui/core";
import EmptyAll from "pages/EmptyList";
import { ImageCropper } from "popups/ImageCropper";
import DesignMyOwnCakeSummaryPage from "pages/DesignMyOwnCakeSummary";
const Box = styled.div`
  /* Add the selected styles when the component is selected */
  ${({ isSelected }) =>
    isSelected &&
    ` 
border-color: #BD0043 !important;
`}
`;

const DesignMyOwnCakeQuestionOnePage = () => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [imageForCrop, setImageForCrop] = useState("");
  const [allSelectedImages, setAllSelectedImages] = useState([]);
  const [list, setList] = useState([]);
  const [settingData, setSettingData] = useState(null);
  const [reference, setReference] = useState("");
  const [textOnCake, setTextOnCake] = useState("");
  const [step, setStep] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [activeId, setActiveId] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [apiStatus, setApiStatus] = useState(false);
  const [allInputData, setAllInputData] = useState({});

  /**
   * get selected items total price
   */
  const getTotalPrice = () => {
    return selectedData.reduce((totalPrice, itemss) => {
      const groupdata = list.find((items) => items?.id === itemss?.group_id);
      if (groupdata) {
        totalPrice += groupdata.modifierGroupItems
          .filter((items) => itemss?.items.includes(items?.id))
          .reduce((subtotal, items) => subtotal + items?.price, 0);
      }
      totalPrice.toFixed(2);
      return totalPrice;
    }, 0);
  };

  /**
   * get estimate price
   */
  const getEstimate = () => {
    setAllInputData({
      cake_details: selectedData,
      upload_image_links: allSelectedImages,
      image_link: reference,
      text_on_cake: textOnCake,
      id: allInputData?.id ? allInputData?.id : "",
      textOnCake: textOnCake,
    });
    setStep("getEstimate");
  };

  /**
   * get question list
   */
  const getQuestionList = async () => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.CUSTOMCAKE.CUSTOM_CAKE_QUESTIONS_LIST}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        setList(response?.data);
        setSettingData(response?.setting_data);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    setApiStatus(true);
  };

  /**
   * check and store id
   */
  const addCartItemsState = (group, itemId) => {
    const existingModifierIndex = selectedData?.findIndex(
      (modifier) => modifier?.group_id === group?.id
    );
    if (existingModifierIndex === -1) {
      const newModifier = {
        group_id: group?.id,
        items: [itemId],
      };
      setSelectedData((prevSelectedData) => [...prevSelectedData, newModifier]);
    } else {
      if (checkedChekcbox(group, itemId) == false) {
        let min = group?.min;
        let max = group?.max;
        if (group?.type == "single") {
          max = 1;
        }
        let dataGetLength = selectedData[existingModifierIndex]?.items.length;
        let removeLastItem = false;
        if (dataGetLength == max && max != 0) {
          removeLastItem = true;
        }
        let newElement = itemId;
        const updatedData = selectedData.map((item) => {
          if (item.group_id === group?.id) {
            if (removeLastItem) {
              let itemArray = [...item.items];
              itemArray.pop();
              itemArray.push(newElement);
              return {
                ...item,
                items: itemArray,
              };
            } else {
              return {
                ...item,
                items: [...item.items, newElement],
              };
            }
          }
          return item;
        });
        setSelectedData(updatedData);
      } else {
        let elementToRemove = itemId;
        const updatedData = selectedData.map((item) => {
          if (item.group_id === group?.id) {
            return {
              ...item,
              items: item.items.filter((item) => item !== elementToRemove),
            };
          }
          return item;
        });
        setSelectedData(updatedData);
      }
    }
  };

  /**
   * check checked checkbox
   */
  const checkedChekcbox = (group, itemId) => {
    const existingModifierIndex = selectedData?.findIndex(
      (modifier) => modifier?.group_id === group?.id
    );
    if (existingModifierIndex !== -1) {
      let dataGetLength = selectedData[existingModifierIndex]?.items;
      if (dataGetLength.includes(itemId)) {
        return true;
      }
    }
    return false;
  };

  /**
   * check disabled button
   */
  const checkDisableButton = (group) => {
    let min = group?.min;
    console.log(min);
    if (group?.type == "single") {
      min = 1;
    }
    let existingModifierIndex = selectedData?.findIndex(
      (modifier) => modifier?.group_id === group?.id
    );
    let totalItems = 0;
    if (existingModifierIndex === -1) {
      if (totalItems >= min) {
        return false;
      }
      return true;
    } else {
      totalItems = selectedData[existingModifierIndex].items.length;
      if (totalItems >= min) {
        return false;
      }
    }
    return true;
  };

  /**
   * handle crop image
   */
  const handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let imageError = CUSTOM.checkFileExtention(
      file.name,
      "png|jpg|jpeg",
      "",
      ""
    );
    if (imageError) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: imageError,
          snackbarState: "error",
        })
      );
      document.getElementById("image-upload-field").value = "";
      return false;
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      getCompressedImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    document.getElementById("image-upload-field").value = "";
  };

  const getCompressedImage = (base64Url) => {
    const outputQuality = 0.9; // Initial quality (adjust as needed)
    const maxSizeInBytes = 0.7 * 1024 * 1024; // 1MB

    const img = new Image();
    img.src = base64Url;
    img.onload = () => {
      // Create a canvas and draw the image on it
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      const tryCompress = (quality) => {
        return new Promise((resolve) => {
          canvas.toBlob(
            (blob) => {
              if (blob.size <= maxSizeInBytes || quality <= 0.1) {
                resolve(blob);
              } else {
                tryCompress(quality - 0.1).then(resolve);
              }
            },
            "image/jpeg",
            quality
          );
        });
      };

      tryCompress(outputQuality).then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          getCroppedImage(base64String);
        };
        reader.readAsDataURL(blob);
      });
    };
  };

  /**
   * get cake details by id
   */
  const getCakeDetailsById = async (id) => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.CUSTOMCAKE.GET_CAKE_DETAILS}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        const organizedData = response?.data?.cakeDetails.map((cakeDetail) => {
          const groupId = cakeDetail.id;
          const items = cakeDetail.modifierGroupItems.map((item) => item.id);
          return { group_id: groupId, items: items };
        });
        setSelectedData(organizedData);
        setTextOnCake(response?.data?.textOnCake);
        setReference(response?.data?.referenceLink);
        setAllSelectedImages(response?.data?.imageLinks);
        setAllInputData({ ...allInputData, id: response?.data?.id });
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const getCroppedImage = async (image) => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.CUSTOMCAKE.UPLOAD_IMAGE}`,
        "post",
        { image: image },
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        const updatedImages = [...allSelectedImages, response?.data];
        setAllSelectedImages(updatedImages);
        setImageForCrop("");
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
  };

  useEffect(() => {
    setTotalPrice(getTotalPrice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  /**
   * get question list
   */
  useEffect(() => {
    getQuestionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * empty image field when close modal crop popup
   */
  useEffect(() => {
    if (!imageForCrop) {
      const imageUploadField = document.getElementById("image-upload-field");
      if (imageUploadField) {
        imageUploadField.value = "";
      }
    }
  }, [imageForCrop]);

  /**
   * change steps
   */
  useEffect(() => {
    if (activeId == 0) {
      setStep("");
    }
  }, [activeId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (id && list.length > 0) {
      getCakeDetailsById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <>
      {step != "getEstimate" ? (
        <div className="bg-gray-50_02 flex flex-col items-center justify-start mx-auto w-full">
          <DesignMyOwnCakeQuestionOneHeader
            className="bg-white-A700 flex flex-row items-center justify-start p-1.5 shadow-bs w-full fixed top-0 left-0 z-10"
            p0={Number(activeId) + 1}
            p13={list.length + 1}
            exittext={t("exit")}
            allInputData={allInputData}
          />
          <div className="h-1 overflow-hidden relative w-full mt-[64px]">
            <StepProgress
              totalSteps={list.length}
              activeStep={Number(activeId) + 1}
            />
          </div>
          <div className="content-area flex-1 overflow-y-auto w-full">
          {list.length == 0 && apiStatus ? (
            <EmptyAll type="custom-cake" />
          ) : null}
          {Number(activeId) + 1 <= list.length ? (
            <>
              {list?.map((items, index) => {
                if (activeId == index) {
                  return (
                    <div
                      className="min-height-question"
                      key={`mainindexing-${index}`}
                    >
                      <Text
                        className="mt-9 text-2xl md:text-[22px] text-black-900 sm:text-xl"
                        size="txtnunitoSemiBold24"
                      >
                        {CUSTOM.getdataByLangKey(
                          items.modifierGroupLocales,
                          "",
                          "name"
                        )}
                      </Text>
                      <Text
                        className="mt-[7px] text-gray-700 text-sm"
                        size="txtnunitoRegular14"
                      >
                        {items?.type == "single" ? (
                          <>
                            {t("chooseAny")} 1 {t("optionSmallLetter")}
                          </>
                        ) : null}

                        {items?.type == "multiple" &&
                        items?.min == 0 &&
                        items?.max == 0 ? (
                          <>{t("chooseMutipleOptions")}</>
                        ) : null}
                        {items?.type == "multiple" &&
                        items?.min == 0 &&
                        items?.max != 0 ? (
                          <>
                            {CUSTOM.replaceKeyValue(
                              t("chooseMaxOption"),
                              "[VALUE]",
                              items?.max
                            )}
                          </>
                        ) : null}
                        {items?.type == "multiple" &&
                        items?.min != 0 &&
                        items?.max == 0 ? (
                          <>
                            {CUSTOM.replaceKeyValue(
                              t("chooseMinOption"),
                              "[VALUE]",
                              items?.min
                            )}
                          </>
                        ) : null}

                        {items?.type == "multiple" &&
                        items?.min != 0 &&
                        items?.max != 0 ? (
                          <>
                            {CUSTOM.replaceKeyValue(
                              t("cooseMinMAxOption"),
                              "['MIN','MAX']",
                              "[" + items?.min + "," + items?.max + "]",
                              "array"
                            )}
                          </>
                        ) : null}
                      </Text>
                      <List
                        className="flex flex-col font-nunitoregular gap-4 items-start mt-[29px] pb-4 md:px-4 xs:w-[100%] w-full max-w-[540px] min-h-[calc(100vh_-_305px)]"
                        orientation="vertical"
                      >
                        {items?.modifierGroupItems.map((item, indexing) => {
                          return (
                            <Box
                              key={`indexing-${indexing}`}
                              className="bg-white-A700 border border-gray-300 border-solid flex flex-row items-center justify-between p-4 rounded w-full cursor-pointer"
                              isSelected={checkedChekcbox(items, item?.id)}
                              onClick={() => addCartItemsState(items, item?.id)}
                            >
                              <div className="flex items-center">
                                {items?.type == "single" ? (
                                  <Radio
                                    className="mr-0 rtl:mr-0 rtl:ml-0"
                                    checked={checkedChekcbox(items, item?.id)}
                                    value={item?.id}
                                  />
                                ) : (
                                  <CheckboxCustom
                                    className="mr-0 rtl:mr-0 rtl:ml-0"
                                    checked={checkedChekcbox(items, item?.id)}
                                    value={item?.id}
                                  />
                                )}

                                <Text
                                  className="mx-[14px] text-basetext-black-900"
                                  size="txtnunitoRegular16"
                                >
                                  {CUSTOM.getdataByLangKey(
                                    item?.modifierGroupItemLocales,
                                    "",
                                    "name"
                                  )}
                                </Text>
                              </div>
                              <div className="flex flex-row gap-2 items-center justify-center mr-[3px]">
                                {/* <Text
                     className="line-through text-gray-700 text-right text-sm"
                     size="txtnunitoRegular14"
                   >
                     {item?.actualPrice}
                   </Text> */}
                                <Text
                                  className="text-base text-black-900 text-right"
                                  size="txtnunitoRegular16"
                                >
                                  {t("sar")} {item?.price}
                                </Text>
                              </div>
                            </Box>
                          );
                        })}
                      </List>
                      
                      <Footer1
                        className="bg-white-A700 flex font-nunitomedium items-center justify-center mt-[32px] md:px-4 shadow-bs w-full fixed bottom-0 left-0 z-10"
                        activeId={index}
                        totalPrice={totalPrice}
                        setActiveId={setActiveId}
                        disableButton={checkDisableButton(items)}
                      />
                    </div>
                  );
                }
              })}
            </>
          ) : null}
          </div>
          {list.length < activeId + 1 && list.length ? (
            <>
              <div className="min-height-question sm:px-4">
                <div>
                  <Text
                    className="mt-[37px] text-2xl md:text-[22px] text-black-900 sm:text-xl"
                    size="txtnunitoSemiBold24"
                  >
                    {t("whatKindOfCakeWouldYouLikeItToResembleQuestionMark")}
                  </Text>
                  <div className="flex flex-row font-nunitoregular gap-2 items-start justify-center mt-[5px] w-full">
                    <Text
                      className="text-gray-700 text-sm"
                      size="txtnunitoRegular14"
                    >
                      {t("youCanSearchCakesOn")}
                    </Text>
                    <Img
                      className="h-5 w-5"
                      src={
                        settingData?.logoLink
                          ? settingData?.logoLink
                          : "/images/img_pinterestsvg.svg"
                      }
                      alt="pinterestsvg"
                    />
                    <a
                      href={
                        settingData?.searchLink
                          ? settingData?.searchLink
                          : "https://in.pinterest.com/search/pins/?q=custom%20cake&rs=typed"
                      }
                      className="text-gray-700 text-sm underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text>{t("pinterestDot")}</Text>
                    </a>
                  </div>
                  {allSelectedImages.length < 5 && (
                    <label className="bg-red-50_01 flex flex-col font-nunitoregular items-center justify-start mt-7 p-5 rounded max-w-[540px] min-w-[540px] sm:min-w-full w-full cursor-pointer ">
                      <Img
                        className="h-9 w-9"
                        src="/images/img_upload.svg"
                        alt="upload"
                      />
                      <Text
                        className="mt-2.5 text-base text-black-900"
                        size="txtnunitoRegular16"
                      >
                        {t("uploadImage")}
                      </Text>
                      <Text
                        className="my-[7px] text-center text-gray-700 text-xs"
                        size="txtnunitoRegular12Gray700"
                      >
                        {t("uploadCakeImagesInPNGSlashJPGSlashJPEGFormatDot")}
                      </Text>
                      <input
                        type="file"
                        accept="image/jpeg, image/jpg, image/png"
                        id="image-upload-field"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleImageChange(e);
                        }}
                      />
                    </label>
                  )}

                  {allSelectedImages.length > 0 && (
                    <List
                      className="flex-wrap flex-row gap-4 flex justify-start mt-3 max-w-[540px] w-full"
                      orientation="horizontal"
                    >
                      {allSelectedImages.map((items, index) => {
                        return (
                          <div className="h-[100px] w-[100px] relative w-full">
                            <Img
                              className="h-[100px] m-auto object-cover rounded-lg w-full"
                              src={
                                items.includes("://")
                                  ? items
                                  : `${process.env.REACT_APP_BASEURL}${items}`
                              }
                              alt="rectangle19120"
                            />
                            <Img
                              className="absolute h-5 right-[2%] top-[4%] w-5 cursor-pointer"
                              src="/images/img_close_gray_900_02.svg"
                              alt="close"
                              onClick={(e) => {
                                const updatedImages = allSelectedImages.filter(
                                  (_, i) => i !== index
                                );
                                setAllSelectedImages(updatedImages);
                              }}
                            />
                          </div>
                        );
                      })}
                    </List>
                  )}
                  <Text
                    className="mt-[33px] text-gray-700 text-sm text-left"
                    size="txtnunitoRegular14"
                  >
                    {t("orPasteAReferenceLinkForYourCustomCake")}
                  </Text>
                  <div className="mt-5 max-w-[540px] w-full">
                    <Input
                      placeholder="Paste a reference link"
                      value={reference}
                      onChange={(e) => {
                        setReference(
                          CUSTOM.strFormat(e.target.value, "MAX:5555555")
                        );
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Img
                            className="h-6 w-6 cursor-pointer"
                            src="/images/insert_link.svg"
                            alt="play"
                          />
                        </InputAdornment>
                      }
                    ></Input>
                  </div>

                  <Text
                    className="mt-[5px] text-gray-700 text-sm text-left"
                    size="txtnunitoRegular14"
                  >
                    {t("nameoncake")}
                  </Text>
                  <div className="mt-5 max-w-[540px] w-full">
                    <Input
                      placeholder={t("nameoncake")}
                      value={textOnCake}
                      onChange={(e) => {
                        setTextOnCake(e.target.value);
                      }}
                    ></Input>
                  </div>
                </div>
              </div>
              <Footer1
                className="bg-white-A700 flex font-nunitomedium items-center justify-center mt-[32px] md:px-4 shadow-bs w-full"
                setActiveId={setActiveId}
                disableButton={
                  (allSelectedImages.length == 0 && !reference) ||
                  (reference && !CUSTOM.isValidField(reference, "url"))
                }
                activeId={activeId}
                showEstimaebutton={1}
                getEstimate={getEstimate}
              />
            </>
          ) : null}
          {imageForCrop && (
            <ImageCropper
              image={imageForCrop}
              allSelectedImages={allSelectedImages}
              setAllSelectedImages={setAllSelectedImages}
              setImageForCrop={setImageForCrop}
            ></ImageCropper>
          )}
        </div>
      ) : (
        <DesignMyOwnCakeSummaryPage
          setActiveId={setActiveId}
          allInputData={allInputData}
          isEdit={true}
          questionsData={list}
        ></DesignMyOwnCakeSummaryPage>
      )}
    </>
  );
};

export default DesignMyOwnCakeQuestionOnePage;
