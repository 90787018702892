import { Text, Img, } from "components";
import { useTranslation } from "react-i18next";
import Payment from "./Payment";
import RecipientDetail from "./RecipientDetail";
const MakePaymentWallet = ({orderData,setIsGiftPayment}) => {
  const { t } = useTranslation();
 
  return (
    <>
      <div className="">
        <div className="flex flex-col w-full ">
          <Text
            className="text-2xl md:text-[22px] text-black-900 sm:text-xl mb-7"
            size="txtnunitoSemiBold24"
          >
            {t("payment")}
          </Text>
          <div className="flex flex-row md:flex-col w-full gap-6 mb-8">
            <div className="flex flex-row md:flex-col w-full gap-6 w-full pl-7 rtl:pl-0 rtl:pr-7 relative">
              <div className="relative bg-gray-100_01 py-[18px] px-6 xs:px-4 xs:mb-[148px] w-full">
                <div className="absolute bg-red-100 flex h-9 items-center justify-center -left-7 rtl:-left-[auto] rtl:-right-7 p-1.5 top-6 w-9 z-[1] ">
                  <Img
                    className="h-6"
                    src="/images/account_balance_wallet.svg"
                    alt="account_balance_wallet"
                  />
                </div>
                <div className="absolute border-l-2 mt-2 border-gray-300 border-dashed w-px -left-3 rtl:-left-[auto] rtl:-right-3 z-[0] "></div>
                <Payment setIsGiftPayment={setIsGiftPayment} orderData={orderData}/>
              </div>
            </div>
            {orderData?.orderType=='gift'?(<RecipientDetail details={orderData}/>):null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MakePaymentWallet;
