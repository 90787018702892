import React, { useEffect, useState } from "react";
import { Button, Img, Input, Text, Radio, CheckboxCustom } from "components";
import { SuccessOrderPlaced } from "popups/SuccessOrderPlaced";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import * as CUSTOM from "../../../../utils/helper/custom";
import * as CARDVALIDATION from "../../../../utils/helper/payfortValidation";
import globalRequest from "utils/global/globalRequest";
import * as APIS from "../../../../utils/helper/Enum";
import { changeLoader } from "../../../../redux/reducers/loader";
import { useDispatch } from "react-redux";
import { setSnackbar } from "redux/reducers/snackbar";
import { changeTrigger } from "redux/reducers/trigger";
import ApplePayComponent from "../../../ApplePayComponent/index.jsx";

// const Tab = styled.div`
//   /* Add the selected styles when the component is selected */
//   ${({ isSelected }) => isSelected && ` `}
// `;
// const Panal = styled.div`
//   /* Add the selected styles when the component is selected */
//   ${({ isSelected }) =>
//     isSelected &&
//     `
// display: flex;`}
// `;

const Payment = ({ setIsGiftPayment, orderData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [cardList, setCardList] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [cardError, setCardError] = useState({});
  const [cardTypeError, setCardTypeError] = useState({});
  let [cardData, setCardData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [tabbyData, setTabbyData] = useState({});
  const [tabbyError, setTabbyError] = useState({});
  const [SuccessOrderPlacedOpen, setSuccessOrderPlacedOpen] =React.useState(false);
  const [showApplePayButton, setShowApplePayButton] = useState(false);
  const [applePayDetail, setApplePayDetail] = useState({});
  // const handelsetSuccessOrderPlacedOpen = () => {
  //   setSuccessOrderPlacedOpen(true);
  //   document.body.classList.add("overflow-hidden");
  //   document.getElementById("header-box").classList.add("relative");
  // };

  const handleTabClick = (id) => {
    setCardError({});
    setCardTypeError({});
    setTabbyData({});
    setTabbyError({});
    setCardData({});
    setActiveTab(id);
  };

  /**
   * get user wallet balance
   */
  const getWalletBalance = async () => {
    try {
      let response = await globalRequest(
        APIS.USERS.GET_WALLET_BALANCE,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setWalletBalance(response?.data?.wallet_balance);
      } else {
        setWalletBalance(0);
      }
    } catch (e) {
      setWalletBalance(0);
    }
  };

  /**
   * Get saved card list
   */
  const getCardList = async () => {
    try {
      let response = await globalRequest(
        APIS.USERS.GET_SAVED_CARD_LIST,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setCardList(response?.data);
      } else {
        setCardList([]);
      }
    } catch (e) {
      setCardList([]);
    }
  };

  /**
   * Ceheck credit card type
   */
  const checkCartType = (cardNo) => {
    let res = CUSTOM.getCardType(cardNo);
    setCardTypeError(res);
  };

  /**
   * payment process
   */
  const paymentProcess = async (type = "", applePayData, session) => {
    dispatch(changeLoader(true));
    try {
      let cDatas = {
        ...cardData,
        ...paymentData,
        card_security_code: cardData?.token_name ? cardData?.cvv : "",
        payment_method: type ? type : null,
      };
      if (type == "tabby") {
        cDatas.tabby_phone_number = tabbyData?.phone;
        cDatas.tabby_email = tabbyData?.email;
      }
      if (type == "apple_pay") {
        cDatas.applePayData = applePayData;
      }
      let response = await globalRequest(
        APIS.GIFTORDER.GET_ORDER_PAYMENT,
        "post",
        cDatas,
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        if (response?.data?.otp_link) {
          window.location.href = response?.data?.otp_link;
        }
        if (response?.data?.is_card) {
          let formId = document.getElementById("aps_payment_form");
          if (formId) {
            cardData = { ...cardData, ...response?.data };
            setCardData(cardData);
            setTimeout(() => {
              formId.submit();
            }, 100);
          }
        } else if (response?.data?.is_tabby === 1) {
          let formId = document.getElementById("aps_payment_form_tabby");
          if (formId) {
            cardData = { ...tabbyData, ...response?.data };
            setTabbyData(cardData);
            setTimeout(() => {
              formId.submit();
            }, 100);
          }
        } else {
          if (type == "apple_pay") {
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
          }
        } 
        if (response?.data?.order_number) {
          setPaymentData({
            ...paymentData,
            order_number: response?.data?.order_number,
          });
          setSuccessOrderPlacedOpen(true);
          setTimeout(() => {
            setIsGiftPayment(false);
            dispatch(changeTrigger("refetchDetails"));
          }, [2500]);
        }
      } else {
        if (type == "apple_pay") {
          session.completePayment(window.ApplePaySession.STATUS_FAILURE);
        }
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      if (type == "apple_pay") {
        session.completePayment(window.ApplePaySession.STATUS_FAILURE);
      }
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /**
   * verify cart if valid or not for payment
   */
  const verifyCard = () => {
    const newErrors = {};
    if (!cardData?.token_name) {
      if (cardData.card_number) {
        let resValidation = CARDVALIDATION.validateCard(cardData.card_number);
        if (!resValidation?.validity) {
          newErrors.card_number = t("cardNumberError");
        }
      } else {
        newErrors.card_number = t("cardNumberErrorEmpty");
      }
      if (cardData.expiration_date) {
        if (!CARDVALIDATION.validateExpiryDate(cardData.expiration_date)) {
          newErrors.date = t("cardDateError");
        }
      } else {
        newErrors.date = t("cardDateErrorEmpty");
      }
      if (!cardData.card_holder) {
        newErrors.card_holder = t("cardHolderError");
      } else {
        let cartHolderValidation = CARDVALIDATION.validateHolderName(
          cardData.card_holder
        );
        if (!cartHolderValidation?.validity) {
          newErrors.card_holder = t("cardNumberError");
        }
      }
    }
    if (cardData.cvv) {
      if (!cardData.cvv.match(/^\d{3,4}$/)) {
        newErrors.cvv = t("cvvNumberError");
      }
    } else {
      newErrors.cvv = t("cvvNumberErrorEmpty");
    }
    if (Object.keys(newErrors).length == 0) {
      if (!cardData?.token_name) {
        let expdata = cardData?.expiration_date.split("/");
        cardData.edate = `${expdata[1]}${expdata[0]}`;
        setCardData({
          ...cardData,
          edate: `${expdata[1]}${expdata[0]}`,
        });
      }
      paymentProcess("card");
    }
    setCardError(newErrors);
  };

  const verifyTabbyFields = () => {
    let newErrors = {};
    if (tabbyData?.email) {
      if (!CUSTOM.isValidField(tabbyData?.email, "email")) {
        newErrors.email = t("email_name_required");
      }
    } else {
      newErrors.email = t("email_name_required");
    }

    if (tabbyData?.phone) {
      if (tabbyData?.phone.length != 9) {
        newErrors.phone = t("mobile_no_length_error");
      }
    } else {
      newErrors.phone = t("mobile_no_required");
    }

    if (Object.keys(newErrors).length > 0) {
      setTabbyError(newErrors);
      return false;
    }
    paymentProcess("tabby");
  };

  useEffect(() => {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      setShowApplePayButton(true);
    }
  }, []);

  /**
   * get card list & wallet balance
   */
  useEffect(() => {
    getCardList();
    getWalletBalance();
  }, []);

  /**
   * Set payment data in state
   */
  useEffect(() => {
    setPaymentData({
      ...paymentData,
      order_id: orderData?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let applePayDataObj = {};
    if (paymentData?.iswallet) {
      applePayDataObj.amount = CUSTOM.setPrice(
        orderData?.priceDetails?.total_amount -
          walletBalance
      )
    } else {
      applePayDataObj.amount = CUSTOM.setPrice(orderData?.priceDetails?.total_amount);
    }
    setApplePayDetail(applePayDataObj);
  }, [orderData, paymentData?.iswallet, activeTab]);

  return (
    <>
      <form
        method="post"
        action={`${
          process.env.REACT_APP_PAYFORT_MODE == "TEST"
            ? process.env.REACT_APP_PAYFORT_DEVLOPMENT
            : process.env.REACT_APP_PAYFORT_PRODUCTION
        }/FortAPI/paymentPage`}
        id="aps_payment_form"
        name="aps_payment_form"
        className="hidden"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: CUSTOM.paymentFormHtml(cardData),
          }}
        ></span>
      </form>
      <form
        method="post"
        action={`${
          process.env.REACT_APP_PAYFORT_MODE == "TEST"
            ? process.env.REACT_APP_PAYFORT_DEVLOPMENT
            : process.env.REACT_APP_PAYFORT_PRODUCTION
        }/FortAPI/paymentPage`}
        id="aps_payment_form_tabby"
        name="aps_payment_form_tabby"
        className="hidden"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: CUSTOM.paymentFormHtmlTabby(tabbyData),
          }}
        ></span>
      </form>
      <div className="relative flex flex-col w-full">
        <div className="flex flex-row xs:flex-col xs:items-start gap-4 items-center justify-between w-full">
          <div className="flex flex-col gap-1 items-start justify-start flex-1">
            <Text className="text-base text-black-900 font-nunitomedium">
              {t("payment")}
            </Text>
            <div className="flex gap-1 flex-row items-center">
              <Text className="text-black-900 text-sm font-nunitoregular">
                {t("selectAnOptionToPay")}
              </Text>
            </div>
          </div>
          <Text className="text-black-900 text-md font-nunitomedium">
            {t("sar")} {orderData?.priceDetails?.total_amount}
          </Text>
        </div>
        <div className="flex flex-col items-center justify-between w-full mt-4">
          <div className="flex flex-row flex-row items-start justify-start w-full pb-3.5 border-b border-solid border-gray-300 ">
            <CheckboxCustom
              checked={paymentData?.iswallet == 1}
              onChange={(e) => {
                if (e.target.checked) {
                  setPaymentData({ ...paymentData, iswallet: 1 });
                } else {
                  setPaymentData({ ...paymentData, iswallet: 0 });
                }
              }}
            />
            <div className="flex flex-col items-start justify-start flex-1 -ml-4 rtl:ml-0 rtl:-mr-4 mt-2">
              <Text className="text-base text-black-900 font-nunitoregular">
                {t("wallet")}
              </Text>
              <div className="flex flex-row xs:flex-col gap-1 items-start justify-start flex-1">
                <Text className="text-gray-700 text-sm font-nunitoregular">
                  {t("availableBalance")}
                </Text>
                <Text className="text-black-900 text-sm font-nunitomedium">
                  {t("sar")} {CUSTOM.setPrice(walletBalance)}
                </Text>
              </div>
              {orderData?.priceDetails?.total_amount <= walletBalance &&
              paymentData?.iswallet == 1 ? (
                <div className="flex items-start justify-start xs:justify-center pt-4 gap-4 mb-[18px] xs:flex-wrap">
                  <Button
                    className="flex-1 max-w-[280px] xs:text-sm xs:whitespace-nowrap"
                    size="md"
                    variant="FillBlack"
                    onClick={(e) => {
                      paymentProcess("wallet");
                    }}
                  >
                    {t("payNow")} ({t("sar")}{" "}
                    {CUSTOM.setPrice(orderData?.priceDetails?.total_amount)})
                  </Button>
                  <Button
                    className="w-auto min-w-[92px] xs:text-sm"
                    size="md"
                    variant="OutlineBlack"
                    hover={true}
                    hoverclass="bg-black-900"
                    onClick={(e) => {
                      setIsGiftPayment(false);
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {(walletBalance < orderData?.priceDetails?.total_amount &&
          paymentData?.iswallet == 1) ||
        paymentData?.iswallet != 1 ? (
          <div className="flex flex-col items-center justify-between w-full">
            {cardList.map((items, index) => {
              return (
                <div
                  key={`cardkey-${index}`}
                  className="common-pointer flex flex-row flex-row gap-3 items-start justify-start w-full py-3.5 border-b border-solid border-gray-300"
                  isSelected={activeTab == index}
                >
                  <Radio
                    name="paymentMode"
                    checked={activeTab == index}
                    onChange={() => handleTabClick(index)}
                  />
                  <div className="flex flex-col items-start gap-1 justify-start flex-1 mt-0.5">
                    <Text className="text-base text-black-900 font-nunitoregular">
                      {items?.type ? items?.type : "VISA"}
                    </Text>
                    <div className="flex flex-row gap-2 items-center justify-start flex-1">
                      <Text className="text-gray-700 text-sm font-nunitoregular">
                        {items?.cardNumber}
                      </Text>
                      <Text className="text-black-900 text-sm font-nunitomedium">
                        <Img
                          className="h-6"
                          src="/images/img_lightbulb.svg"
                          alt="signal"
                        />
                      </Text>
                    </div>
                    {activeTab == index ? (
                      <div className="flex flex-col items-start justify-start mt-5 mb-[18px] xs:-ml-7">
                        <div className="max-w-[280px] w-full">
                          <Input
                            required
                            label={t("cvv")}
                            type="password"
                            value={cardData?.cvv}
                            error={cardError?.cvv}
                            onChange={(e) => {
                              setCardData({
                                ...cardData,
                                token_name: items?.cardToken,
                                cvv: CUSTOM.strFormat(
                                  e.target.value,
                                  "max:4|number"
                                ),
                              });
                              setCardError({ ...cardError, cvv: "" });
                            }}
                          ></Input>
                        </div>
                        <div className="w-full flex gap-4">
                          <Button
                            className="flex-1 mx-auto xs:w-full max-w-[242px]"
                            size="lg"
                            variant="FillBlack"
                            hover={true}
                            hoverclass="bg-white-A700"
                            onClick={(e) => {
                              verifyCard();
                            }}
                          >
                            {t("payNow")} ({t("sar")}{" "}
                            {CUSTOM.setPrice(
                              orderData?.priceDetails?.total_amount
                            )}
                            )
                          </Button>
                          <Button
                            className="w-auto min-w-[92px]"
                            size="md"
                            variant="OutlineBlack"
                            hover={true}
                            hoverclass="bg-black-900"
                            onClick={(e) => {
                              setIsGiftPayment(false);
                            }}
                          >
                            {t("cancel")}
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}

            <div className="common-pointer flex flex-row flex-row gap-3 items-start justify-start w-full py-3.5 border-b border-solid border-gray-300">
              <Radio
                className="flex"
                name="paymentMode"
                checked={activeTab === 555}
                onChange={() => handleTabClick(555)}
              />
              <div className="flex flex-col items-start gap-1 justify-start flex-1 -mt-1">
                <Text className="text-base text-black-900 font-nunitoregular">
                  {t("creditDebitCard")}
                </Text>

                {activeTab == 555 ? (
                  <div className=" max-w-[580px] w-full flex flex-col items-start justify-start mt-6 mb-[0px]">
                    <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full ">
                      <Input
                        required
                        label={t("cardNumber")}
                        value={cardData?.card_number}
                        error={cardError?.card_number}
                        onChange={(e) => {
                          checkCartType(e.target.value);
                          setCardData({
                            ...cardData,
                            card_number: CUSTOM.strFormat(
                              e.target.value,
                              "number|max:16"
                            ),
                          });
                          setCardError({ ...cardError, card_number: "" });
                        }}
                        endAdornment={
                          cardTypeError?.image ? (
                            <img
                              src={cardTypeError?.image}
                              className="h-5 rtl:ml-3 card-type-img-in-card-no"
                              alt="Card Type Error"
                            />
                          ) : null
                        }
                      />
                      <Input
                        required
                        label={t("cardHolderName")}
                        value={cardData?.card_holder}
                        error={cardError?.card_holder}
                        onChange={(e) => {
                          setCardData({
                            ...cardData,
                            card_holder: CUSTOM.strFormat(
                              e.target.value,
                              "text|max:50"
                            ),
                          });
                          setCardError({ ...cardError, card_holder: "" });
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full">
                      <Input
                        required
                        label={t("expiryDate(MM/YY)")}
                        value={cardData?.expiration_date}
                        error={cardError?.date}
                        onChange={(e) => {
                          setCardData({
                            ...cardData,
                            expiration_date: CUSTOM.strFormat(
                              e.target.value,
                              "max:15|expiry_date"
                            ),
                          });
                          setCardError({ ...cardError, date: "" });
                        }}
                      />
                      <Input
                        required
                        label={t("cvv")}
                        type="password"
                        value={cardData?.cvv}
                        error={cardError?.cvv}
                        onChange={(e) => {
                          setCardData({
                            ...cardData,
                            cvv: CUSTOM.strFormat(
                              e.target.value,
                              "max:4|number"
                            ),
                          });
                          setCardError({ ...cardError, cvv: "" });
                        }}
                      />
                    </div>
                    <div className="flex items-center justify-start w-full -mt-3">
                      <CheckboxCustom
                        checked={cardData?.remember_me == "YES"}
                        onChange={(e) => {
                          setCardData({
                            ...cardData,
                            remember_me: e.target.checked ? "YES" : "NO",
                          });
                        }}
                      />
                      <Text className="text-black-900 text-base font-nunitoregular -ml-4 rtl:ml-0 rtl:-mr-4 -mt-1">
                        {t("saveCardDetailsForFuturePurpose")}
                      </Text>
                    </div>

                    <div className="w-full max-w-[242px] mt-5">
                      <Button
                        className="flex-1 mx-auto"
                        size="lg"
                        variant="FillBlack"
                        hover={true}
                        hoverclass="bg-white-A700"
                        onClick={() => {
                          verifyCard();
                        }}
                      >
                        {t("payNow")} (SAR{" "}
                        {CUSTOM.setPrice(orderData?.priceDetails?.total_amount)}
                        )
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {
              <div
                className="common-pointer flex flex-row flex-row gap-3 items-start justify-start w-full pt-5 pb-5 border-b "
                isSelected={activeTab === 2222}
              >
                <Radio
                  className="flex"
                  name="paymentMode"
                  checked={activeTab === 2222}
                  onChange={() => handleTabClick(2222)}
                />
                <div className="flex flex-col items-start gap-1 justify-start flex-1 -mt-1">
                  <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-0 w-full mb-0">
                    {/* <img src="/images/tabby-logo.svg" alt="tabby-logo" style={{ maxWidth: '45%', maxHeight: '40%' }}/> */}
                    <Text className="text-base text-black-900 font-nunitoregular">
                      {t("payWithTabby")}
                    </Text>
                    {/* <InfoIcon onClick={handleInfoIconClick} /> */}
                  </div>
                  {activeTab == 2222 ? (
                    <div className="hidden111 max-w-[580px] w-full flex flex-col items-start justify-start mt-6 mb-[18px]">
                      <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full mb-2">
                        <TextField
                          required
                          label={t("Phone")}
                          type="text"
                          value={tabbyData?.phone}
                          error={!!tabbyError?.phone}
                          helperText={tabbyError?.phone || " "}
                          onChange={(e) => {
                            setTabbyData({
                              ...tabbyData,
                              phone: CUSTOM.strFormat(
                                e.target.value,
                                "max:9|number"
                              ),
                            });
                            setTabbyError({ ...tabbyError, phone: "" });
                          }}
                        />
                        <TextField
                          required
                          label={t("Email")}
                          type="text"
                          value={tabbyData?.email}
                          error={!!tabbyError?.email}
                          helperText={tabbyError?.email || " "}
                          onChange={(e) => {
                            setTabbyData({
                              ...tabbyData,
                              email: CUSTOM.strFormat(
                                e.target.value,
                                "max:50|email"
                              ),
                            });
                            setTabbyError({ ...tabbyError, email: "" });
                          }}
                        />
                      </div>
                      <div className="w-full max-w-[242px] mt-5">
                        <Button
                          className="flex-1 mx-auto"
                          size="lg"
                          variant="FillBlack"
                          onClick={(e) => {
                            verifyTabbyFields();
                          }}
                        >
                          {t("payNow")} (SAR{" "}
                          {CUSTOM.setPrice(
                            orderData?.priceDetails?.total_amount
                          )}
                          )
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            }
            {
            !!showApplePayButton && (
              <div
                className="common-pointer flex flex-row flex-row gap-3 items-start justify-start w-full pt-5 pb-5 border-b "
                isSelected={activeTab === 3333}
              >
                <Radio
                  className="flex"
                  name="paymentMode"
                  checked={activeTab === 3333}
                  onChange={() => handleTabClick(3333)}
                />
                <div className="flex flex-col items-start gap-1 justify-start flex-1 -mt-1">
                  <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-0 w-full mb-0">
                    {/* <img src="/images/tabby-logo.svg" alt="tabby-logo" style={{ maxWidth: '45%', maxHeight: '40%' }}/> */}
                    <Text className="text-base text-black-900 font-nunitoregular">
                      {"Pay with Apple Pay"}
                    </Text>
                    {/* <InfoIcon onClick={handleInfoIconClick} /> */}
                  </div>
                  {activeTab == 3333 ? (
                    <div className="hidden111 max-w-[580px] w-full flex flex-col items-start justify-start mt-6 mb-[18px]">
                      <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full mb-2">
                        <ApplePayComponent
                          applePayData={applePayDetail}
                          payViaOtherSource={paymentProcess}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )
            }
          </div>
        ) : null}
      </div>
      <div className="bg-red-50_01 gap-4 flex flex-wrap items-center justify-between py-[22px] px-[30px] xs:p-5 relative left-[-22px] rtl:left-[22px] bottom-[-18px] payment-secure-box">
        <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
          <Img
            className="h-9 w-9"
            src="/images/img_shielddone.svg"
            alt="shielddone"
          />
          <div className="flex flex-col gap-1 items-start justify-start">
            <Text className="text-base text-black-900 font-nunitomedium">
              {t("secureCardPayment")}
            </Text>
            <Text className="text-black-900 text-xs font-nunitoregular whitespace-nowrap">
              {t("100SecurePaymentsPoweredByRabbitHole")}
            </Text>
          </div>
        </div>
        <div className="flex gap-2 items-center justify-start w-auto">
          <div className="h-8 w-[44px] bg-white-A700 rounded-md">
            <Img
              className="h-full w-full object-contain"
              src="/images/img_file_white_a700.svg"
              alt="file"
            />
          </div>
          <div className="h-8 w-[44px] bg-white-A700 rounded-md">
            <Img
              className="h-full w-full object-contain"
              src="/images/img_023mastercard1.png"
              alt="023mastercardOne"
            />
          </div>
          <div className="h-8 w-[44px] bg-white-A700 rounded-md">
            <Img
              className="h-full w-full object-contain"
              src="/images/img_007visa.png"
              alt="007visa"
            />
          </div>
          <div className="h-8 w-[44px] bg-white-A700 rounded-md">
            <Img
              className="h-full w-full object-contain"
              src="/images/img_1200pxrupaysvg.png"
              alt="1200pxrupaysvg"
            />
          </div>
        </div>
      </div>
      {SuccessOrderPlacedOpen === true ? (
        <SuccessOrderPlaced
          closepopup={setSuccessOrderPlacedOpen}
          orderNumber={paymentData?.order_number}
        />
      ) : null}
    </>
  );
};

export default Payment;
