import React, { useEffect } from "react";
import { Button, Text } from "components";
import { useTranslation } from "react-i18next";
import { PickUpPointsListItem } from "components/PickUpPointsListItem";
import { PickUpPoint } from "popups/PickUpPoint";
import { useDispatch, useSelector } from "react-redux";
import {
  updateOrderData,
  getOrderData,
} from "../../../../../redux/reducers/orderData";
import { changeTrigger } from "redux/reducers/trigger";

const PickUpPointsList = (props) => {
  const { zoneList } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderData = useSelector(getOrderData);
  const [pickUpPoints, setPickUpPoints] = React.useState(false);
  const [selectedZone, setSelectedZone] = React.useState({
    pickUpPoint: {},
    zoneIndex: -1,
    selected: false,
  });

   /*
   * set address data
   */
   const deliverHear = (data) => {
    const updatedOrderData = {
      ...orderData,
      delivery_mode_show: "hide",
      home_office: selectedZone?.pickUpPoint,
      pageStep: 3,
      delivery_mode: "pickup_point",
    };
    if (orderData?.delivery_type === "fastest") {
      updatedOrderData.timeSloat = {};
      updatedOrderData.delivery_type = "";
    }
    dispatch(updateOrderData(updatedOrderData));
    dispatch(changeTrigger("closepopup"));
  };

  const handelsetpickUpPoints = () => {
    setPickUpPoints(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };

  useEffect(() => {
    if (selectedZone?.selected) {
      if (pickUpPoints) {
        setPickUpPoints(false);
        document.body.classList.remove("overflow-hidden");
        document.getElementById("header-box").classList.remove("relative");
      } else {
        deliverHear();
      }
    }
  }, [selectedZone, pickUpPoints]);

  return (
    <>
      <div className="mt-2.5 w-full">
        <Text className="text-base text-black-900 font-nunitomedium mb-6">
          {t("pickUpPointsNearYou")}
        </Text>
        <div className="flex flex-col gap-4">
          {zoneList?.length > 0 ? (
            <>
              <PickUpPointsListItem
                zoneList={zoneList}
                setSelectedZone={setSelectedZone}
                selectedZone={selectedZone}
              />
              <div className="w-full h-px bg-gray-300"></div>
              <div
                className="flex flex-1 flex-row items-center justify-between w-full"
                onClick={() => {
                  handelsetpickUpPoints();
                }}
              >
                <Button className="flex flex-1 flex-row items-center justify-start w-full gap-2">
                  <Text className="common-pointer text-center text-pink-800 text-sm w-auto font-nunitomedium">
                    {t("viewAll")}
                  </Text>
                </Button>
                {/* {zoneList?.length ? (
                  <Button
                    className="w-auto mx-auto px-3 py-1.5 text-sm xs:mx-0" // "common-pointer bg-black-900 cursor-pointer font-nunitomedium h-12 py-2.5 px-3.5 rounded text-base text-center text-white-A700 w-auto"
                    size="sm"
                    variant={
                      !selectedZone?.selected ? "FillBlack" : "OutlineBlack"
                    }
                    hover={!selectedZone?.selected ? false : true}
                    hoverclass="bg-black-900"
                    disabled={!selectedZone?.selected}
                    onClick={deliverHear}
                  >
                    {t("continue")}
                  </Button>
                ) : null} */}
              </div>
            </>
          ) : (
            <div>{t("data_not_found")}</div>
          )}
        </div>
      </div>
      {pickUpPoints === true ? (
        <PickUpPoint
          closepopup={() => {
            setPickUpPoints(false);
            document.body.classList.remove("overflow-hidden");
            document.getElementById("header-box").classList.remove("relative");
          }}
          zoneList={zoneList}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
          deliverHear={deliverHear}
        />
      ) : null}
    </>
  );
};

export default PickUpPointsList;
