import { Button, DatepickerInput, Text, TimeSlotRadio } from "components";
import { useTranslation } from "react-i18next";

import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import * as API from "../../../../utils/helper/Enum";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import * as CUSTOM from "../../../../utils/helper/custom";
import { useEffect, useState } from "react";
import {
  getGiftOrderData,
  updateGiftOrderData,
} from "../../../../redux/reducers/giftOrderData";
let cdate = new Date();
let defaultSloat = {
  day: "",
  slots: [],
};

const StepsDeliveryDateTime = () => {
  const { t } = useTranslation();
  let orderData = useSelector(getGiftOrderData);
  let dispatch = useDispatch();
  const [slots, setSlots] = useState(defaultSloat);
  const [inputData, setInputData] = useState({
    date: "",
    initialDate: "",
    slotId: 0,
  });

  /**
   * get time sloat
   */
  const getTimeSloats = async () => {
    cdate = inputData?.date ? inputData?.date : cdate;
    let date = CUSTOM.changeDateFormat(cdate, "yyyy-MM-dd");
    let day = CUSTOM.getDayOfWeek(date);
    let inpData = {
      lat: orderData?.addressData?.lattitude,
      long: orderData?.addressData?.longitude,
      day: day,
      date: date,
      user_id: orderData?.orderDetails?.userId,
    };
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.GIFTORDER.GET_TIME_SLOATS}`,
        "get",
        {},
        { params: inpData },
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        setSlots(response?.data);
      } else {
        setSlots(defaultSloat);
      }
    } catch (e) {
      setSlots(defaultSloat);
    }
  };

  /**
   * submit form details
   */
  const submitForm = async () => {
    //SUBMIT_GIFT_DETAILS
    if (inputData?.slotId == 0) {
      return;
    }
    try {
      let inputFormData = {
        order_id: orderData?.orderDetails?.id,
        mobile_number: orderData?.mobile_no,
        address_details: {
          mobileNo: `+966-${orderData?.mobile_no}`,
          streetNumber: orderData?.addressData?.streetNumber,
          buildingNumber: orderData?.addressData?.buildingNumber,
          floorApartment: orderData?.addressData?.floorApartment,
          address: orderData?.addressData?.address,
          lattitude: orderData?.addressData?.lattitude,
          longitude: orderData?.addressData?.longitude,
        },
        delivery_date: inputData?.date,
        time_slot_id: inputData?.slotId,
        branch_id: inputData?.branch_id,
      };
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.GIFTORDER.SUBMIT_GIFT_DETAILS}`,
        "post",
        inputFormData,
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        inputFormData.pageStep = 4;
        inputFormData.timeLabel = inputData?.timeLabel;
        dispatch(updateGiftOrderData(inputFormData));
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
  };

   /**
   * get Current Time from server
   */
   const getCurrentTime = async () => {
    let newdate = CUSTOM.convertDateString(new Date(), "yyyy-MM-dd");
    try {
      let response = await globalRequest(
        API?.CART?.GET_CURRENT_DATE,
        "get",
        {},
        {}
      );
      response = response?.data;
      if (response.status == "SUCCESS") {
        newdate = CUSTOM.convertDateString(response?.data, "yyyy-MM-dd");
      }
    } catch (e) {
      console.log(e?.message);
    }
    setInputData((prev) => ({
      ...prev,
      date: newdate,
      initialDate: newdate,
    }));
  };

  /**
   * sloat reset when address change
   */
  useEffect(() => {
    if (orderData?.addressData?.lattitude) {
      getCurrentTime();
    //  setInputData({ ...inputData, date: cdate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.addressData?.lattitude]);

  /**
   * Get sloats when change date and time
   */
  useEffect(() => {
    if (inputData?.date) {
      setInputData({ ...inputData, slotId: 0 });
      getTimeSloats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData?.date]);

  return (
    <>
      <div className="relative flex flex-col w-full">
        <div className="flex xs:flex-col xs:items-start flex-row gap-4 items-center justify-between w-full mt-0.5 mb-2">
          <div className="flex flex-col gap-3 flex-row items-start justify-start w-full">
            <div className="flex flex-col gap-1 items-start justify-start">
              <Text className="text-base text-black-900 font-nunitomedium">
                {t("deliveryDateAndTime")}
              </Text>
              <div className="flex flex-row items-center">
                <Text className="text-gray-700 text-sm font-nunitoregular">
                  {t("forYourItemsChooseTheDeliveryDateAndTime")}
                </Text>
              </div>
            </div>
          </div>
          {/* <Button
            className="w-auto mx-auto px-3 py-1.5 text-sm xs:mx-0"
            size="sm"
            variant="OutlineBlack"
          >
            {t("change")}
          </Button> */}
        </div>

        {orderData?.pageStep == 3 ? (
          <div className="flex flex-col items-start justify-start w-full mt-3">
            <div className="flex flex-col gap-3 items-start justify-start w-full">
              <div className="max-w-[350px] w-full">
                <DatepickerInput
                  required={true}
                  disablePast={true}
                  format={"DD/MM/YYYY"}
                  label={t("deliveryDate")}
                  min={inputData?.initialDate}
                  value={inputData?.date}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      date: e,
                      slotId: 0,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-3 items-start justify-start w-full">
              <Text className="text-base text-black-900 font-nunitomedium">
                {slots?.slots.length > 0
                  ? t("availableTimeSlots")
                  : t("noSloatFound")}
              </Text>
              <div className="w-full flex flex-wrap gap-3">
                {slots?.slots.map((items, index) => {
                  return (
                    <TimeSlotRadio
                      label={CUSTOM.formatTimeRange(
                        items?.openTime,
                        items?.closeTime
                      )}
                      name="time"
                      className="w-auto"
                      checked={{ slotId: inputData?.slotId, id: items?.id }}
                      value={items?.id}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          branch_id: items?.branchId,
                          slotId: items?.id,
                          timeLabel: CUSTOM.formatTimeRange(
                            items?.openTime,
                            items?.closeTime
                          ),
                        });
                      }}
                      id={`slot-1-${index}`}
                    />
                  );
                })}
              </div>
            </div>
            <div className="w-full max-w-[350px] mt-3 flex flex-row gap-4 mb-[18px]">
              <div className="w-full max-w-[242px] mt-8 flex flex-row gap-4 mb-[18px]">
                <Button
                  className="flex-1 mx-auto"
                  size="lg"
                  variant="FillBlack"
                  disabled={inputData?.slotId == 0}
                  onClick={(e) => {
                    submitForm();
                  }}
                >
                  {t("continue")}
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default StepsDeliveryDateTime;
