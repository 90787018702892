import { Button, Img, MobileInput, Input, Text } from "components";
import { useTranslation } from "react-i18next";
import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import * as API from "../../../../utils/helper/Enum";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import * as CUSTOM from "../../../../utils/helper/custom";
import { useEffect, useState } from "react";
import { eachDayOfInterval } from "date-fns";
import {
  getGiftOrderData,
  updateGiftOrderData,
} from "../../../../redux/reducers/giftOrderData";

const StepsYourDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderData = useSelector(getGiftOrderData);
  const [timer, setTimer] = useState(0);
  const [inputData, setInputData] = useState({
    enableVerify: true,
    sentOtp: false,
    otp: "",
    enableContinue: true,
  });

  /**
   * send otp on mobile no
   */
  const sendOtp = async (token) => {
    // console.log(orderData?.orderDetails?.giftDetails?.mobile_no, inputData?.mobile_no, "mobile_no")
    // if (orderData?.orderDetails?.giftDetails?.mobile_no.split("-")[1] == inputData?.mobile_no && inputData?.mobile_no) {
    //   saveUserData();
    //   return;
    // }
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.OTP.SEND_OTP}`,
        "post",
        inputData,
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        setTimer(process.env.REACT_APP_OTP_TIMER);
        setInputData({ ...inputData, sentOtp: true, otp: "" });
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
  };

  /**
   * verify Otp
   */
  const verifyOtp = async (token) => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.OTP.VERIFY_OTP}`,
        "post",
        inputData,
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        saveUserData();
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: eachDayOfInterval?.message,
          snackbarState: "error",
        })
      );
    }
  };

  /**
   * Check mobile number is valid or not
   */
  const checkMobileNumber = async () => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.GIFTORDER.CHECK_MOBILE_NUMBER}`,
        "post",
        {
          order_id: orderData?.orderDetails?.id,
          mobile_number: inputData?.mobile_no,
        },
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        // saveUserData();
        sendOtp();
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: eachDayOfInterval?.message,
          snackbarState: "error",
        })
      );
    }
  };

  /**
   * save user data
   */
  const saveUserData = () => {
    setInputData({
      enableVerify: false,
      sentOtp: false,
      otp: "",
      enableContinue: true,
      mobile_no: inputData?.mobile_no,
    });
    dispatch(
      updateGiftOrderData({
        ...orderData,
        mobile_no: inputData?.mobile_no,
        pageStep: 2,
      })
    );
  };

  /**
   * check and update button state when enter the mobile number verify button should be enable
   */
  useEffect(() => {
    if (inputData?.mobile_no) {
      let statusVerify = true;
      if (CUSTOM.isValidField(inputData?.mobile_no, "mobile")) {
        statusVerify = false;
      }
      setInputData({ ...inputData, enableVerify: statusVerify });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData?.mobile_no]);

  /**
   * check and update button state when enter the otp continue button should be enable
   */
  useEffect(() => {
    if (inputData?.otp) {
      let enableContinue = true;
      if (inputData?.otp.length == 4) {
        enableContinue = false;
      }
      setInputData({ ...inputData, enableContinue: enableContinue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData?.otp]);

  /**
   * timer set for otp
   */
  useEffect(() => {
    let interval;
    const countdown = () => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    };
    interval = setInterval(countdown, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <>
      <div className="relative flex flex-col w-full">
        <Img
          className="h-[22px] absolute left-0 right-0 mt-[-30px] mx-auto"
          src="/images/img_signal.svg"
          alt="signal"
        />
        {/* login and Sign Up User */}
        <div className="flex flex-row md:gap-5 items-center justify-start w-full">
          <div className="flex flex-col gap-1 items-start justify-start">
            <Text className="text-base text-black-900 font-nunitomedium">
              {t("yourDetails")}
            </Text>

            {orderData?.pageStep > 1 ? (
              <div className="flex gap-1 flex-row items-center">
                <Text className="text-black-900 text-sm nunitoregular">
                  +966 {orderData?.mobile_no}
                </Text>
                <Img
                  className="h-6 w-6"
                  src="/images/img_checkmark_teal_400.svg"
                  alt="checkmark"
                />
              </div>
            ) : (
              <Text className="text-gray-700 text-sm font-nunitoregular">
                {t("verifyMobileNumberToSubmittingTheDetails")}
              </Text>
            )}
          </div>

          {orderData?.pageStep > 1 ? (
            <Button
              className="w-auto ltr:ml-auto rtl:mr-auto px-3 py-1.5 text-sm"
              size="sm"
              variant="OutlineBlack"
              onClick={(e) => {
                dispatch(updateGiftOrderData({ ...orderData, pageStep: 1 }));
              }}
            >
              {t("change")}
            </Button>
          ) : null}
        </div>
        {/* Sign Up User */}

        {orderData?.pageStep == 1 ? (
          <>
            <div className="w-full max-w-[350px] pt-[30px]">
              <MobileInput
                required
                label={t("mobile")}
                className="w-full"
                onChange={(e) => {
                  setInputData({
                    ...inputData,
                    mobile_no: CUSTOM.strFormat(e.target.value, "max:9|number"),
                  });
                }}
                value={inputData?.mobile_no}
              />
            </div>
            {inputData?.sentOtp ? (
              <div className="flex flex-row gap-3">
                <div className="w-full max-w-[350px]">
                  <Input
                    required
                    className="w-full mb-0"
                    label={t("verificationCode")}
                    value={inputData?.otp}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        otp: CUSTOM.strFormat(e.target.value, "max:4|number"),
                      });
                    }}
                  />
                </div>

                <div className="w-auto">
                  <Text
                    className={`text-pink-800 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mt-3.5 ${
                      timer == 0 ? "cursor-pointer" : "opacity-down"
                    }`}
                    onClick={(e) => {
                      if (timer == 0) {
                        sendOtp();
                      }
                    }}
                  >
                    <>{t("resendOTP")}</>
                  </Text>
                </div>
                {timer != 0 ? (
                  <div className="w-auto">
                    <Text className="text-pink-800 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mt-3.5">
                      {timer}:00
                    </Text>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="w-full max-w-[350px] mt-3 flex flex-row gap-4 mb-[18px]">
              {!inputData?.sentOtp ? (
                <div className="w-full max-w-[224px] pt-[10px] flex flex-row gap-4 mb-[18px]">
                  <Button
                    className="flex-1 mx-auto"
                    size="lg"
                    variant="FillBlack"
                    disabled={inputData?.enableVerify}
                    onClick={(e) => {
                      checkMobileNumber();
                    }}
                  >
                    {t("verify")}
                  </Button>
                </div>
              ) : (
                <div className="w-full max-w-[224px] pt-[10px] flex flex-row gap-4 mb-[18px]">
                  <Button
                    className="flex-1 mx-auto"
                    size="lg"
                    variant="FillBlack"
                    disabled={inputData?.enableContinue}
                    onClick={(e) => {
                      verifyOtp();
                    }}
                  >
                    {t("confirm")}
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default StepsYourDetail;
