export const ONBOARDING_APIS = {
    CHECK_USER_BY_MOBILE: "/user/auth/check-user-by-mobile",
    SEND_OTP: "/user/auth/send-otp",
    VERIFY_OTP: "/user/auth/verify-otp",
    LOGIN_WITH_OTP: "/user/auth/login",
    SIGNUP: "/user/auth/register",
    FORGOT_PASSWORD: "forgotPassword",
    REFRESH_TOKEN: "/user/auth/refresh-token"
};

export const OTP = {
    SEND_OTP: "/otp/api/v1/sendOtp",
    VERIFY_OTP: "/otp/api/v1/verify-otp"
};

export const USERS = {
    GET_PROFILE: "/user/api/v1/user/profile",
    UPDATE_PROFILE: "/user/api/v1/user/update-profile",
    SEND_OTP: "/user/api/v1/user/send-otp",
    VERIFY_OTP: "/user/api/v1/user/verify-otp",
    UPDATE_MOBILE_NUMBER: "/user/api/v1/user/update-mobile-no",
    GET_ALL_ADDRESS: "/user/api/v1/user/get-all-addresss",
    SET_PREFFERED_LANG:"/user/api/v1/user/set-preffered-lang",
    ADD_ADDRESS:"/user/api/v1/user/add-address",
    DELETE_ADDRESS:"/user/api/v1/user/delete-address",
    UPDATE_ADDRESS:"/user/api/v1/user/update-user-address",
    SET_DEFAULT_ADDRESS:"/user/api/v1/user/set-default-address",
    GET_SAVED_CARD_LIST: "/user/api/v1/user/get-saved-payment-cards",
    ADD_MONEY_IN_WALLET:"/user/api/v1/wallet/add-money" ,
    WALLET_HISTORY:"/user/api/v1/get-wallet-balance-history",
    DELETE_PAYMENT_CARD:"/user/api/v1/delete-saved-payment-card", 
    GET_WALLET_BALANCE:"/user/api/v1/user/wallet-balance"
};

export const GUEST = {
    ADD_GUEST: "/user/api/v1/add-guest-user",
    ADD_ADDRESS:"/user/api/v1/add-guest-user-address",
    GET_ADDRESS_LIST:"/user/api/v1/get-guest-address-by-guestid",
    GET_DETAILS:"/user/api/v1/get-guest-details-by-guestid"
};


export const ADDRESS = {
    CHECK_ADDRESS: "/user/api/v1/check-address",
};


export const CATEGORIES = {
    GET_ALL_CATEGORIES: "/user/api/v1/category/get-all-categories",
};

export const PRODUCTS = {
    GET_PRODUCT_LIST: "/user/api/v1/get-products",
    GET_PRODUCT_BY_ID: "/user/api/v1/get-product-details",
    GET_RELATED_PRODUCT:"/user/api/v1/get-related-products",
    GET_PRODUCT_RATING_BY_ID:"/user/api/v1/get-product-rating-by-id",
    GET_MODIFIER_BY_UOM_ID:"/user/api/v1/get-product-modifiers",
    SEARCH:"/user/api/v1/search",
    All_PRODUCTS:"/home-page/api/v1/get-product",
    GET_UOMS_BY_PRODUCT_ID: "/user/api/v1/get-uom-by-product-id"
};

export const OCCASION = {
    LISTING: "/user/api/v1/occasion-listing",
    TYPE_LISTING: "/user/api/v1/get-occasion-types",
    ADD_OCCASION:"/user/api/v1/create-occasion",
    UPDATE_OCCASION_STATUS:"/user/api/v1/update-occasion-status",
    UPDATE_OCCASION:"/user/api/v1/update-occasion",
    GET_OCCASION_PRODUCT_BY_ID:"/user/api/v1/occasion-details-by-id",
    PLACE_ORDER:"/user/api/v1/place-order-occasion"
};

export const CART = {
    ADD_TO_OCCASION: "/user/api/v1/add-to-occasion",
    ADD_TO_CART: "/user/api/v1/add-to-cart",
    CUSTOMIZE_CART:"/user/api/v1/customize-cart-item",
    DELETE_BY_ITEM_ID:'/user/api/v1/remove-cart-item',
    GET_CART_COUNT:'/user/api/v1/cart-total-count',
    GET_CART_DATA:'/user/api/v1/cart-details',
    MOVE_CART_TO_OCCASION:"/user/api/v1/move-items-to-occasion",
    UPDATE_QTY:"/user/api/v1/update-qty",
    CART_MODIFIER_BY_ITEM_ID:"/user/api/v1/get-modifier-by-cart-item-id",
    CART_SIGNUP_SEND_OTP:"/user/auth/cart-sign-up-send-opt",
    DELETE_GUEST_ADDRESS:"/user/api/v1/delete-guest-user-address",
    GET_SETTING_DATA:"/user/api/v1/get-settings-for-delivery",
    VALIDATE_MERCHANT_FOR_APPLE_PAY:"/validate-apple-pay-merchant",
    GET_CURRENT_DATE: "/user/api/v1/get-current-date"
};

export const CHECKOUT = {
    GET_SLOT_LIST_BY_DATE: "/user/api/v1/get-slots-based-on-branches",
    GET_PREP_TIME: "/user/api/v1/get-prep-time",
};

export const ORDER = {
    GET_SUMMARY: "/user/api/v1/order-summary", 
    PLACE_ORDER:"/user/api/v1/place-order",
    PAY_PAYMENT:"/user/api/v1/order/pay-payment",
    MY_ORDER_LISTING:"/user/api/v1/orders-list",
    MY_ORDER_DETAILS:"/user/api/v1/order-detail",
    DOWNLOAD_INVOICE:"/user/api/v1/download-invoice",
    ADD_RAITING:"/user/api/v1/add-rating",
    CANCEL_ORDER:"/user/api/v1/cancel-order",
    RE_ORDER:"/user/api/v1/reorder",
    ARJUST_ORDER:"/user/api/v1/adjust-order",
    RESEND_GFT_LINK: "/gift/api/v1/resend-email-gift"
};

export const RELATION = {
    LISTING: "/user/api/v1/get-relations",
};


export const PAGES = {
    GET_CMS: "/user/api/v1/get-cms",
    CONTACT_FORM_DATA:"/api/v1/contact-us-submit",
    NEWSLATTER:"/api/v1/subscriber-newsletter"
};

export const COUPON={
    APPLY:"/user/api/v1/apply-coupon"
}

export const DROP_ZONE = {
    GET_NEAR_ALL: "/user/api/v1/get-all-drop-zones",
};

export const GIFTORDER = {
    GET_ORDER_DETAIL: "/gift/api/v1/get-gift-order-details", 
    GET_TIME_SLOATS:"/gift/api/v1/get-slots",
    CHECK_ADDRESS_IN_ZONE:"/gift/api/v1/check-address-in-zone",
    SUBMIT_GIFT_DETAILS:"/gift/api/v1/submit-gift-user-details",
    GET_ORDER_PAYMENT:"/gift/api/v1/pay-payment",
    CHECK_MOBILE_NUMBER: "/gift/api/v1/check-mobile-recipient-number"
};

export const CUSTOMCAKE = {
    CUSTOM_CAKE_QUESTIONS_LIST: "/custom-cake/api/v1/get-custom-cake-questions", 
    UPLOAD_IMAGE:"/custom-cake/api/v1/upload-image-in-temp",
    SAVE_FOR_LATTER:"/custom-cake/api/v1/add-to-my-custom-cake-list",
    MY_LIST:"/custom-cake/api/v1/get-my-custom-cake-list",
    DELETE_CUSTOM_CAKE:"/custom-cake/api/v1/delete-custom-cake",
    GET_CAKE_DETAILS:"/custom-cake/api/v1/get-cake-Details",
    UPDATE_CUSTOM_CAKE:"/custom-cake/api/v1/update-custom-cake",
    PLACE_ORDER:"/custom-cake/api/v1/place-order",
    GET_ORDER_SUMMARY:"/custom-cake/api/v1/order-summary",
    APPLY_COUPON_CODE:"/custom-cake/api/v1/apply-coupon",
    SENDGIFT:"/custom-cake/api/v1/place-order-gift",
    PAY_PAYMENT:"/user/api/v1/custom-cake-order/pay-payment",
    GET_SLOT_LIST_BY_DATE: "/user/api/v1/get-custom-cake-slots-based-on-branches"
};


export const BLOGS={
    CATEGORIES_LIST:"/user/v1/blog/blog-category-list",
    GET_BLOCK_BY_CATEGORY_ID:"/user/v1/blog/get-blogs-by-category",
    GET_DETAILS_BY_ID:"/user/v1/blog/get-blog-details",
    LIKE_DISLIKE:"/user/v1/blog/like-and-dislike"    
}

