import React, { useEffect } from "react";
import { Button, Img, Text } from "components";
import { useTranslation } from "react-i18next";
import { SavedAddressesListItem } from "components/SavedAddressesListItem";
import { updateMyAddress } from "../../../../../redux/reducers/myAddress";
import { useDispatch, useSelector } from "react-redux";
import * as CUSTOM from "../../../../../utils/helper/custom";
import {
  updateOrderData,
  getOrderData,
} from "../../../../../redux/reducers/orderData";

const SavedAddressesList = (props) => {
  const { addressList, setAddAddressPopup } = props;
  const dispatch = useDispatch();
  const orderData = useSelector(getOrderData);
  const { t } = useTranslation();
  const [selectedAddressData, setSelectedAddressData] = React.useState({
    homeAddress: {},
    addressIndex: -1,
    selected: false,
  });

  const deliverHear = (data) => {
    dispatch(
      updateOrderData({
        ...orderData,
        pageStep: 3,
        delivery_mode_show: "hide",
        home_office: selectedAddressData?.homeAddress,
        delivery_mode: "home_office",
      })
    );
  };

  useEffect(() => {
    if (selectedAddressData?.selected) {
      deliverHear();
    }
  }, [selectedAddressData]);

  return (
    <>
      <div className="mt-2.5 w-full">
        <Text className="text-base text-black-900 font-nunitomedium mb-6">
          {t("savedAddresses")}
        </Text>

        <div className="flex flex-col gap-4">
          {addressList?.length ? (
            <SavedAddressesListItem
              addressList={addressList}
              selectedAddressData={selectedAddressData}
              setSelectedAddressData={setSelectedAddressData}
            />
          ) : (
            <div>{t("data_not_found")}</div>
          )}
          <div className="w-full h-px bg-gray-300"></div>
          <div className="flex flex-1 flex-row items-center justify-between w-full">
            <Button
              className="flex flex-1 flex-row items-center justify-start w-full gap-2"
              onClick={(e) => {
                setAddAddressPopup(true);
                dispatch(updateMyAddress(CUSTOM.defaultAddressParams()));
              }}
            >
              <Img
                className="h-6 w-6"
                src="/images/img_plus_pink_800.svg"
                alt="location"
              />
              <Text className="common-pointer text-center text-pink-800 text-sm w-auto font-nunitomedium">
                {t("addANewAddress")}
              </Text>
            </Button>
            {/* {addressList?.length ? (
              <Button
                className="w-auto mx-auto px-3 py-1.5 text-sm xs:mx-0" // "common-pointer bg-black-900 cursor-pointer font-nunitomedium h-12 py-2.5 px-3.5 rounded text-base text-center text-white-A700 w-auto"
                size="sm"
                variant={
                  !selectedAddressData?.selected ? "FillBlack" : "OutlineBlack"
                }
                hover={!selectedAddressData?.selected ? false : true}
                hoverclass="bg-black-900"
                disabled={!selectedAddressData?.selected}
                onClick={deliverHear}
              >
                {t("continue")}
              </Button>
            ) : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedAddressesList;
