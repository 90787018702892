import React from "react";
import { useState, useRef, useEffect } from "react";

const Img = ({
  imgRef = null,
  className,
  src = "defaultNoData.png",
  alt = "testImg",
  onLoad= () => {},
  ...restProps
}) => {

  return (
    <img
      className={className}
      ref={imgRef}
      src={src}
      alt={alt}
      {...restProps}
      loading={"lazy"}
      onLoad={onLoad}
    />
  );
};
export { Img };
