import { useTranslation } from "react-i18next";
import { Button, Img, Text } from "components";
import Footer from "components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import Confetti from "react-confetti";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "../../../redux/reducers/loginData";
import { useEffect } from "react";
import { changeTrigger } from "redux/reducers/trigger";
import { updateOrderData } from "redux/reducers/orderData";
import { setSnackbar } from "../../../redux/reducers/snackbar";

const CartSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { slug } = useParams();
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get('type');
  
  /**
   *  Function to copy order Number
   */
    const copyText = (textToCopy) => {
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, 99999); 
      document.execCommand('copy');
      document.body.removeChild(textarea);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: 'Text copied to clipboard',
          snackbarState: "success",
        })
      );
    };

  useEffect(() => {
    dispatch(updateOrderData({}));
    dispatch(changeTrigger("fetchCartCount"));
    window.scrollTo(0, 0); // Scrolls to the top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-gray-50_02 flex flex-col font-nunitoregular items-center justify-start mx-auto w-full h-full">
        <div className="flex flex-col items-center justify-center w-full md:px-4 py-7 my-auto h-full relative">
          <Confetti className="w-full h-full absolute top-0 left-0 z-0" />
          <Img
            className="relative h-20 z-[2] m-auto"
            src="/images/cart-success.svg"
            alt="group36102"
          />
          <Text className="text-left mt-8 text-2xl md:text-[22px] text-black-900 sm:text-xl font-nunitosemibold font-normal rtl:text-right">
            
            {type && type=='custom-cake' ? t("paymentSuccessful") : t("orderPlaced")}
          </Text>
          <Text className="mt-5 text-base text-gray-700 text-center font-nunitoregular w-full max-w-[490px]">
            {t("thankYouYourOrderOrderID")}{" "}
            <span className="text-black-900" onClick={()=>{copyText(slug)}} style={{ cursor: 'pointer' }}>{slug}</span>
            {t(
              "hasBeenPlacedForFurtherInformationVisitYourOrdersOrYouCanContinueShopping"
            )}
          </Text>
          <Button
            className="common-pointer hover:border hover:border-black-900 bg-black-900 cursor-pointer font-nunitomedium h-12 mt-11 py-3.5 rounded text-base text-center text-white-A700 w-[180px]"
            hover={true}
            hoverclass="bg-gray-50_02"
            onClick={(e) => {
              navigate("/");
            }}
          >
            {t("goToHome")}
          </Button>
          {auth?.id != 0 ? (
            <Text
              className="common-pointer text-center text-pink-800 text-sm w-auto font-nunitomedium mt-6"
              onClick={(e) => {
                navigate("/my-order");
              }}
            >
              {t("myOrders")}
            </Text>
          ) : null}
        </div>
        <Footer className="absolute bottom-[0] flex font-nunitoregular inset-x-[0] items-center justify-center mx-auto w-full" />
      </div>
    </>
  );
};

export default CartSuccess;
