import { Button, Img, Text } from "components";
import { useTranslation } from "react-i18next";
import ShareRedBox from "./ShareRedBox";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import globalRequest from "../../../../utils/global/globalRequest";
import { changeLoader } from "../../../../redux/reducers/loader";
import * as APIS from "../../../../utils/helper/Enum";

const CustomOrderDetailGift = ({ orderData, setIsGiftPayment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Resend Gift Link
   */
  const resendGiftLink = async (orderId) => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        APIS?.ORDER?.RESEND_GFT_LINK + `/${orderId}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "success",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  return (
    <>
      {orderData?.giftLinkToken ? (
        <div className="bg-light_blue-50 flex flex-row justify-between items-center mt-6 p-5 rounded w-full">
          <div className="flex flex-row gap-3 justify-start pr-5">
            <Img
              src="/images/img_info-black.svg"
              className="w-6 h-6"
              alt="info"
            />
            <div className="relative">
              <Text className="font-nunitoregular text-sm flex text-black-900 leading-[24px]">
                {t("yourGiftRequestHasBeenSentToTheRecipient_text")}
              </Text>
              <Text className="font-nunitoregular text-sm flex text-black-900 leading-[24px]">
                {t(
                  "ifTheRecipientDoesNotGiveDetailsWithin_24_hoursTheOrderWillBeCancelled"
                )}
              </Text>
            </div>
          </div>
          <Button
            className="min-w-[125px] flex items-center justify-center"
            size="sm"
            leftIcon={
              <Img
                className="h-5 w-5 mt-px"
                src="/images/img_share-red.svg"
                alt="share icon"
              />
            }
          >
            <Text
              className="mx-2 font-nunitomedium text-red-900 whitespace-nowrap"
              as="span"
              onClick={async (e) => {
                try {
                  resendGiftLink(orderData?.id);
                  // await navigator.clipboard.writeText(
                  //   "https://rabbitholeastaging.com/recipientform/" +
                  //     orderData?.giftLinkToken
                  // );
                  // dispatch(
                  //   setSnackbar({
                  //     snackbarOpen: true,
                  //     snackbarMessage: "Link copied! successfully",
                  //     snackbarState: "success",
                  //   })
                  // );
                } catch (err) {
                  // setSnackbar({
                  //   snackbarOpen: true,
                  //   snackbarMessage: err?.message,
                  //   snackbarState: "error",
                  // });
                }
              }}
            >
              {t("resendLink")}
            </Text>
          </Button>
        </div>
      ) : null}

      {orderData?.recipientStatus == "accepted" &&
      orderData?.status == "approved" ? (
        <div className="bg-light_blue-50 flex flex-row justify-between items-center mt-6 p-5 rounded w-full">
          <div className="flex flex-row gap-3 justify-start pr-5">
            <Img
              src="/images/img_info-black.svg"
              className="w-6 h-6"
              alt="info"
            />
            <div className="relative">
              <Text className="font-nunitoregular text-sm flex text-black-900 leading-[24px]">
                {t("yourRecipientHasConfirmedTheDetailsToConfirmThe_text")}
              </Text>
              <Text className="font-nunitoregular text-sm flex text-black-900 leading-[24px]">
                {t("You have")}{" "}
                <span className="font-nunitomedium mx-[4px]">
                  30 Minutes
                </span>{" "}
                {t("to confirm this order otherwise it will be cancelled.")}
              </Text>
            </div>
          </div>
          <Button variant={"FillBlack"} size="sm">
            <Text
              className="mx-3 font-nunitomedium text-white whitespace-nowrap"
              as="span"
              onClick={(e) => {
                setIsGiftPayment(true);
              }}
            >
              {t("makePayment")}
            </Text>
          </Button>
        </div>
      ) : null}
      <ShareRedBox orderData={orderData} />
    </>
  );
};

export default CustomOrderDetailGift;
