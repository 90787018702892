import { Img, Text } from "components";
import { useTranslation } from "react-i18next";
const RecipientFormThankYouPage = ({orderData}) => {
  const { t } = useTranslation();
  
  return (
    <>
      <div className="bg-white-A700 flex flex-col font-nunitosemibold items-center justify-start mx-auto w-full">
        {/* <DesignMyOwnCakeQuestionOneHeader className="bg-white-A700 flex flex-col items-center justify-start p-1.5 shadow-bs w-full" /> */}
     
        <div className="relative w-full min-h-[91vh]"> 
         
          <div className="bg-white-A700 flex flex-col gap-[30px] items-center justify-start p-[52px] md:px-10 sm:px-5 shadow-bs2 w-[730px] sm:w-[90%] md:w-full mx-auto mb-[145px] sm:relative sm:z-[5]">
            <Img
              className="h-[22px] absolute left-0 right-0 mt-[-65px] mx-auto"
              src="/images/img_signal.svg"
              alt="signal"
            />
            <div className="bg-red-100 flex flex-col h-20 items-center justify-center p-2.5 rounded-[50%] w-20">
              <Img
                className="h-[60px] w-[60px]"
                src="/images/img_checkmark_pink_800.svg"
                alt="checkmark"
              />
            </div>
            <Text
              className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
              size="txtnunitoSemiBold24"
            >
              {t("thankYou")}
            </Text>
            <Text
              className="leading-[24.00px] text-base text-center text-gray-700"
              size="txtnunitoRegular16Gray700"
            >
              <>
                {t("yourDetailsHasBeenSuccessfullySentToTheSenderDot")}
                <br />
                {t("youWillReceiveYourGiftOnceTheSenderConfirmsThe")}
                <br />
                {t("detailsOfTheOrderDot")}
              </>
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipientFormThankYouPage;