import { useEffect } from "react";

const PaymentResponse = () => {

  useEffect(() => {
    const url = window.location.href;
    const params = new URL(url);
    const payment_status = params.searchParams.get("payment_status");
    if (payment_status !== null) {
      const order_id = params.searchParams.get("order_id");
      const message = params.searchParams.get("message");
      if (payment_status == "success") {
        localStorage.setItem(
          "paymentdata",
          JSON.stringify({ status: "success", order_id: order_id })
        );
      } else {
        localStorage.setItem(
          "paymentdata",
          JSON.stringify({ status: "failed", order_id: "", message: message })
        );
      }
      window.close();
    }
  }, []);

  return (
    <>
    <p></p>
    </>
  );
};

export default PaymentResponse;