import { Button, Img, Line, Text } from "components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as CUSTOM from "../../../../utils/helper/custom";

let statusIgnoreArray = [
  "no_show",
  "cancelled",
  "rejected",
  "placed",
  // "under_qa",
  // "under_qa",
  //"ready_to_pick",
];

const TrackHistory = ({ orderData, type }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [lastStatusData, setLastStatusData] = useState({});
  const [imgURl, setImgUrl] = useState("");
  const [currentOrderStatus, setCurrentOrderStatus] = useState("");
  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  useEffect(() => {
    setImgUrl(CUSTOM.orderStatusImgages(currentOrderStatus));
  }, [currentOrderStatus]);

  /**
   * set order status
   */
  useEffect(() => {
    let resData = CUSTOM.getLastStatusData(
      orderData?.orderStatusLogs,
      orderData?.status
    );
    setLastStatusData(resData);
    let status = resData?.status;
    if (orderData?.orderType == "custom_cake_gift") {
      if (status == "approved") {
        status = "pending";
      }
    }
    if (
      orderData?.deliveryMode == "pickup" &&
      (status == "out_for_delivery" || status == "ready_to_pick")
    ) {
      status = "under_qa";
    }
    if (status == "pickup_delivered") {
      status = "ready_to_pick";
    }
    setCurrentOrderStatus(status);
  }, [orderData]);

  useEffect(() => {
    if (orderData?.deliveryMode == "pickup") {
      statusIgnoreArray.push("out_for_delivery");
    }
  }, [orderData]);

  return (
    <>
      {type == "gift" ? (
        <div>
          <Line className="bg-gray-300 h-px w-[95%] xs:w-full ltr:ml-auto rtl:mr-auto mt-10" />
          <div
            className="flex flex-col gap-[0px] items-center justify-start w-full"
            onClick={toggleVisibility}
          >
            <div className="flex sm:flex-wrap flex-row py-3 items-center justify-between sm:justify-end w-full">
              <div className="flex flex-row gap-4 items-center justify-start w-auto cursor-pointer w-full">
                <Img className="h-8 w-8" src={imgURl} alt="warning" />

                <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                  {t(CUSTOM.statusByKey(currentOrderStatus, "on"))}{" "}
                  {CUSTOM.convertDateString(
                    CUSTOM.getDateFromStatusLog(
                      orderData?.orderStatusLogs,
                      currentOrderStatus
                    ),
                    "dd/MM/yyyy hh:mm a"
                  )}
                </Text>
              </div>
              <Button
                className="px-3 flex items-end justify-end text-red-700 font-nunitomedium"
                size="md"
                rightIcon={
                  <Img
                    className="h-5 w-5"
                    src="/images/dow-red-icon.svg"
                    alt="arrow_down"
                  />
                }
              >
                {t("trackHistory")}
              </Button>
            </div>
          </div>
          <Line className="bg-gray-300 h-px w-full w-full ltr:ml-auto rtl:mr-auto" />

          <div className={`toggle-content pl-1 ${isVisible ? "show" : ""}`}>
            {statusIgnoreArray.indexOf(currentOrderStatus) == -1 ? (
              <>
                {CUSTOM.getAllOrderStatus("gift").map((items, index) => {
                  if (statusIgnoreArray.indexOf(items) !== -1) {
                    return;
                  }
                  return (
                    <div
                      className="flex flex-row gap-4 items-start justify-start w-full mt-5 mb-5 relative xs:flex-wrap"
                      key={`keyid-${index}-${Math.random()}`}
                    >
                      <div
                        className={`${
                          CUSTOM.getDateFromStatusLog(
                            orderData?.orderStatusLogs,
                            items,
                            ""
                          )
                            ? `progress-line success`
                            : `progress-line gray`
                        }`}
                      ></div>
                      <Img
                        className="h-6 w-6 relative z-[10]"
                        src={`${
                          CUSTOM.getDateFromStatusLog(
                            orderData?.orderStatusLogs,
                            items,
                            ""
                          )
                            ? "/images/img_check_circle.svg"
                            : "/images/gray-dot.svg"
                        }`}
                        alt="check"
                      />
                      <div className="flex flex-col items-start justify-start w-full title  ">
                        <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                          {t(CUSTOM.statusByKey(items))}{" "}
                        </Text>
                        <Text className="text-gray-700 text-[12px] w-auto font-nunitoregular">
                          {t(CUSTOM.statusMessageByKey(items))}{" "}
                        </Text>
                      </div>
                      <div className="flex flex-col items-end justify-start w-auto xs:ml-10">
                        <Text className="text-black-900 text-sm w-auto font-nunitoregular whitespace-nowrap">
                          {CUSTOM.convertDateString(
                            CUSTOM.getDateFromStatusLog(
                              orderData?.orderStatusLogs,
                              items
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {orderData?.orderStatusLogs.map((itemss, index) => {
                  let items = itemss?.status;
                  if (statusIgnoreArray.indexOf(items) !== -1) {
                    return;
                  }
                  return (
                    <div
                      className="flex flex-row gap-4 items-start justify-start w-full mt-5 mb-5 relative xs:flex-wrap"
                      key={`keyid-${index}-${Math.random()}`}
                    >
                      <div className={`progress-line success`}></div>
                      <Img
                        className="h-6 w-6 relative z-[10]"
                        src={
                          orderData?.orderStatusLogs.length == 1 ||
                          (orderData?.orderStatusLogs.length == 2 &&
                            statusIgnoreArray.indexOf(currentOrderStatus) != -1)
                            ? `/images/img_warning.svg`
                            : `/images/img_check_circle.svg`
                        }
                        alt="check"
                      />
                      <div className="flex flex-col items-start justify-start w-full title  ">
                        <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                          {t(CUSTOM.statusByKey(items))}{" "}
                        </Text>
                        <Text className="text-gray-700 text-[12px] w-auto font-nunitoregular">
                          {t(CUSTOM.statusMessageByKey(items))}{" "}
                        </Text>
                      </div>
                      <div className="flex flex-col items-end justify-start w-auto xs:ml-10">
                        <Text className="text-black-900 text-sm w-auto font-nunitoregular whitespace-nowrap">
                          {CUSTOM.convertDateString(
                            CUSTOM.getDateFromStatusLog(
                              orderData?.orderStatusLogs,
                              items
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            {statusIgnoreArray.indexOf(currentOrderStatus) !== -1 ? (
              <div className="flex flex-row gap-4 items-start justify-start w-full mt-5 mb-5 relative xs:flex-wrap">
                <div className="progress-line error"></div>
                <Img
                  className="h-6 w-6 relative z-[10]"
                  src={CUSTOM.orderStatusImgages(currentOrderStatus)}
                  alt="check"
                />
                <div className="flex flex-col items-start justify-start w-full title  ">
                  <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                    {t(CUSTOM.statusByKey(currentOrderStatus))}
                  </Text>
                  <Text className="text-gray-700 text-[12px] w-auto font-nunitoregular">
                    {t(
                      lastStatusData?.reason
                        ? lastStatusData?.reason
                        : CUSTOM.statusMessageByKey(currentOrderStatus)
                    )}
                  </Text>
                </div>
                <div className="flex flex-col items-end justify-start w-auto xs:ml-10">
                  <Text className="text-black-900 text-sm w-auto font-nunitoregular whitespace-nowrap">
                    {CUSTOM.convertDateString(
                      CUSTOM.getDateFromStatusLog(
                        orderData?.orderStatusLogs,
                        currentOrderStatus
                      ),
                      "dd/MM/yyyy hh:mm a"
                    )}
                  </Text>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {type == "regular" ? (
        <div>
          <Line className="bg-gray-300 h-px w-[95%] xs:w-full ltr:ml-auto rtl:mr-auto mt-10" />
          <div
            className="flex flex-col gap-[0px] items-center justify-start w-full"
            onClick={toggleVisibility}
          >
            <div className="flex sm:flex-wrap flex-row py-3 items-center justify-between sm:justify-end w-full">
              <div className="flex flex-row gap-4 items-center justify-start w-auto cursor-pointer w-full">
                <Img className="h-8 w-8" src={imgURl} alt="warning" />

                <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                  {t(CUSTOM.statusByKey(currentOrderStatus, "on"))}{" "}
                  {CUSTOM.convertDateString(
                    CUSTOM.getDateFromStatusLog(
                      orderData?.orderStatusLogs,
                      currentOrderStatus
                    ),
                    "dd/MM/yyyy hh:mm a"
                  )}
                </Text>
              </div>
              <Button
                className="px-3 flex items-end justify-end text-red-700 font-nunitomedium"
                size="md"
                rightIcon={
                  <Img
                    className="h-5 w-5"
                    src="/images/dow-red-icon.svg"
                    alt="arrow_down"
                  />
                }
              >
                {t("trackHistory")}
              </Button>
            </div>
          </div>
          <Line className="bg-gray-300 h-px w-full w-full ltr:ml-auto rtl:mr-auto" />

          <div className={`toggle-content pl-1 ${isVisible ? "show" : ""}`}>
            {statusIgnoreArray.indexOf(currentOrderStatus) == -1 ? (
              <>
                {CUSTOM.getAllOrderStatus("regular").map((items, index) => {
                  if (statusIgnoreArray.indexOf(items) !== -1) {
                    return;
                  }

                  return (
                    <div
                      className="flex flex-row gap-4 items-start justify-start w-full mt-5 mb-5 relative xs:flex-wrap"
                      key={`keyid-${index}-${Math.random()}`}
                    >
                      <div
                        className={`${
                          CUSTOM.getDateFromStatusLog(
                            orderData?.orderStatusLogs,
                            items,
                            ""
                          )
                            ? `progress-line success`
                            : `progress-line gray`
                        }`}
                      ></div>
                      <Img
                        className="h-6 w-6 relative z-[10]"
                        src={`${
                          CUSTOM.getDateFromStatusLog(
                            orderData?.orderStatusLogs,
                            items,
                            ""
                          )
                            ? "/images/img_check_circle.svg"
                            : "/images/gray-dot.svg"
                        }`}
                        alt="check"
                      />
                      <div className="flex flex-col items-start justify-start w-full title  ">
                        <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                          {t(CUSTOM.statusByKey(items))}{" "}
                        </Text>
                        <Text className="text-gray-700 text-[12px] w-auto font-nunitoregular">
                          {t(CUSTOM.statusMessageByKey(items))}{" "}
                        </Text>
                      </div>
                      <div className="flex flex-col items-end justify-start w-auto xs:ml-10">
                        <Text className="text-black-900 text-sm w-auto font-nunitoregular whitespace-nowrap">
                          {CUSTOM.convertDateString(
                            CUSTOM.getDateFromStatusLog(
                              orderData?.orderStatusLogs,
                              items
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {orderData?.orderStatusLogs.map((itemss, index) => {
                  let items = itemss?.status;
                  if (statusIgnoreArray.indexOf(items) !== -1) {
                    return;
                  }
                  return (
                    <div
                      className="flex flex-row gap-4 items-start justify-start w-full mt-5 mb-5 relative xs:flex-wrap"
                      key={`keyid-${index}-${Math.random()}`}
                    >
                      <div className={`progress-line success`}></div>
                      <Img
                        className="h-6 w-6 relative z-[10]"
                        src={
                          !CUSTOM.getDateFromStatusLog(
                            orderData?.orderStatusLogs,
                            items,
                            ""
                          )
                            ? `/images/gray-dot.svg`
                            : `/images/img_check_circle.svg`
                        }
                        alt="check"
                      />
                      <div className="flex flex-col items-start justify-start w-full title  ">
                        <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                          {t(CUSTOM.statusByKey(items))}
                        </Text>
                        <Text className="text-gray-700 text-[12px] w-auto font-nunitoregular">
                          {t(CUSTOM.statusMessageByKey(items))}{" "}
                        </Text>
                      </div>
                      <div className="flex flex-col items-end justify-start w-auto xs:ml-10">
                        <Text className="text-black-900 text-sm w-auto font-nunitoregular whitespace-nowrap">
                          {CUSTOM.convertDateString(
                            CUSTOM.getDateFromStatusLog(
                              orderData?.orderStatusLogs,
                              items
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            {statusIgnoreArray.indexOf(currentOrderStatus) !== -1 ? (
              <div className="flex flex-row gap-4 items-start justify-start w-full mt-5 mb-5 relative xs:flex-wrap">
                <div className="progress-line error"></div>
                <Img
                  className="h-6 w-6 relative z-[10]"
                  src={CUSTOM.orderStatusImgages(currentOrderStatus)}
                  alt="check"
                />
                <div className="flex flex-col items-start justify-start w-full title  ">
                  <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                    {t(CUSTOM.statusByKey(currentOrderStatus))}
                  </Text>
                  <Text className="text-gray-700 text-[12px] w-auto font-nunitoregular">
                    {t(
                      lastStatusData?.reason
                        ? lastStatusData?.reason
                        : CUSTOM.statusMessageByKey(currentOrderStatus)
                    )}
                  </Text>
                </div>
                <div className="flex flex-col items-end justify-start w-auto xs:ml-10">
                  <Text className="text-black-900 text-sm w-auto font-nunitoregular whitespace-nowrap">
                    {CUSTOM.convertDateString(
                      CUSTOM.getDateFromStatusLog(
                        orderData?.orderStatusLogs,
                        currentOrderStatus
                      ),
                      "dd/MM/yyyy hh:mm a"
                    )}
                  </Text>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {type == "custom_cake" ? (
        <div>
          <Line className="bg-gray-300 h-px w-[95%] xs:w-full ltr:ml-auto rtl:mr-auto mt-10" />
          <div
            className="flex flex-col gap-[0px] items-center justify-start w-full"
            onClick={toggleVisibility}
          >
            <div className="flex sm:flex-wrap flex-row py-3 items-center justify-between sm:justify-end w-full">
              <div className="flex flex-row gap-4 items-center justify-start w-auto cursor-pointer w-full">
                <Img className="h-8 w-8" src={imgURl} alt="warning" />

                <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                  {t(CUSTOM.statusByKey(currentOrderStatus, "on"))}{" "}
                  {CUSTOM.convertDateString(
                    CUSTOM.getDateFromStatusLog(
                      orderData?.orderStatusLogs,
                      currentOrderStatus
                    ),
                    "dd/MM/yyyy hh:mm a"
                  )}
                </Text>
              </div>
              <Button
                className="px-3 flex items-end justify-end text-red-700 font-nunitomedium"
                size="md"
                rightIcon={
                  <Img
                    className="h-5 w-5"
                    src="/images/dow-red-icon.svg"
                    alt="arrow_down"
                  />
                }
              >
                {t("trackHistory")}
              </Button>
            </div>
          </div>
          <Line className="bg-gray-300 h-px w-full w-full ltr:ml-auto rtl:mr-auto" />

          <div className={`toggle-content pl-1 ${isVisible ? "show" : ""}`}>
            {statusIgnoreArray.indexOf(currentOrderStatus) == -1 ? (
              <>
                {CUSTOM.getAllOrderStatus("custom_cake").map((items, index) => {
                  if (statusIgnoreArray.indexOf(items) !== -1) {
                    return;
                  }

                  return (
                    <div
                      className="flex flex-row gap-4 items-start justify-start w-full mt-5 mb-5 relative xs:flex-wrap"
                      key={`keyid-${index}-${Math.random()}`}
                    >
                      <div
                        className={`${
                          CUSTOM.getDateFromStatusLog(
                            orderData?.orderStatusLogs,
                            items,
                            ""
                          )
                            ? `progress-line success`
                            : `progress-line gray`
                        }`}
                      ></div>
                      {(orderData?.orderStatusLogs.length == 1 &&
                        items == "pending") ||
                      (orderData?.orderStatusLogs.length == 2 &&
                        statusIgnoreArray.includes(currentOrderStatus)) ? (
                        <Img
                          className="h-6 w-6 relative z-[10]"
                          src={`${CUSTOM.orderStatusImgages("pending")}`}
                          alt="check"
                        />
                      ) : (
                        <Img
                          className="h-6 w-6 relative z-[10]"
                          src={`${
                            CUSTOM.getDateFromStatusLog(
                              orderData?.orderStatusLogs,
                              items,
                              ""
                            )
                              ? "/images/img_check_circle.svg"
                              : "/images/gray-dot.svg"
                          }`}
                          alt="check"
                        />
                      )}

                      <div className="flex flex-col items-start justify-start w-full title  ">
                        <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                          {t(CUSTOM.statusByKey(items))}{" "}
                        </Text>
                        <Text className="text-gray-700 text-[12px] w-auto font-nunitoregular">
                          {t(CUSTOM.statusMessageByKey(items))}{" "}
                        </Text>
                      </div>
                      <div className="flex flex-col items-end justify-start w-auto xs:ml-10">
                        <Text className="text-black-900 text-sm w-auto font-nunitoregular whitespace-nowrap">
                          {CUSTOM.convertDateString(
                            CUSTOM.getDateFromStatusLog(
                              orderData?.orderStatusLogs,
                              items
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {orderData?.orderStatusLogs.map((itemss, index) => {
                  let items = itemss?.status;
                  if (statusIgnoreArray.indexOf(items) !== -1) {
                    return;
                  }
                  return (
                    <div
                      className="flex flex-row gap-4 items-start justify-start w-full mt-5 mb-5 relative xs:flex-wrap"
                      key={`keyid-${index}-${Math.random()}`}
                    >
                      <div className={`progress-line success`}></div>
                      <Img
                        className="h-6 w-6 relative z-[10]"
                        src={
                          !CUSTOM.getDateFromStatusLog(
                            orderData?.orderStatusLogs,
                            items,
                            ""
                          )
                            ? `/images/gray-dot.svg`
                            : `/images/img_check_circle.svg`
                        }
                        alt="check"
                      />
                      <div className="flex flex-col items-start justify-start w-full title  ">
                        <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                          {t(CUSTOM.statusByKey(items))}
                        </Text>
                        <Text className="text-gray-700 text-[12px] w-auto font-nunitoregular">
                          {t(CUSTOM.statusMessageByKey(items))}{" "}
                        </Text>
                      </div>
                      <div className="flex flex-col items-end justify-start w-auto xs:ml-10">
                        <Text className="text-black-900 text-sm w-auto font-nunitoregular whitespace-nowrap">
                          {CUSTOM.convertDateString(
                            CUSTOM.getDateFromStatusLog(
                              orderData?.orderStatusLogs,
                              items
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </>
            )}

            {statusIgnoreArray.indexOf(currentOrderStatus) !== -1 ? (
              <div className="flex flex-row gap-4 items-start justify-start w-full mt-5 mb-5 relative xs:flex-wrap">
                <div className="progress-line error"></div>
                <Img
                  className="h-6 w-6 relative z-[10]"
                  src={CUSTOM.orderStatusImgages(currentOrderStatus)}
                  alt="check"
                />
                <div className="flex flex-col items-start justify-start w-full title  ">
                  <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                    {t(CUSTOM.statusByKey(currentOrderStatus))}
                  </Text>
                  <Text className="text-gray-700 text-[12px] w-auto font-nunitoregular">
                    {t(
                      lastStatusData?.reason
                        ? lastStatusData?.reason
                        : CUSTOM.statusMessageByKey(currentOrderStatus)
                    )}
                  </Text>
                </div>
                <div className="flex flex-col items-end justify-start w-auto xs:ml-10">
                  <Text className="text-black-900 text-sm w-auto font-nunitoregular whitespace-nowrap">
                    {CUSTOM.convertDateString(
                      CUSTOM.getDateFromStatusLog(
                        orderData?.orderStatusLogs,
                        currentOrderStatus
                      ),
                      "dd/MM/yyyy hh:mm a"
                    )}
                  </Text>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {/* end */}
    </>
  );
};

export default TrackHistory;
