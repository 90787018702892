import { Button, Img, Text } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Share } from "popups/Share";
import { ViewImage } from "popups/ViewImage";

const OrderDetailProcessing = ({ orderData }) => {
  const { t } = useTranslation();
  const [ShareModalOpen, setShareModalOpen] = React.useState(false);
  const [ViewImageModalOpen, setViewImageModalOpen] = React.useState(false);

  const handelsetShareModalOpen = () => {
    setShareModalOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };
  
  const handelsetViewImageModalOpen = () => {
    setViewImageModalOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };
  return (
    <>
      <div className="bg-red-50 flex flex-col items-center justify-start mt-5 w-full">
        <div
          className="bg-cover bg-no-repeat flex flex-col h-[216px] sm:h-auto items-center justify-start px-6 py-4 w-full"
          style={{ backgroundImage: "url('images/bg-detail-banner.png')" }}
        >
          <div className="flex flex-row items-center justify-between w-full mb-2">
            <Text className="font-nunitomedium text-base ">
              {t("yourCakeHasPassedOurQualityCheck")}
            </Text>
            <Button
              className="min-w-[95px] flex items-center justify-center"
              size="sm"
              leftIcon={
                <Img
                  className="h-5 w-5 mt-px"
                  src="/images/img_share-red.svg"
                  alt="share icon"
                />
              }
              onClick={() => handelsetShareModalOpen()}
            >
              <Text
                className="mx-2 font-nunitomedium text-red-900"
                as="span"
              >
                {t("share")}
              </Text>
            </Button>
          </div>
          <Text className="font-nunitoregular text-sm text-black-900 leading-[24px] mb-6">
            {t(
              "ifYouHaveAnyQualityIssuesYouCanReachOutCustomerSupportYouCanAlsoShareTheRealCakeImagesWithFriendsOnSocialMedia"
            )}
          </Text>
          <div className="flex sm:flex-wrap flex-row gap-4 items-start justify-start w-full">
            {orderData?.qcData.map((items, index) => {
              return (
                <Img
                  key={`images-${index}`}
                  className="cursor-pointer h-[64px] w-[64px] object-cover rounded-lg"
                  src={items?.imageUrl}
                  alt="rectangle19120"
                  loading="lazy"
                  onClick={() => handelsetViewImageModalOpen()}
                />
              );
            })}
          </div>
        </div>
      </div>

      {ShareModalOpen === true ? (
        <Share closepopup={setShareModalOpen}  images={orderData?.qcData.map(item => item.imageUrl)}/>
      ) : null}
      {ViewImageModalOpen === true ? (
        <ViewImage closepopup={setViewImageModalOpen} images={orderData?.qcData.map(item => item.imageUrl)}/>
      ) : null}
    </>
  );
};

export default OrderDetailProcessing;
