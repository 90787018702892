import React, { useEffect, useState } from "react";
import { Img, List, Text } from "components";
import Footer from "components/Footer";
import HomeBlog from "components/HomeBlog";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import * as CUSTOM from "../../utils/helper/custom";
import * as API from "../../utils/helper/Enum";
import EmptyAll from "pages/EmptyList";
import { getAuth } from "../../redux/reducers/loginData";

const BlogListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const [categoryList, setCategoryList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  const [apiStatus, setApiStatus] = useState(false);
  const setActiveCategoryId = useState(0)[1];
  const [tabs, setTabs] = React.useState(1);

  const Button = styled.div`
    /* Add the selected styles when the component is selected */
    ${({ isSelected }) =>
      isSelected &&
      `
      border-bottom: 2px solid;  
      border-color: #bd0043 !important;
      border-radius: 0px;
    `}
  `;

  /**
   * get blog category
   */
  const getBlogCategory = async () => {
    try {
      let response = await globalRequest(
        API?.BLOGS?.CATEGORIES_LIST,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setCategoryList(response?.data);
        getBlogData(response?.data[0]?.id);
      }
    } catch (e) {}
  };

  /**
   * get category details
   */
  const getBlogData = async (id) => {
    setTabs(id);
    setApiStatus(false);
    setActiveCategoryId(id);
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        `${API?.BLOGS?.GET_BLOCK_BY_CATEGORY_ID}`,
        "get",
        {},
        {
          params: {
            page: 1,
            limit: 1000,
            blogCategoryId: id,
            user_id: auth?.id,
          },
        },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setBlogList(response?.data?.data);
      } else {
        setBlogList([]);
      }
    } catch (e) {
      setBlogList([]);
    }
    setApiStatus(true);
    dispatch(changeLoader(false));
  };

  /**
   * like unline blog post
   */
  const likeBlog = async (id) => {
    if (auth?.id > 0) {
      try {
        let response = await globalRequest(
          `${API?.BLOGS?.LIKE_DISLIKE}/${id}`,
          "patch",
          {},
          {},
          true
        );
        response = response?.data;
        if (response?.status == "SUCCESS") {
          setBlogList((prevBlogList) => {
            return prevBlogList.map((blog) => {
              if (blog.id === id) {
                return {
                  ...blog,
                  isLike: !blog?.isLike ? true : false,
                  likes: !blog?.isLike
                    ? Number(blog?.likes) + 1
                    : blog?.likes - 1,
                };
              }
              return blog;
            });
          });
        }
      } catch (e) {}
    }
  };

  useEffect(() => {
    getBlogCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white-A700 flex flex-col font-nunitoregular items-center justify-start mx-auto w-full">
        <div className="flex flex-col items-center justify-start w-full">
          <div className="bg-teal-50 flex flex-col items-start justify-start w-full overflow-hidden	">
            <div className="relative flex flex-row gap-[42px] items-center justify-start md:px-5 w-full">
              <div className="flex flex-col md:gap-10 gap-[100px] items-end justify-start w-[7%] absolute ltr:left-0 rtl:right-0">
                <Img
                  className="h-[134px] md:h-auto object-cover"
                  src="/images/img_clippathgroup_gray_100.png"
                  alt="clippathgroup"
                />
                <Img
                  className="h-[17px] w-4"
                  src="/images/img_close_red_300.svg"
                  alt="close"
                />
              </div>
              <div className="h-[350px] sm:h-[300px] xs:h-[250px] relative max-w-[1110px] w-full mx-auto flex flex-col items-start justify-center">
                <div className="absolute h-[350px] sm:h-[300px] inset-y-[0] my-auto right-[2%] w-[89%] md:w-full">
                  <div className="absolute flex flex-col h-full inset-y-[0] items-center justify-start left-[0] my-auto w-[93%]">
                    <div className="flex flex-row items-start justify-start w-full">
                      <Img
                        className="h-[116px] mt-[233px]"
                        src="/images/img_clippathgroup_orange_50.svg"
                        alt="clippathgroup_One"
                      />
                      <Img
                        className="h-[133px] ml-[116px] sm:mt-0 mt-[138px]"
                        src="/images/img_clippathgroup_black_900.svg"
                        alt="clippathgroup_Two"
                      />
                      <Img
                        className="h-[292px] mb-[57px] ml-[91px]"
                        src="/images/img_clippathgroup_black_900_292x344.svg"
                        alt="clippathgroup_Three"
                      />
                    </div>
                  </div>
                  <Img
                    className="absolute h-[278px] inset-y-[0] my-auto object-cover right-[0] rtl:right-[auto] rtl:left-[0] w-[278px] sm:right-[-12%] xs:right-[-20%] xs:top-[80%]"
                    src="/images/img_image64.png"
                    alt="imageSixtyFour"
                  />
                </div>
                <Img
                  className="absolute h-[37px] right-[0] rtl:right-[auto] rtl:left-[0] top-[39%]"
                  src="/images/img_alarm.svg"
                  alt="alarm"
                />
                <Img
                  className="absolute bottom-[14%] h-14 right-[35%]"
                  src="/images/img_vector.svg"
                  alt="vector"
                />
                <Img
                  className="absolute h-[17px] right-[43%] top-[18%] w-4"
                  src="/images/img_close_deep_orange_100.svg"
                  alt="close_One"
                />
                <Img
                  className="absolute h-[22px] left-[20%] top-[9%]"
                  src="/images/img_signal.svg"
                  alt="signal"
                />
                <Text
                  className="leading-[56.00px] sm:text-4xl xs:text-xl md:text-[38px] text-[40px] text-black-900 mt-auto z-[20] rtl:text-right"
                  size="txtnunitoBold40Black900"
                >
                  <>
                    {t("wePostTrendsAndTutorials")}
                    <br />
                    {t("forModernCakesMakingProcess")}
                  </>
                </Text>
                <Text
                  className="leading-[24.00px] text-base text-black-900 w-[56%] sm:w-full mb-auto z-[20] mt-4 rtl:text-right"
                  size="txtnunitoRegular16"
                >
                  <>
                    {t(
                      "hereYouAndWillFindArticlesOnTheLatestWellnessTrendsAndTopicsDeliciousPlantBasedRecipesTipTricksAndMuchMoreExclamationMark"
                    )}
                  </>
                </Text>
              </div>
            </div>
          </div>

          {categoryList.length ? (
            <div className="bg-light_blue-50 flex flex-col items-start justify-start w-full">
              <div className="gap-1 flex items-start justify-start max-w-[745px] w-full mx-auto xs:flex-wrap">
                {categoryList.map((items, index) => {
                  return (
                    <Button
                      className="cursor-pointer flex-1 font-bold text-center text-gray_600 tracking-[0.32px] w-full p-3 min-w-[100px] text-sm border-b-[2px] rounded-none border-transparent"
                      key={`categody-id-${index}`}
                      isSelected={tabs === items?.id}
                      onClick={() => getBlogData(items?.id)}
                    >
                      {CUSTOM.getdataByLangKey(
                        items?.blogCategoryLocales,
                        "",
                        "name"
                      )}
                    </Button>
                  );
                })}
              </div>
            </div>
          ) : null}

          {blogList.length == 0 && apiStatus ? <EmptyAll type="blogs" /> : null}

          {blogList.length ? (
            <List
              className="sm:flex-col flex-row gap-[30px] grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 grid-cols-4 justify-center max-w-[1110px] mt-[31px] mx-auto md:px-5 w-full mb-8"
              orientation="horizontal"
            >
              {blogList.map((items, index) => {
                return (
                  <HomeBlog
                    keys={index}
                    auth={auth}
                    data={items}
                    userimage={items?.imageLink}
                    likeBlog={likeBlog}
                    viewcount={CUSTOM.formatViewCount(items?.views)}
                    likecount={CUSTOM.formatViewCount(items?.likes)}
                    title={CUSTOM.getdataByLangKey(
                      items?.blogLocales,
                      "",
                      "title"
                    )}
                    description={CUSTOM.getdataByLangKey(
                      items?.blogLocales,
                      "",
                      "shortDescription"
                    )}
                  />
                );
              })}
            </List>
          ) : null}
          {/* 
          <div className="flex h-12 items-center justify-center mt-4 mb-10 rounded-[50%] w-full">
            <Img
              src="/images/Loaderanim.png"
              className="animate-spin"
              alt="loaderanim"
            />
          </div> */}
          <Footer className="flex font-nunitoregular items-center justify-center mt-[97px] md:px-5 w-full" />
        </div>
      </div>
    </>
  );
};

export default BlogListPage;
