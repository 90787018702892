import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, Img, Button, Line } from "components";
// import EmptyList from "../EmptyList";
import TrackHistory from "./TrackHistory";
import ItemsBox from "./ItemsBox";
import TotalBillSection from "./TotalBillSection";
import * as CUSTOM from "../../../../utils/helper/custom";
import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import * as APIS from "../../../../utils/helper/Enum";
import OrderDetailGift from "./OrderDetailGift";
import MakePaymentWallet from "../MakePaymentWallet";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import OrderDetailCustomCake from "./OrderDetailCustomCake";
import CustomOrderDetailGift from "./CustomOrderDetailGift";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { CancelOrder } from "popups/CancelOrder";
import { useNavigate } from "react-router-dom";
import { changeTrigger } from "redux/reducers/trigger";
import { AdjustOrder } from "popups/AdjustOrder";
import OrderDetailProcessing from "./OrderDetailProcessing";
import PickUpPointAddress from "./PickUpPointAddress";
import MapContainer from "./DriverMap";

const OrderDetailDelevery = ({ orderData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const [open, setOpen] = useState(false);
  //const [isCustomCakePayment, setIsCustomCakePayment] = useState(false);
  const [isGiftPayment, setIsGiftPayment] = useState(false);
  const [showNeedHelp, setShowNeedHelp] = useState(false);
  const [isCancelOrder, setIsCancelOrder] = useState(false);
  const [isArjustOrder, setIsArjustOrder] = useState(false);

  /**
   * download the invoice
   */
  const getInvoice = async (order_id) => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        `${APIS?.ORDER?.DOWNLOAD_INVOICE}/${order_id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        window.open(response?.data, "_blank");
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /**
   * Reorder Api
   */
  const reorderApi = async (order_id) => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        `${APIS?.ORDER?.RE_ORDER}/${order_id}`,
        "post",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(changeTrigger("fetchCartCount"));
        setTimeout(() => {
          dispatch(changeLoader(false));
          navigate("/cart");
        }, [1000]);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
        dispatch(changeLoader(false));
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  };

  /**
   *  Function to copy order Number
   */
  const copyText = (textToCopy) => {
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(textarea);
    dispatch(
      setSnackbar({
        snackbarOpen: true,
        snackbarMessage: "Text copied to clipboard",
        snackbarState: "success",
      })
    );
  };

  const pickupDelivered = () => {
    if (orderData?.showPickupDayAlert) {
      return (
        <div className="bg-light_blue-50 flex flex-col justify-start mt-6 p-5 rounded w-full">
          <div className="flex flex-row gap-3 justify-start">
            <Img
              src="/images/img_info-black.svg"
              className="w-6 h-6"
              alt="info"
            />
            <div className="relative">
              <Text className="font-nunitoregular text-sm block items-center text-black-900 leading-[24px] mb-3">
                {t("youWillHaveToPickYourOrder")}{" "}
                <Text className="font-nunitosemibold text-sm text-black-900 inline">
                  {t("todayUppercase")}
                </Text>{" "}
                {t("fromTheBelowPickupPointOrElseYourOrderWillGetCancelledDot")}
              </Text>
            </div>
          </div>
        </div>
      );
    }
    return "";
  };

  return (
    <>
      {orderData?.orderData?.deliveryMode == "delivery" &&
      orderData?.orderData?.deliveryType == "fastest" &&
      orderData?.orderData?.status == "out_for_delivery" ? (
        <>
          <MapContainer
            branchDetail={orderData?.orderData?.branchDetails}
            addressDetail={orderData?.orderData?.addressDetails}
            driverDetail={orderData?.orderData?.driverDetails}
          />
        </>
      ) : null}
      {orderData?.orderData?.orderType == "regular" ? (
        <>
          <div className="flex flex-row items-center justify-start w-full mb-9"></div>
          <div className="flex sm:flex-col flex-row sm:gap-10 items-start justify-between w-full">
            <Text
              onClick={() => {
                copyText(orderData?.orderData?.orderNumber);
              }}
              style={{ cursor: "pointer" }}
              className="font-nunitosemibold text-2xl md:text-[22px] text-black-900 sm:text-xl"
            >
              {t("order")} #{orderData?.orderData?.orderNumber}
              {/* <ContentCopyRounded onClick={()=>{copyText(orderData?.orderData?.orderNumber)}} style={{ cursor: 'pointer' }} /> */}
            </Text>
            <div className="flex gap-3 items-center justify-end flex-row">
              <div className="flex gap-3 justify-end">
                {orderData?.orderData?.status == "delivered" ? (
                  <Button
                    className="min-w-[125px] flex items-center justify-center"
                    size="sm"
                    variant="OutlineBlack"
                    hover={true}
                    hoverclass="bg-black-900"
                    leftIcon={
                      <Img
                        className="h-5 mt-px btn-icon"
                        src="/images/reorder.svg"
                        alt="download"
                      />
                    }
                  >
                    <Text
                      className="mx-2 font-nunitomedium"
                      as="span"
                      onClick={(e) => {
                        reorderApi(orderData?.orderData?.id);
                      }}
                    >
                      {t("reOrder")}
                    </Text>
                  </Button>
                ) : (
                  ""
                )}
                {orderData?.orderData?.status !== "pending" && orderData?.orderData?.status !== "rejected" && orderData?.orderData?.status !== "approved" ? (
                  <Button
                    className="min-w-[125px] flex items-center justify-center"
                    size="sm"
                    variant="OutlineBlack"
                    hover={true}
                    hoverclass="bg-black-900"
                    leftIcon={
                      <Img
                        className="h-5 mt-px btn-icon"
                        src="/images/download-icon.svg"
                        alt="download"
                      />
                    }
                  >
                    <Text
                      className="mx-2 font-nunitomedium"
                      as="span"
                      onClick={(e) => {
                        getInvoice(orderData?.orderData?.id);
                      }}
                    >
                      {t("downloadInvoice")}
                    </Text>
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {pickupDelivered()}
          {orderData?.orderData?.deliveryMode == "delivery" &&
          orderData?.orderData?.status == "no_show" ? (
            <PickUpPointAddress data={orderData?.orderData?.branchDetails} />
          ) : null}
          {orderData?.orderData?.qcData?.length ? (
            <OrderDetailProcessing orderData={orderData?.orderData} />
          ) : null}

          <div className="mt-[34px] relative w-full">
            <Line className="absolute top-[20px] h-[100px] left-[15px] rtl:left-auto rtl:right-[15px] w-px ltr:border-l rtl:border-r border-dashed border-gray-900" />
            <div className="relative flex flex-row gap-4 items-start justify-start w-fulll mb-5">
              <Button className="bg-red-100 flex h-8 items-center justify-center p-1 w-8 mt-2">
                <Img
                  className="h-5 w-5"
                  src="/images/location_on.svg"
                  alt="location_Two"
                />
              </Button>
              <div className="flex flex-col gap-1 items-start justify-start w-full">
                <Text className="font-nunitomedium text-base text-black-900">
                  {orderData?.orderData?.userDetails?.personalInformation
                    ?.first_name ||
                    orderData?.orderData?.userDetails?.personalInformation
                      ?.last_name}{" "}
                  |{" "}
                  {CUSTOM.mobileWithCountryCode(
                    orderData?.orderData?.userDetails?.personalInformation
                      ?.mobile_no
                  )}
                </Text>
                {orderData?.orderData?.deliveryMode == "delivery" ? (
                  <>
                    <Text className="font-nunitoregular text-black-900 text-sm">
                      {orderData?.orderData?.addressDetails?.address}
                    </Text>
                    <Text className="text-gray-700 text-xs font-nunitoregular">
                      {t("deliveryAddress")}
                    </Text>
                  </>
                ) : null}
                {orderData?.orderData?.deliveryMode == "pickup" ? (
                  <>
                    <Text className="font-nunitoregular text-black-900 text-sm">
                      {orderData?.orderData?.pickupPointDetails?.address}
                    </Text>
                    <Text className="text-gray-700 text-xs font-nunitoregular">
                      {t("PickUpPoint")}: {"("}
                      <span className="font-nunitoregular text-black-900 text-xs">
                        {CUSTOM.getdataByLangKey(
                          orderData?.orderData?.pickupPointDetails
                            ?.dropZoneLocales,
                          "",
                          "name"
                        )}
                      </span>
                      {")"}
                    </Text>
                  </>
                ) : null}
              </div>
            </div>
            <div className="relative flex flex-row gap-4 items-start justify-start w-full">
              <Button className="bg-red-100 flex h-8 items-center justify-center p-1 w-8">
                <Img
                  className="h-5 w-5"
                  src="/images/event_note.svg"
                  alt="calendar"
                />
              </Button>
              <div className="flex flex-col gap-1 items-start justify-start">
                <Text className="font-nunitomedium text-base text-black-900">
                  {CUSTOM.changeDateFormat(
                    orderData?.orderData?.deliveryDate,
                    "dd/MM/yyyy"
                  )}{" "}
                  {orderData?.orderData?.timeSlotDetails
                    ? CUSTOM.formatTimeRange(
                        orderData?.orderData?.timeSlotDetails?.openTime,
                        orderData?.orderData?.timeSlotDetails?.closeTime
                      )
                    : ""}
                </Text>
                <Text className="font-nunitoregular text-gray-700 text-xs">
                  {orderData?.orderData?.deliveryType == "fastest"
                    ? t("fastestTime")
                    : t("scheduledDateAndTime")}
                </Text>
              </div>
            </div>
          </div>
          <TrackHistory orderData={orderData?.orderData} type="regular" />
          <Line className="bg-black-900 h-px w-full" />
          <ItemsBox orderData={orderData?.orderData} />
          <Line className="bg-black-900 h-px w-full mt-8 mb-6" />
          <TotalBillSection orderData={orderData} />
        </>
      ) : null}

      {orderData?.orderData?.orderType == "custom_cake" ? (
        <>
          {isGiftPayment ? (
            <MakePaymentWallet
              orderData={orderData?.orderData}
              setIsGiftPayment={setIsGiftPayment}
            ></MakePaymentWallet>
          ) : (
            <>
              <div className="flex flex-row items-center justify-start w-full mb-9"></div>
              <div className="flex sm:flex-col flex-row sm:gap-10 items-start justify-between w-full">
                <Text
                  onClick={() => {
                    copyText(orderData?.orderData?.orderNumber);
                  }}
                  style={{ cursor: "pointer" }}
                  className="font-nunitosemibold text-2xl md:text-[22px] text-black-900 sm:text-xl"
                >
                  {t("order")} #{orderData?.orderData?.orderNumber}
                  {/* <ContentCopyRounded onClick={()=>{copyText(orderData?.orderData?.orderNumber)}} style={{ cursor: 'pointer' }} /> */}
                </Text>
                {orderData?.orderData?.status !== "pending" && orderData?.orderData?.status !== "rejected" && orderData?.orderData?.status !== "approved" ? (
                  <Button
                    className="min-w-[125px] flex items-center justify-center"
                    size="sm"
                    variant="OutlineBlack"
                    hover={true}
                    hoverclass="bg-black-900"
                    leftIcon={
                      <Img
                        className="h-5 mt-px btn-icon"
                        src="/images/download-icon.svg"
                        alt="download"
                      />
                    }
                  >
                    <Text
                      className="mx-2 font-nunitomedium"
                      as="span"
                      onClick={(e) => {
                        getInvoice(orderData?.orderData?.id);
                      }}
                    >
                      {t("downloadInvoice")}
                    </Text>
                  </Button>
                ) : null}
              </div>
              {pickupDelivered()}
              {orderData?.orderData?.deliveryMode == "delivery" &&
              orderData?.orderData?.status == "no_show" ? (
                <PickUpPointAddress
                  data={orderData?.orderData?.branchDetails}
                />
              ) : null}
              {orderData?.orderData?.qcData?.length ? (
                <OrderDetailProcessing orderData={orderData?.orderData} />
              ) : null}
              <OrderDetailCustomCake
                setIsGiftPayment={setIsGiftPayment}
                orderData={orderData?.orderData}
              ></OrderDetailCustomCake>
              <TrackHistory
                orderData={orderData?.orderData}
                type="custom_cake"
              />
              <Line className="bg-black-900 h-px w-full" />
              <ItemsBox orderData={orderData?.orderData} />
              <Line className="bg-black-900 h-px w-full mt-8 mb-6" />
              <TotalBillSection orderData={orderData} />
            </>
          )}
        </>
      ) : null}

      {orderData?.orderData?.orderType == "gift" ? (
        <>
          {isGiftPayment ? (
            <>
              <MakePaymentWallet
                orderData={orderData?.orderData}
                setIsGiftPayment={setIsGiftPayment}
              ></MakePaymentWallet>
            </>
          ) : (
            <>
              <div className="flex flex-row items-center justify-start w-full mb-9"></div>
              <div className="flex sm:flex-col flex-row sm:gap-10 items-start justify-between w-full">
                <Text
                  onClick={() => {
                    copyText(orderData?.orderData?.orderNumber);
                  }}
                  style={{ cursor: "pointer" }}
                  className="font-nunitosemibold text-2xl md:text-[22px] text-black-900 sm:text-xl"
                >
                  {t("order")} #{orderData?.orderData?.orderNumber}
                  {/* <ContentCopyRounded onClick={()=>{copyText(orderData?.orderData?.orderNumber)}} style={{ cursor: 'pointer' }} /> */}
                </Text>
                {orderData?.orderData?.status !== "pending" && orderData?.orderData?.status !== "rejected" && orderData?.orderData?.status !== "approved" ? (
                  <Button
                    className="min-w-[125px] flex items-center justify-center"
                    size="sm"
                    variant="OutlineBlack"
                    hover={true}
                    hoverclass="bg-black-900"
                    leftIcon={
                      <Img
                        className="h-5 mt-px btn-icon"
                        src="/images/download-icon.svg"
                        alt="download"
                      />
                    }
                  >
                    <Text
                      className="mx-2 font-nunitomedium"
                      as="span"
                      onClick={(e) => {
                        getInvoice(orderData?.orderData?.id);
                      }}
                    >
                      {t("downloadInvoice")}
                    </Text>
                  </Button>
                ) : null}
              </div>
              {pickupDelivered()}
              {orderData?.orderData?.deliveryMode == "delivery" &&
              orderData?.orderData?.status == "no_show" ? (
                <PickUpPointAddress
                  data={orderData?.orderData?.branchDetails}
                />
              ) : null}
              {orderData?.orderData?.qcData?.length ? (
                <OrderDetailProcessing orderData={orderData?.orderData} />
              ) : null}
              <OrderDetailGift
                orderData={orderData?.orderData}
                setIsGiftPayment={setIsGiftPayment}
              />
              <TrackHistory orderData={orderData?.orderData} type="gift" />
              <Line className="bg-black-900 h-px w-full" />
              <ItemsBox orderData={orderData?.orderData} />
              <Line className="bg-black-900 h-px w-full mt-8 mb-6" />
              <TotalBillSection orderData={orderData} />
            </>
          )}
        </>
      ) : null}

      {orderData?.orderData?.orderType == "custom_cake_gift" ? (
        <>
          {isGiftPayment ? (
            <>
              <MakePaymentWallet
                orderData={orderData?.orderData}
                setIsGiftPayment={setIsGiftPayment}
              ></MakePaymentWallet>
            </>
          ) : (
            <>
              <div className="flex flex-row items-center justify-start w-full mb-9"></div>
              <div className="flex sm:flex-col flex-row sm:gap-10 items-start justify-between w-full">
                <Text
                  onClick={() => {
                    copyText(orderData?.orderData?.orderNumber);
                  }}
                  style={{ cursor: "pointer" }}
                  className="font-nunitosemibold text-2xl md:text-[22px] text-black-900 sm:text-xl"
                >
                  {t("order")} #{orderData?.orderData?.orderNumber}
                  {/* <ContentCopyRounded onClick={()=>{copyText(orderData?.orderData?.orderNumber)}} style={{ cursor: 'pointer' }} /> */}
                </Text>
                {orderData?.orderData?.status !== "pending" && orderData?.orderData?.status !== "rejected" && orderData?.orderData?.status !== "approved" ? (
                  <Button
                    className="min-w-[125px] flex items-center justify-center"
                    size="sm"
                    variant="OutlineBlack"
                    hover={true}
                    hoverclass="bg-black-900"
                    leftIcon={
                      <Img
                        className="h-5 mt-px btn-icon"
                        src="/images/download-icon.svg"
                        alt="download"
                      />
                    }
                  >
                    <Text
                      className="mx-2 font-nunitomedium"
                      as="span"
                      onClick={(e) => {
                        getInvoice(orderData?.orderData?.id);
                      }}
                    >
                      {t("downloadInvoice")}
                    </Text>
                  </Button>
                ) : null}
              </div>
              {pickupDelivered()}
              {orderData?.orderData?.deliveryMode == "delivery" &&
              orderData?.orderData?.status == "no_show" ? (
                <PickUpPointAddress
                  data={orderData?.orderData?.branchDetails}
                />
              ) : null}
              {orderData?.orderData?.qcData?.length ? (
                <OrderDetailProcessing orderData={orderData?.orderData} />
              ) : null}
              <CustomOrderDetailGift
                orderData={orderData?.orderData}
                setIsGiftPayment={setIsGiftPayment}
              />
              <TrackHistory orderData={orderData?.orderData} type="gift" />
              <Line className="bg-black-900 h-px w-full" />
              <ItemsBox orderData={orderData?.orderData} />
              <Line className="bg-black-900 h-px w-full mt-8 mb-6" />
              <TotalBillSection orderData={orderData} />
            </>
          )}
        </>
      ) : null}
      {/** need help section start */}

      {orderData?.orderData?.orderType ? (
        <>
          <Line className="bg-black-900 h-px w-full mt-8 mb-6" />
          <Text
            className="font-nunitoregular text-sm text-pink-800 mb-4 cursor-pointer mt-6"
            onClick={(e) => {
              setShowNeedHelp(true);
            }}
          >
            {t("needHelpOrderHeading")}
          </Text>
          <SwipeableDrawer
            anchor="right"
            open={showNeedHelp}
            onClose={(e) => {
              setShowNeedHelp(false);
            }}
            onOpen={(e) => {
              setShowNeedHelp(true);
            }}
          >
            <div className="w-full max-w-[400px] px-[30px] sm:px-4 py-8 sm:py-4 before-pink-round ">
              <div className="flex flex-row gap-3 items-center justify-start w-full">
                <Img
                  className="h-6 w-6 cursor-pointer"
                  src="/images/img_close_black_900.svg"
                  alt="close"
                  onClick={(e) => {
                    setShowNeedHelp(false);
                  }}
                />
              </div>
              {/* content here */}
              <div className="flex flex-col items-start justify-between w-full">
                <Img
                  className="h-auto w-[200px] cursor-pointer m-auto"
                  src="/images/customer-support.png"
                  alt="image"
                />
                <Text
                  className="text-2xl md:text-[22px] text-black-900 sm:text-xl text-center w-full mt-3"
                  size="txtnunitoSemiBold24"
                >
                  {t("needHelp")}
                </Text>
                <Text
                  className="mt-3 mb-12 text-base text-gray-700 text-center w-full"
                  size="txtnunitoRegular16Gray700"
                >
                  {t("doYouWantToCallCustomerCare")}
                  <br />
                  {`+966 9876543210`}{" "}
                  {orderData?.orderData?.orderType == "custom_cake" ||
                  orderData?.orderData?.orderType == "custom_cake_gift"
                    ? t("ForHelpOrCancelThisOrder")
                    : t("ForHelpOrCancelOrAdjustThisOrder")}
                </Text>
                <a
                  href="tel:+123456789"
                  className="bg-black-900 cursor-pointer py-[11px] rounded text-base text-center text-white-A700 w-full"
                >
                  {t("callNow")}
                </a>

                <>
                  <div
                    className={`flex flex-row font-nunitomedium gap-5 items-center ${
                      orderData?.hideCancelButton == 0 &&
                      orderData?.hideAdjustOrderButton == 0
                        ? "justify-start"
                        : "justify-center"
                    } mt-5 w-full`}
                  >
                    {orderData?.hideCancelButton == 0 ? (
                      <Button
                        className="common-pointer border border-black-900 border-solid cursor-pointer py-[11px] rounded text-base text-black-900 text-center w-[164px] xs:w-auto xs:flex-1"
                        onClick={(e) => {
                          setIsCancelOrder(true);
                          setShowNeedHelp(false);
                        }}
                      >
                        {t("cancelOrder")}
                      </Button>
                    ) : null}
                    {orderData?.hideAdjustOrderButton == 0 ? (
                      <Button
                        className="common-pointer border border-black-900 border-solid cursor-pointer py-[11px] rounded text-base text-black-900 text-center w-[164px] xs:w-auto xs:flex-1"
                        onClick={(e) => {
                          setIsArjustOrder(true);
                          setShowNeedHelp(false);
                        }}
                      >
                        {t("adjustOrder")}
                      </Button>
                    ) : null}
                  </div>
                </>
              </div>
            </div>
          </SwipeableDrawer>
        </>
      ) : null}
      {isCancelOrder ? (
        <CancelOrder closepopup={setIsCancelOrder} orderData={orderData} />
      ) : null}
      {isArjustOrder ? (
        <AdjustOrder closepopup={setIsArjustOrder} orderData={orderData} />
      ) : null}
      {/** need help section end */}
    </>
  );
};

export default OrderDetailDelevery;
