import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import * as CUSTOM from "../../../utils/helper/custom";
import { changeTrigger } from "redux/reducers/trigger";
import {
  Button,
  Img,
  Input,
  Line,
  MobileInput,
  Text,
  AutoFillAddress,
} from "components";
import {
  validateForm,
  strFormat,
  defaultAddressParams,
} from "../../../utils/helper/custom";
import {
  updateMyAddress,
  getAddressData,
} from "../../../redux/reducers/myAddress";

const AddEditAddress = (props) => {
  const { setAddAddressPopup, orderData, setInputData, giftorderData } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getAddress = useSelector(getAddressData);
  const [errors, setErrors] = useState({});

  const closepopupout = (type = false) => {
    dispatch(changeTrigger("closepopup"));
    setAddAddressPopup(false);
  };

  const saveAddress = async (e) => {
    e.preventDefault();
    let validationErrors = validateForm(getAddress, "addAddress", t);
    let validateMobile = CUSTOM.isValidMobile(getAddress?.mobile_no, t);
    validationErrors = { ...validationErrors, ...validateMobile };
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      dispatch(changeLoader(true));
      let addressData = {
        streetNumber: getAddress?.street_number,
        buildingNumber: getAddress?.building_number,
        floorApartment: getAddress?.floor_apartment,
        address: getAddress?.formatted_address,
        lattitude: getAddress?.lattitude,
        longitude: getAddress?.longitude,
        is_save: true
      }
      setInputData(addressData)
      // dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    let dAddress = defaultAddressParams();
    if (giftorderData?.addressData) {
      dAddress = giftorderData?.addressData
    }
    dAddress = { ...dAddress, full_name: orderData?.giftDetails?.recipient_name, mobile_no: CUSTOM?.getMobileNo(orderData?.giftDetails?.mobile_no, "number") }
    dispatch(updateMyAddress(dAddress));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {Object.keys(getAddress).length > 0 ? (
        <>
          <div className="all-popup-class justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none m-auto w-fit h-fit sm:w-full">
            <div className="relative w-auto my-6 md:my-0 mx-auto max-w-[1110px] min-w-[1110px] lg:max-w-[700px] lg:min-w-[700px] sm:w-full sm:min-w-full sm:max-w-full overflow-hidden">
              {/*content*/}
              <div className="border-0 rounded-lg md:rounded-b-none relative flex flex-col w-full bg-transparent outline-none focus:outline-none max-h-[80vh] md:max-h-[94vh] md:min-h-[auto] overflow-hidden">
                {/*header*/}
                <div className="flex flex-row items-center justify-end w-full px-3 pb-2 sm:hidden">
                  {/* <Text
                    className="text-2xl md:text-[22px] text-black-900 sm:text-xl m-0"
                    size="txtnunitoSemiBold24"
                  >
                    {!giftorderData?.addressData ? t("addNewAddress") : t("editAddress")}
                  </Text> */}
                  <div className="flex flex-row items-start justify-end w-auto">
                    <Img
                      src="/images/img_close_gray_700.svg"
                      className="common-pointer h-3 w-3"
                      alt="close"
                      onClick={() => closepopupout(false)}
                    />
                  </div>
                </div>
                {/*body*/}
                <div className="flex flex-row sm:flex-col items-start justify-start rounded-lg w-full overflow-auto">
                  <div className="mb-auto w-[53%] sm:w-full z-[1] relative sm:h-[calc(100vh_-_330px)] sm:overflow-auto">
                    <div className="absolute md:relative top-[0px] inset-x-[0]">
                      <AutoFillAddress></AutoFillAddress>
                    </div>
                  </div>
                  <div className="flex flex-col font-nunitomedium items-start justify-start p-5 w-[47%] sm:w-full z-[1] overflow-auto  h-[70vh] sm:h-[auto] sm:max-h-[calc(100vh_-_370px)] sm:rounded-t-xl bg-[#ffffff] sm:pt-5 sm:-mt-[10px]">
                    <form id="addressForm" className="w-full" onSubmit={saveAddress}>
                      {
                        getAddress?.address ?
                          <p className="mb-6 sm:mb-4 flex gap-2 items-center"><img src="/images/location_on_black.svg" className="" alt="" /><span className="truncate">{getAddress?.address}</span></p>
                          :
                          <p className="mb-6 sm:mb-4 truncate flex gap-2 items-center" onChange={(e) => {
                            setErrors({ ...errors, address: "" });
                          }}><img src="/images/location_on_black.svg" className="" alt="" /> <span className="truncate">{`${t("streetAddress")}*`}</span></p>

                      }
                      <div className="grid sm:gap-4 sm:grid-cols-2 grid-cols-1">
                        <Input
                          className="w-full error-fixed"
                          label={`${t("fullname")}*`}
                          error={errors?.full_name}
                          value={getAddress?.full_name}
                          onChange={(e) => {
                            setErrors({ ...errors, full_name: "" });
                            dispatch(
                              updateMyAddress({
                                ...getAddress,
                                full_name: strFormat(
                                  e.target.value,
                                  "max:50|text"
                                ),
                              })
                            );
                          }}
                        />
                        <MobileInput
                          className="w-full error-fixed"
                          label={`${t("mobile")}*`}
                          error={errors?.mobile_no}
                          value={getAddress?.mobile_no}
                          onChange={(e) => {
                            setErrors({ ...errors, mobile_no: "" });
                            dispatch(
                              updateMyAddress({
                                ...getAddress,
                                mobile_no: strFormat(
                                  e.target.value,
                                  "max:9|number"
                                ),
                              })
                            );
                          }}
                        />
                      </div>
                      {/* <Input
                        className="w-full"
                        label={`${t("streetAddress")}*`}
                        error={errors?.address}
                        value={getAddress?.address}
                        readOnly="true"
                        onChange={(e) => {
                          setErrors({ ...errors, address: "" });
                        }}
                      /> */}
                      <div className="grid sm:gap-4 sm:grid-cols-2 grid-cols-1">
                        <Input
                          className="w-full error-fixed"
                          label={`${t("streetNumber")}*`}
                          error={errors?.street_number}
                          value={getAddress?.street_number}
                          onChange={(e) => {
                            setErrors({ ...errors, street_number: "" });
                            dispatch(
                              updateMyAddress({
                                ...getAddress,
                                street_number: strFormat(
                                  e.target.value,
                                  "max:50"
                                ),
                              })
                            );
                          }}
                        />
                        <Input
                          className="w-full error-fixed"
                          label={`${t("buildingNumber")}*`}
                          error={errors?.building_number}
                          value={getAddress?.building_number}
                          onChange={(e) => {
                            setErrors({ ...errors, building_number: "" });
                            dispatch(
                              updateMyAddress({
                                ...getAddress,
                                building_number: strFormat(
                                  e.target.value,
                                  "max:50"
                                ),
                              })
                            );
                          }}
                        />
                      </div>
                      <Input
                        className="w-full error-fixed"
                        label={`${t("floor_Apartment")}*`}
                        error={errors?.floor_apartment}
                        value={getAddress?.floor_apartment}
                        onChange={(e) => {
                          setErrors({ ...errors, floor_apartment: "" });
                          dispatch(
                            updateMyAddress({
                              ...getAddress,
                              floor_apartment: strFormat(
                                e.target.value,
                                "max:50"
                              ),
                            })
                          );
                        }}
                      />
                      <div className="flex items-center gap-3">
                      <Button
                        className="w-full hidden sm:block sm:py-1 sm:h-10"
                        size="md"
                        type="button"
                        variant="OutlineBlack"
                        onClick={() => closepopupout(false)}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        className="w-full sm:py-1 sm:h-10 "
                        size="md"
                        variant="FillBlack"
                        hover={true}
                        hoverclass="bg-white-A700"
                      >
                        {t("save")}
                      </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="opacity-75 fixed inset-0 z-40 bg-black-900"
            onClick={() => closepopupout(false)}
          ></div>
        </>
      ) : null}
    </>
  );
};
export { AddEditAddress };
