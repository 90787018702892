import React, { useState, useEffect } from "react";
import { Button, Img, MobileInput, Input, Text } from "components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { YourDetails } from "popups/YourDetails";
import { getAuth } from "../../../../redux/reducers/loginData";
import globalRequest from "../../../../utils/global/globalRequest";
import * as CUSTOM from "../../../../utils/helper/custom";
import * as APIS from "../../../../utils/helper/Enum";
import { changeLoader } from "../../../../redux/reducers/loader";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import { changeTrigger } from "redux/reducers/trigger";
import {
  updateOrderData,
  getOrderData,
} from "../../../../redux/reducers/orderData";
import {
  addDeleteGetLocalStorage,
  storageKeys,
} from "../../../../utils/global/localData";

let defaultGuestId = CUSTOM.getDeviceID();
let defaultGuestData = {
  account_name: "Guest",
  first_name: "",
  last_name: "",
  mobile_no: "",
  email: "",
  guest_id: defaultGuestId,
  isData: false,
};

// let defaultSignupData = {
//   first_name: "",
//   last_name: "",
//   mobile_no: "",
//   email: "",
//   otp: "",
//   email_otp: "",
// };

const Account = ({ addressList }) => {
  const { t } = useTranslation();
  const [YourDetailsOpen, setYourDetailsOpen] = useState(false);
  const auth = useSelector(getAuth);
  const [formType, setFormType] = useState("");
  const [guestButtonDisable, setGuestButtonDisable] = useState(false);
  const [timer, setTimer] = useState(0);
  const [emailTimer, setEmailTimer] = useState(0);
  const orderData = useSelector(getOrderData);
  const [guestDetail, setGuestDetail] = useState(defaultGuestData);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    login: {},
    signup: {},
  });
  const [errorformData, setErrorFormData] = useState({
    login: {
      is_verify_disable: true,
    },
    signup: {
      is_verify_disable: true,
    },
  });

  /*
   * Check Login Form
   */
  const checkLoginForm = () => {
    let disabledBtn = true;
    if (formData?.login?.is_otp_sent) {
      if (CUSTOM.isValidField(formData?.login?.otp, "otp")) {
        disabledBtn = false;
      }
    } else {
      if (CUSTOM.isValidField(formData?.login?.mobile_no, "mobile")) {
        disabledBtn = false;
      }
    }
    setErrorFormData({
      ...errorformData,
      login: { ...errorformData.login, is_verify_disable: disabledBtn },
    });
  };

  /*
   * Check user for login
   */
  const checkUser = async () => {
    let validateMobile = CUSTOM.isValidMobile(formData?.login?.mobile_no, t);
    if (Object.keys(validateMobile).length > 0) {
      setErrorFormData({
        ...errorformData,
        login: {
          ...errorformData.login,
          ...validateMobile,
        },
      });
      return;
    }
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        APIS?.ONBOARDING_APIS?.CHECK_USER_BY_MOBILE,
        "post",
        formData?.login
      );
      response = response?.data;
      if (response?.status !== "SUCCESS") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
      if (response?.data?.is_new_user == 1) {
        setErrorFormData({
          ...errorformData,
          login: {
            ...errorformData.login,
            mobile_no: t("mobile_no_not_registered"),
          },
        });
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "success",
          })
        );
        setTimer(process.env.REACT_APP_OTP_TIMER);
        setErrorFormData({
          ...errorformData,
          login: { ...errorformData.login, is_verify_disable: true },
        });
        setFormData({
          ...formData,
          login: { ...formData.login, otp: "", is_otp_sent: true },
        });
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /*
   *Login By mobile number
   */
  const login = async () => {
    try {
      let response = await globalRequest(
        APIS?.ONBOARDING_APIS?.LOGIN_WITH_OTP,
        "post",
        { ...formData?.login, device_id: CUSTOM.getDeviceID() }
      );
      response = response?.data;
      if (response?.status !== "SUCCESS") {
        setErrorFormData({
          ...errorformData,
          login: { ...errorformData.login, otp: response?.message },
        });
        return;
      }
      cancelForm("login");
      addDeleteGetLocalStorage(
        storageKeys?.USER_TOKEN,
        response?.data?.token,
        "add",
        "single"
      );
      dispatch(changeTrigger("loggedin"));
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /*
   * get guest details
   */
  const getGuestDetails = async () => {
    try {
      let response = await globalRequest(
        APIS?.GUEST?.GET_DETAILS,
        "get",
        {},
        {
          params: { guest_id: CUSTOM.getDeviceID() ? CUSTOM.getDeviceID() : 0 },
        }
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        let responseData = {
          account_name: "Guest",
          first_name: response?.data?.firstName,
          last_name: response?.data?.lastName,
          mobile_no: CUSTOM.getMobileNo(response?.data?.mobileNo, "No"),
          email: response?.data?.email,
          guest_id: CUSTOM.getDeviceID(),
          isData: true,
        };
        setGuestDetail(responseData);
        // dispatch(
        //   updateOrderData({
        //     pageStep: 2,
        //     userDetails: responseData,
        //     accountForm: "completed",
        //   })
        // );
        setFormType("");
      } else {
      }
    } catch (e) {}
  };

  /*
   * save guest details
   */
  const saveGuest = async (data = "", type = "") => {
    let moobileNo = type == "popup" ? data?.mobile_no : guestDetail?.mobile_no;
    let validateMobile = CUSTOM.isValidMobile(moobileNo, t);
    if (Object.keys(validateMobile).length > 0) {
      setErrorFormData({
        ...errorformData,
        login: {
          ...errorformData.login,
          ...validateMobile,
        },
      });
      return;
    }
    try {
      let payloadData = type == "popup" ? data : guestDetail;
      let response = await globalRequest(
        APIS?.GUEST?.ADD_GUEST,
        "post",
        payloadData
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "success",
          })
        );
        // dispatch(
        //   updateOrderData({
        //     pageStep: 2,
        //     userDetails: guestDetail,
        //     accountForm: "completed",
        //   })
        // );
        getGuestDetails();
        setFormType("");
        return true;
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
  };

  /*
   *validation form
   */
  const validateForm = (data, type = "login") => {
    let errors = {};
    switch (type) {
      case "guest":
        if (data?.mobile_no) {
          if (data?.mobile_no.length != 9) {
            errors.mobile_no = t("mobile_no_length_error");
          }
        } else {
          errors.mobile_no = t("mobile_no_required");
        }
        if (!data?.first_name) {
          errors.first_name = t("first_name_required");
        }
        if (data?.email) {
          if (!CUSTOM.isValidField(data?.email, "email")) {
            errors.email = t("email_name_required");
          }
        } 
        // else {
        //   errors.email = t("email_name_required");
        // }
        if (!data?.last_name) {
          errors.last_name = t("last_name_required");
        }
        break;

      case "signup":
        if (data?.mobile_no) {
          if (data?.mobile_no.length != 9) {
            errors.mobile_no = t("mobile_no_length_error");
          }
        } else {
          errors.mobile_no = t("mobile_no_required");
        }
        if (!data?.first_name) {
          errors.first_name = t("first_name_required");
        }
        if (data?.email) {
          const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          if (!emailRegex.test(data?.email)) {
            errors.email = t("email_name_required");
          }
        } 
        // else {
        //   errors.email = t("email_name_required");
        // }
        if (!data?.last_name) {
          errors.last_name = t("last_name_required");
        }
        break;

      default:
        break;
    }

    return errors;
  };

  /*
   *cancel form
   */
  const cancelForm = (type) => {
    if (type == "login") {
      setFormData({ ...formData, login: {} });
      setErrorFormData({
        ...errorformData,
        login: { is_verify_disable: true },
      });
    }
    if (type == "guest") {
      setGuestDetail(
        orderData?.userDetails ? orderData?.userDetails : defaultGuestData
      );
    }
    if (type == "signup") {
      setFormData({ ...formData, signup: {} });
      setErrorFormData({
        ...errorformData,
        signup: { is_verify_disable: true },
      });
    }
    setTimer(0);
    setFormType("");
  };

  /**
   * otp send for signup option
   */
  const sendSignupOtp = async () => {
    let validateMobile = CUSTOM.isValidMobile(formData?.signup?.mobile_no, t);
    if (Object.keys(validateMobile).length > 0) {
      setErrorFormData({
        ...errorformData,
        signup: {
          ...errorformData.signup,
          ...validateMobile,
        },
      });
      return;
    }
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        APIS?.CART?.CART_SIGNUP_SEND_OTP,
        "post",
        formData?.signup
      );
      response = response?.data;
      if (response.status != "SUCCESS") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "success",
          })
        );
        setFormData({
          formData,
          signup: { ...formData.signup, is_otp_sent: true, otp: "" },
        });
        setTimer(process.env.REACT_APP_OTP_TIMER);
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /**
   * resend otp
   */
  const sendOtp = async (type = "mobile") => {
    let validateMobile = CUSTOM.isValidMobile(formData?.signup?.mobile_no, t);
    if (Object.keys(validateMobile).length > 0) {
      setErrorFormData({
        ...errorformData,
        signup: {
          ...errorformData.signup,
          ...validateMobile,
        },
      });
      return;
    }
    let input_data = {
      mobile_no: formData?.signup?.mobile_no,
      email: formData?.signup?.email,
      type: type,
      first_name: formData?.signup?.first_name,
      last_name: formData?.signup?.last_name,
    };
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        APIS?.ONBOARDING_APIS?.SEND_OTP,
        "post",
        input_data
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "success",
          })
        );
        if (type == "email") {
          setFormData({
            formData,
            signup: { ...formData.signup, is_otp_sent: true, emailOtp: "" },
          });
          setEmailTimer(process.env.REACT_APP_OTP_TIMER);
          setErrorFormData({
            ...errorformData,
            signup: { ...errorformData.signup, emailOtp: "" },
          });
        } else if (type == "mobile") {
          setFormData({
            formData,
            signup: { ...formData.signup, is_otp_sent: true, otp: "" },
          });
          setTimer(process.env.REACT_APP_OTP_TIMER);
          setErrorFormData({
            ...errorformData,
            signup: { ...errorformData.signup, otp: "" },
          });
        } else {
          setFormData({
            formData,
            signup: {
              ...formData.signup,
              is_otp_sent: true,
              otp: "",
              emailOtp: "",
            },
          });
          setTimer(process.env.REACT_APP_OTP_TIMER);
          setEmailTimer(process.env.REACT_APP_OTP_TIMER);
        }
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }

    dispatch(changeLoader(false));
  };

  /**
   * user signup
   */
  const registration = async () => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        APIS?.ONBOARDING_APIS?.SIGNUP,
        "post",
        { ...formData?.signup, device_id: CUSTOM.getDeviceID(), otp_email: formData?.signup?.emailOtp }
      );
      response = response?.data;
      if (response?.status !== "SUCCESS") {
        if (response?.data?.type == "mobile") {
          setErrorFormData({
            ...errorformData,
            signup: { otp: response?.message },
          });
        } else if (response?.data?.type == "email") {
          setErrorFormData({
            ...errorformData,
            signup: { emailOtp: response?.message },
          });
        } else {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: response?.message,
              snackbarState: "error",
            })
          );
        }
        dispatch(changeLoader(false));
        return;
      }
      addDeleteGetLocalStorage(
        storageKeys?.USER_TOKEN,
        response?.data?.token,
        "add",
        "single"
      );
      cancelForm("signup");
      dispatch(changeTrigger("loggedin"));
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (!auth?.id) {
      let obj = { ...orderData };
      let isPageTwoStepComplete = false;
      if (guestDetail?.isData || orderData?.userDetails?.first_name) {
        isPageTwoStepComplete = true;
        obj.pageStep = 2;
        obj.accountForm = "completed";
        if (guestDetail?.isData) {
          obj.userDetails = guestDetail;
        }
      }
      if (addressList.length > 0 && isPageTwoStepComplete) {
        if (
          !orderData?.DeliveryModeActiveTab ||
          orderData?.DeliveryModeActiveTab == 1
        ) {
          obj = {
            ...obj,
            DeliveryModeActiveTab: 1,
            delivery_mode: "home_office",
            delivery_type: "",
            deliveryDateTimeActiveTab: 0,
            pageStep: 3,
            delivery_mode_show: "hide",
            home_office: {
              address: CUSTOM.buildFullAddress(addressList[0]),
              itemDetail: addressList[0],
            },
          };
        }
      }
      dispatch(updateOrderData(obj));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList, guestDetail]);

  useEffect(() => {
    if (
      auth?.id == 0 &&
      CUSTOM.getDeviceID() &&
      !orderData?.userDetails?.first_name
    ) {
      getGuestDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  /**
   * check user login
   */
  useEffect(() => {
    if (auth?.id != 0) {
      let newDataUser = {
        account_name: CUSTOM.replaceKeyValue(auth?.mobileNo, "-", " "),
        first_name: auth?.firstName,
        last_name: auth?.lastName,
        mobile_no: CUSTOM.getMobileNo(auth?.mobileNo, "number"),
        email: auth?.email,
        guest_id: defaultGuestId,
      };
      dispatch(
        updateOrderData({
          pageStep: 2,
          userDetails: newDataUser,
          accountForm: "completed",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  /**
   * Check login form validation
   */
  useEffect(() => {
    checkLoginForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.login]);

  /**
   * guest form check than button enable/disable
   */
  useEffect(() => {
    let statusDisable = true;
    if (Object.keys(validateForm(guestDetail, "guest")).length == 0) {
      statusDisable = false;
    }
    setGuestButtonDisable(statusDisable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestDetail]);

  /**
   * signup form validation for button
   */
  useEffect(() => {
    let statusDisable = true;
    if (Object.keys(validateForm(formData?.signup, "signup")).length == 0) {
      statusDisable = false;
    }
    setErrorFormData({
      ...errorformData,
      signup: { ...errorformData.signup, is_verify_disable: statusDisable },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.signup]);

  /**
   * mobile Otp timer check
   */
  useEffect(() => {
    let interval;
    const countdown = () => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    };
    interval = setInterval(countdown, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  /**
   * email Otp timer check
   */
  useEffect(() => {
    let interval;
    const countdown = () => {
      if (emailTimer > 0) {
        setEmailTimer((prevTimer) => prevTimer - 1);
      }
    };
    interval = setInterval(countdown, 1000);
    return () => clearInterval(interval);
  }, [emailTimer]);

  return (
    <>
      <div className="relative flex flex-col w-full">
        {/* login and Sign Up User */}
        {orderData?.accountForm != "completed" && (
          <div className="absolute bottom-[0] md:h-[110px] h-[115px] right-[0] rtl:right-[auto] rtl:left-[0] top-0 w-[17%] xs:hidden">
            <div className="absolute bg-light_blue-50 bottom-[0] h-[104px] left-[0] rounded-[50%] w-[104px]"></div>
            <Img
              className="absolute h-[110px] inset-y-[0] my-auto object-cover right-[0] rtl:right-[auto] rtl:left-[0] w-[78%]"
              src="images/img_birthdaycake.png"
              alt="birthdaycake"
            />
          </div>
        )}
        {orderData?.accountForm != "completed" && (
          <div className="flex flex-col gap-1 items-start justify-start">
            <Text className="text-base text-black-900 font-nunitomedium">
              {t("account")}
            </Text>
            <Text className="text-gray-700 text-sm font-nunitoregular w-[80%] xs:w-full">
              {t(
                "toTrackYourOrderStatusWeRecommendYouToLoginOrCreateANewAccount"
              )}
            </Text>
          </div>
        )}
        {/* login and Sign Up User */}
        {/* Sign Up User */}
        {!formType && orderData?.accountForm != "completed" && (
          <>
            <div className="w-full max-w-[430px] xs:flex-col pt-[30px] flex flex-row gap-4 mb-[22px]">
              <Button
                className="flex-1 w-auto hover:border hover:border-black-900 mx-auto px-6 xs:px-3 text-base xs:w-full"
                size="lg"
                variant="OutlineBlack"
                hover={true}
                hoverclass="bg-black-900"
                onClick={(e) => {
                  setFormType("login");
                }}
              >
                {t("haveAnAccountLogIn")}
              </Button>
              <Button
                className="flex-1 mx-auto px-3 xs:px-3 text-base xs:w-full "
                size="lg"
                variant="FillBlack"
                hover={true}
                hoverclass="bg-white-A700"
                onClick={(e) => {
                  setFormType("signup");
                }}
              >
                {t("newToRabbitHoleSignUp")}
              </Button>
            </div>
            <div className="flex flex-row gap-2">
              {guestDetail?.first_name ? (
                <div className="w-auto">
                  <Button
                    className="w-auto min-w-[92px] mx-auto"
                    size="lg"
                    variant="OutlineBlack"
                    hover={true}
                    hoverclass="bg-black-900"
                    onClick={(e) => {
                      dispatch(
                        updateOrderData({
                          ...orderData,
                          pageStep: 2,
                          accountForm: "completed",
                        })
                      );
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              ) : (
                <>
                  <div className="w-auto">
                    <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-sm leading-6">
                      {t("or")}
                    </Text>
                  </div>
                  <div className="w-auto">
                    <Text
                      className="text-pink-800 whitespace-nowrap font-medium font-nunitomedium cursor-pointer text-sm leading-6"
                      onClick={() => {
                        setFormType("guest");
                      }}
                    >
                      {t("continueAsGuest")}
                    </Text>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {/* Sign Up User */}
        {/* login Up User */}

        {formType == "login" && (
          <>
            <div
              className={`w-full max-w-[350px] pt-[30px] ${
                formData?.login?.is_otp_sent == 1 ? "opacity-down" : ""
              }`}
            >
              <MobileInput
                required
                label={t("mobile")}
                className="w-full"
                value={formData?.login?.mobile_no}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    login: {
                      ...formData.login,
                      mobile_no: CUSTOM.strFormat(
                        e.target.value,
                        "max:9|number"
                      ),
                    },
                  });
                  setErrorFormData({
                    ...errorformData,
                    login: { ...errorformData.login, mobile_no: "" },
                  });
                }}
                error={errorformData?.login?.mobile_no}
              />
            </div>

            {formData?.login?.is_otp_sent && (
              <div className="flex flex-row gap-3">
                <div className="w-full max-w-[350px]">
                  <Input
                    required
                    className="w-full mb-0"
                    label={t("verificationCode")}
                    value={formData?.login?.otp}
                    error={errorformData?.login?.otp}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        login: {
                          ...formData.login,
                          otp: CUSTOM.strFormat(e.target.value, "max:4|number"),
                        },
                      });
                      setErrorFormData({
                        ...errorformData,
                        login: { ...errorformData.login, otp: "" },
                      });
                    }}
                  />
                </div>
                <div className="w-auto">
                  <Text
                    className={`text-pink-800 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mt-3.5 cursor-pointer  ${
                      timer != 0 ? "opacity-down" : ""
                    }`}
                    onClick={(e) => {
                      checkUser();
                    }}
                  >
                    {t("resendOTP")}
                  </Text>
                </div>
                {timer != 0 ? (
                  <div className="w-auto">
                    <Text className="text-pink-800 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mt-3.5">
                      {timer}:00
                    </Text>
                  </div>
                ) : null}
              </div>
            )}

            <div className="w-full max-w-[350px] pt-[10px] flex flex-row gap-4 mb-[18px]">
              <Button
                className="flex-1 mx-auto"
                size="lg"
                variant="FillBlack"
                hover={true}
                hoverclass="bg-white-A700"
                disabled={errorformData?.login?.is_verify_disable}
                onClick={() => {
                  if (formData?.login?.is_otp_sent) {
                    login();
                  } else {
                    checkUser();
                  }
                }}
              >
                {!formData?.login?.is_otp_sent ? t("verify") : t("confirm")}
              </Button>
              <Button
                className="w-auto min-w-[92px] mx-auto"
                size="lg"
                variant="OutlineBlack"
                hover={true}
                hoverclass="bg-black-900"
                onClick={(e) => {
                  cancelForm("login");
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </>
        )}

        {formType == "guest" && (
          <>
            <div className="w-full xs:flex-col max-w-[350px] flex flex-row gap-x-5 pt-[30px]">
              <Input
                required
                label={t("firstName")}
                value={guestDetail?.first_name}
                onChange={(e) => {
                  setGuestDetail({
                    ...guestDetail,
                    first_name: CUSTOM.strFormat(e.target.value, "max:50|text"),
                  });
                }}
              />
              <Input
                required
                label={t("lastName")}
                value={guestDetail?.last_name}
                onChange={(e) => {
                  setGuestDetail({
                    ...guestDetail,
                    last_name: CUSTOM.strFormat(e.target.value, "max:50|text"),
                  });
                }}
              />
            </div>
            <div className="w-full max-w-[350px]">
              <MobileInput
                required
                label={t("mobile")}
                className="w-full"
                value={guestDetail?.mobile_no}
                onChange={(e) => {
                  setErrorFormData({});
                  setGuestDetail({
                    ...guestDetail,
                    mobile_no: CUSTOM.strFormat(e.target.value, "max:9|number"),
                  });
                }}
                error={errorformData?.login?.mobile_no}
              />
            </div>
            <div className="w-full max-w-[350px]">
              <Input
                // required
                label={t("email")}
                className="w-full"
                value={guestDetail?.email}
                onChange={(e) => {
                  setGuestDetail({
                    ...guestDetail,
                    email: CUSTOM.strFormat(e.target.value, "max:50|email"),
                  });
                }}
              />
            </div>
            {/* <div className="w-full max-w-[350px] pt-[30px]">
              <MobileInput required label={t("mobile")} className="w-full" />
            </div> */}
            <div className="w-full max-w-[350px] pt-[10px] flex flex-row gap-4 mb-[18px]">
              <Button
                className="flex-1 mx-auto"
                size="lg"
                variant="FillBlack"
                hover={true}
                hoverclass="bg-white-A700"
                disabled={guestButtonDisable}
                onClick={(e) => {
                  saveGuest();
                }}
              >
                {t("confirm")}
              </Button>
              <Button
                className="w-auto min-w-[92px] mx-auto"
                size="lg"
                variant="OutlineBlack"
                hover={true}
                hoverclass="bg-black-900"
                onClick={(e) => {
                  cancelForm("guest");
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </>
        )}

        {formType == "signup" && (
          <>
            <div
              className={`w-full xs:flex-col max-w-[350px] flex flex-row gap-x-5 pt-[30px]`}
            >
              <Input
                required
                className={`${
                  formData?.signup?.is_otp_sent ? "opacity-down" : ""
                }`}
                label={t("firstName")}
                value={formData?.signup?.first_name}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    signup: {
                      ...formData.signup,
                      first_name: CUSTOM.strFormat(
                        e.target.value,
                        "max:50|text"
                      ),
                    },
                  });
                }}
              />
              <Input
                required
                className={`${
                  formData?.signup?.is_otp_sent ? "opacity-down" : ""
                }`}
                label={t("lastName")}
                value={formData?.signup?.last_name}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    signup: {
                      ...formData.signup,
                      last_name: CUSTOM.strFormat(
                        e.target.value,
                        "max:50|text"
                      ),
                    },
                  });
                }}
              />
            </div>
            <div className={`w-full max-w-[350px]`}>
              <MobileInput
                required
                label={t("mobile")}
                className={`w-full ${
                  formData?.signup?.is_otp_sent ? "opacity-down" : ""
                }`}
                value={formData?.signup?.mobile_no}
                onChange={(e) => {
                  setErrorFormData({});
                  setFormData({
                    ...formData,
                    signup: {
                      ...formData.signup,
                      mobile_no: CUSTOM.strFormat(
                        e.target.value,
                        "max:9|number"
                      ),
                    },
                  });
                }}
                error={errorformData?.signup?.mobile_no}
              />
            </div>

            {formData?.signup?.is_otp_sent ? (
              <>
                <div className="flex flex-row gap-3">
                  <div className="w-full max-w-[350px]">
                    <Input
                      required
                      className="w-full mb-0"
                      label={t("verificationCodePhone")}
                      value={formData?.signup?.otp}
                      error={errorformData?.signup?.otp}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          signup: {
                            ...formData.signup,
                            otp: CUSTOM.strFormat(
                              e.target.value,
                              "max:4|number"
                            ),
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="w-auto">
                    <Text
                      className={`text-pink-800 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mt-3.5 cursor-pointer  ${
                        timer != 0 ? "opacity-down" : ""
                      }`}
                      onClick={(e) => {
                        sendOtp("mobile");
                      }}
                    >
                      {t("resendOTP")}
                    </Text>
                  </div>
                  {timer != 0 ? (
                    <div className="w-auto">
                      <Text className="text-pink-800 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mt-3.5">
                        {timer}:00
                      </Text>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}

            <div className="w-full max-w-[350px]">
              <Input
               // required
                label={t("email")}
                className={`w-full ${
                  formData?.signup?.is_otp_sent ? "opacity-down" : ""
                }`}
                value={formData?.signup?.email}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    signup: {
                      ...formData.signup,
                      email: CUSTOM.strFormat(e.target.value, "max:50|email"),
                    },
                  });
                }}
              />
            </div>
            {(formData?.signup?.email && formData?.signup?.is_otp_sent) ? (
              <>
                <div className="flex flex-row gap-3">
                  <div className="w-full max-w-[350px]">
                    <Input
                      required
                      className="w-full mb-0"
                      label={t("verificationCodeEmail")}
                      value={formData?.signup?.emailOtp}
                      error={errorformData?.signup?.emailOtp}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          signup: {
                            ...formData.signup,
                            emailOtp: CUSTOM.strFormat(
                              e.target.value,
                              "max:4|number"
                            ),
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="w-auto">
                    <Text
                      className={`text-pink-800 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mt-3.5 cursor-pointer  ${
                        emailTimer != 0 ? "opacity-down" : ""
                      }`}
                      onClick={(e) => {
                        sendOtp("email");
                      }}
                    >
                      {t("resendOTP")}
                    </Text>
                  </div>
                  {emailTimer != 0 ? (
                    <div className="w-auto">
                      <Text className="text-pink-800 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mt-3.5">
                        {emailTimer}:00
                      </Text>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
            {/* <div className="w-full max-w-[350px] pt-[30px]">
            <MobileInput required label={t("mobile")} className="w-full" />
            </div> */}
            <div className="w-full max-w-[350px] pt-[10px] flex flex-row gap-4 mb-[18px]">
              {formData?.signup?.is_otp_sent ? (
                <Button
                  className="flex-1 mx-auto"
                  size="lg"
                  variant="FillBlack"
                  hover={true}
                  hoverclass="bg-white-A700"
                  disabled={
                    formData?.signup?.otp?.length != 4 ||
                    (formData?.signup?.email && formData?.signup?.emailOtp?.length != 4)
                  }
                  onClick={(e) => {
                    registration();
                  }}
                >
                  {t("confirm")}
                </Button>
              ) : (
                <Button
                  className="flex-1 mx-auto"
                  size="lg"
                  variant="FillBlack"
                  hover={true}
                  hoverclass="bg-white-A700"
                  disabled={errorformData?.signup?.is_verify_disable}
                  onClick={(e) => {
                    sendOtp("email_and_mobile");
                  }}
                >
                  {t("verify")}
                </Button>
              )}

              <Button
                className="w-auto min-w-[92px] mx-auto"
                size="lg"
                variant="OutlineBlack"
                hover={true}
                hoverclass="bg-black-900"
                onClick={(e) => {
                  cancelForm("signup");
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </>
        )}

        {/* login Up User */}
        {/* After login Up User */}
        {orderData?.accountForm == "completed" && (
          <>
            <div className="flex flex-col gap-4 items-center justify-start w-full mb-[18px]">
              <div className="flex flex-row md:gap-5 items-center justify-start w-full">
                <div className="flex flex-col gap-1 items-start justify-start flex-1">
                  <Text className="text-base text-black-900 font-nunitomedium">
                    {t("account")}
                  </Text>
                  <div className="flex gap-1 flex-row items-center">
                    <Text className="text-black-900 text-sm nunitoregular">
                      {orderData?.userDetails?.account_name}
                    </Text>
                    <Img
                      className="h-6 w-6"
                      src="images/img_checkmark_teal_400.svg"
                      alt="checkmark"
                    />
                  </div>
                </div>
                {auth?.id == 0 && (
                  <Button
                    className="w-auto mx-auto px-3 py-1.5 text-sm"
                    size="sm"
                    variant="OutlineBlack"
                    hover={true}
                    hoverclass="bg-black-900"
                    onClick={(e) => {
                      setFormType("");
                      setGuestDetail(orderData?.userDetails);
                      dispatch(
                        updateOrderData({
                          ...orderData,
                          accountForm: "",
                          pageStep: 1,
                        })
                      );
                    }}
                  >
                    {t("change")}
                  </Button>
                )}
              </div>
              <div className="common-pointer bg-light_blue-50 flex flex-col items-center justify-start py-3 px-6 xs:px-3 w-full">
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="flex flex-col gap-1 items-start justify-start">
                    <Text
                      className="text-base text-black-900 font-nunitomedium"
                      size="txtnunitoMedium16"
                    >
                      {t("yourDetails")}
                    </Text>
                    <Text className="text-black-900 text-sm nunitoregular">
                      {orderData?.userDetails?.first_name}{" "}
                      {orderData?.userDetails?.last_name} |{" "}
                      {CUSTOM.mobileWithCountryCode(
                        CUSTOM.replaceKeyValue(
                          orderData?.userDetails?.mobile_no,
                          "-",
                          " "
                        )
                      )}{" "}
                      | {orderData?.userDetails?.email}
                    </Text>
                  </div>
                  <Img
                    className="h-6 w-6 rtl:rotate-180"
                    src="/images/img_arrowright.svg"
                    alt="arrowright"
                    onClick={(e) => {
                      dispatch(changeTrigger("addpopup"));
                      setYourDetailsOpen(true);
                      //dispatch(updateOrderData({ ...orderData, pageStep: 1 }));
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {YourDetailsOpen === true ? (
          <YourDetails
            closepopup={setYourDetailsOpen}
            userDetails={orderData?.userDetails}
            getGuestDetails={getGuestDetails}
            saveGuest={saveGuest}
            guestDetail={guestDetail}
            setGuestDetail={setGuestDetail}
            isAuth={!!auth?.id}
            setFormType={setFormType}
          />
        ) : null}
      </div>
    </>
  );
};

export default Account;
