import React, { useEffect, useRef } from "react";
import globalRequest from "../../utils/global/globalRequest";
import * as APIS from "../../utils/helper/Enum";

const ApplePayComponent = ({  payViaOtherSource, applePayData }) => {
  const applePayButtonRef = useRef(null);

  useEffect(() => {
    const createApplePayButton = () => {
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
        if (!applePayButtonRef.current) {
          const applePayButton = document.createElement("button");
          applePayButton.id = "apple-pay-button";
          applePayButton.style =
            "-webkit-appearance: -apple-pay-button; width: 200px; height: 44px;";
          document
            .getElementById("apple-pay-button-container")
            .appendChild(applePayButton);
          applePayButton.addEventListener("click", startApplePaySession);
          applePayButtonRef.current = applePayButton;
        }
      }
    };

    createApplePayButton();
    // Clean up if necessary
    return () => {
      if (applePayButtonRef.current) {
        applePayButtonRef.current.removeEventListener("click", startApplePaySession);
        applePayButtonRef.current.remove();
        applePayButtonRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applePayData]);

  const startApplePaySession = async () => {
    const paymentRequest = {
      countryCode: "SA",
      currencyCode: "SAR",
      supportedNetworks: ["visa", "masterCard", "amex", "mada"],
      merchantCapabilities: ["supports3DS"],
      total: {
        label: "RabbitHole",
        amount: Number(applePayData?.amount),
      },
    };

    const session = new window.ApplePaySession(1, paymentRequest);

    session.onvalidatemerchant = async (event) => {
      const validationURL = event.validationURL;
      const merchantSession = await globalRequest(
        APIS.CART.VALIDATE_MERCHANT_FOR_APPLE_PAY,
        "POST",
        { appleUrl: validationURL },
        {},
        true
      )
        .then((response) => {
          session.completeMerchantValidation(response.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    session.onpaymentauthorized = (event) => {
      const payment = event.payment;
      payViaOtherSource("apple_pay", payment, session);
    };
    session.begin();
  };

  return <div id="apple-pay-button-container"></div>;
};

export default ApplePayComponent;
