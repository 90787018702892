import { Button, Img, Line, Text } from "components";
import { useTranslation } from "react-i18next";
import AddMoney from "./AddMoney";
import React, { useEffect, useState } from "react";
import { addDeleteGetLocalStorage, storageKeys } from "utils/global/localData";
import { MoneyAdded } from "popups/MoneyAdded";
import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import * as CUSTOM from "../../../../utils/helper/custom";
import * as APIS from "../../../../utils/helper/Enum";
import { getAuth } from "../../../../redux/reducers/loginData";
import EmptyAll from "pages/EmptyList";

const Wallet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [history, setHistory] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [apiStatus, setApiStatus] = useState(false);
  const [MoneyAddedOpen, setMoneyAddedOpen] = useState(false);
  const [addedAmount, setAddedAmount] = useState(0);
  const [isAddMoney, setIsAddMoney] = useState(false);
  const [paymentData, setPaymentData] = useState({ step: 1 });
  const auth = useSelector(getAuth);


  /**
   *  Get wallet history
   */
  const getWalletHistory = async () => {
    setApiStatus(false);
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        APIS.USERS.WALLET_HISTORY,
        "get",
        {},
        { params: { page: 1, limit: 50000 } },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setHistory(
          CUSTOM.organizeDataByMonthAndYear(response?.data?.data, "createdAt")
        );
        setWalletBalance(response?.data?.wallet_balance);
      }
      setApiStatus(true);
    } catch (e) {}
    dispatch(changeLoader(false));
  };

  const renderTransactionDetails = (items, auth, t) => {
    return items?.additionalNote;
  };

  useEffect(() => {
    if (!isAddMoney) {
      setPaymentData({ step: 1 });
    }
  }, [isAddMoney]);

  useEffect(() => {
    const responsePayment = addDeleteGetLocalStorage(
      storageKeys.WALLET_RESPONSE,
      {},
      "get"
    );
    if (responsePayment) {
      let dataJson = JSON.parse(responsePayment);
      if (dataJson?.payment_status == "success") {
        setMoneyAddedOpen(true);
        setAddedAmount(dataJson?.amount);
      } else {
        setMoneyAddedOpen(false);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: dataJson?.message,
            snackbarState: "error",
          })
        );
        setPaymentData({ ...paymentData, step: 2, amount: dataJson?.amount });
        setIsAddMoney(true);
      }
      addDeleteGetLocalStorage(storageKeys.WALLET_RESPONSE, {}, "delete");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWalletHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moneyProps = {
    setIsAddMoney: setIsAddMoney,
    paymentData: paymentData,
    setPaymentData: setPaymentData,
    getWalletHistory: getWalletHistory
  };

  return (
    <>
      {isAddMoney ? (
        <AddMoney {...moneyProps} />
      ) : (
        <div className="flex flex-col w-full ">
          <Text
            className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
            size="txtnunitoSemiBold24"
          >
            {t("wallet")}
          </Text>
          <div className="bg-gray-50_02 flex flex-col font-nunitomedium items-center justify-start mt-[30px] p-5 rounded-lg w-full">
            <div className="flex xs:flex-col xs:gap-4 flex-row items-center justify-between w-full">
              <div className="flex flex-row gap-[20px] items-start justify-start">
                <Img
                  className="h-10 md:mt-0 mt-[7px] w-10"
                  src="/images/img_volume_black_900_28x28.svg"
                  alt="volume_Two"
                />
                <div className="flex flex-col items-start justify-start">
                  <Text
                    className="text-2xl md:text-[22px] mb-1 text-black-900 sm:text-xl"
                    size="txtnunitoSemiBold24"
                  >
                    {t("sar")} {walletBalance}
                  </Text>
                  <Text
                    className="text-black-900 text-xs"
                    size="txtnunitoRegular12"
                  >
                    {t("walletBalance")}
                  </Text>
                </div>
              </div>
              <Button
                className="w-auto px-4 gap-2 flex items-center justify-center text-black-900"
                variant={"OutlineBlack"}
                size={"sm"}
                hover={true}
                hoverclass="bg-black-900"
                onClick={(e) => {
                  setIsAddMoney(true);
                }}
                leftIcon={
                  <Img
                    className="h-[18px] btn-icon"
                    src="/images/img_plus_black_900_20x20.svg"
                    alt="plus"
                  />
                }
              >
                <div className=" text-center text-sm">{t("moneyToWallet")}</div>
              </Button>
            </div>
          </div>
          {history.length > 0 ? (
            <Text
              className="mt-[34px] text-base text-black-900"
              size="txtnunitoMedium16"
            >
              {t("transactionHistory")}
            </Text>
          ) : null}
          <div className="relative w-full mb-8">
            {history.map((monthData, indexindex) => {
              const monthName = Object.keys(monthData)[0];
              const transactions = monthData[monthName];
              return (
                <>
                  <div className="bg-light_blue-50 mt-4 py-3 px-5 rounded w-full mb-4">
                    <Text
                      className="text-base text-black-900"
                      size="txtnunitoMedium16"
                    >
                      {monthName}
                    </Text>
                  </div>
                  {transactions.map((items, indexitems) => {
                    return (
                      <>
                        <div
                          className="flex flex-row items-start justify-between px-5"
                          key={`wallet-items${items?.id}`}
                        >
                          <div className="flex gap-[16px]">
                            <Img
                              className="h-10 w-10 mt-1"
                              src={CUSTOM.walletPaymentStatusLogo(
                                items?.transactionDetails?.type
                              )}
                              alt="moneyadded_Four"
                            />
                            <div className="flex flex-col relative w-full gap-1">
                              <Text
                                className="text-base text-black-900"
                                size="txtnunitoMedium16"
                              >
                                {renderTransactionDetails(items, auth, t)}{" "}
                              </Text>
                              <Text
                                className="text-gray-700 text-xs"
                                size="txtnunitoRegular12Gray700"
                              >
                                {CUSTOM.convertDateString(
                                  items?.createdAt,
                                  "dd/MM/yyyy - hh:mm a"
                                )}{" "}
                                {/* {auth?.id == items?.addedBy ? (
                                  <>
                                    |{t("txnID")}: {items?.transactionId}
                                  </>
                                ) : null} */}
                              </Text>
                            </div>
                          </div>
                          <div className="flex flex-col relative gap-1">
                            <Text
                              className="text-base text-black-900 text-right"
                              size="txtnunitoMedium16"
                            >
                              {items?.orderType == "order" ? "-" : ""}{" "}
                              {t("sar")}{" "}
                              {CUSTOM.setPrice(
                                items?.transactionDetails?.walletAmount
                              )}
                            </Text>
                            <Text
                              className="text-gray-700 text-right text-xs"
                              size="txtnunitoRegular12Gray700"
                            >
                              {t("closingBal")}: {t("sar")}{" "}
                              {CUSTOM.setPrice(items?.walletClosingBalance)}
                            </Text>
                          </div>
                        </div>
                        {transactions.length - 1 != indexitems ? (
                          <Line className="bg-gray-300 h-px my-[13px] w-full" />
                        ) : null}
                      </>
                    );
                  })}
                </>
              );
            })}
            {history.length == 0 && apiStatus ? (
              <EmptyAll type="walletHistory"></EmptyAll>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {MoneyAddedOpen ? (
        <MoneyAdded closepopup={setMoneyAddedOpen} addedAmount={addedAmount} />
      ) : null}
      {/* after click // money to wallet button // show below content  */}
    </>
  );
};

export default Wallet;
