import { createSlice } from '@reduxjs/toolkit';
const initialState = {
};
const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    updateMyAddress: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});
export const { updateMyAddress } = dataSlice.actions;
export const getAddressData = (state) => state.updateMyAddress;
export default dataSlice.reducer;