import { useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { Button, Img, Text } from "components";
import { TopHeader } from "components/Header/TopHeader";
import { NavMenu } from "components/Header/NavMenu";
import {
  addDeleteGetLocalStorage,
  storageKeys,
} from "../../utils/global/localData";
/**custom modules*/
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import * as CUSTOM from "../../utils/helper/custom";
import * as API from "../../utils/helper/Enum";
import { getAuth } from "../../redux/reducers/loginData";
import { useTranslation } from "react-i18next";
import { changeTrigger, currentTrigger } from "redux/reducers/trigger";
const Header = (props) => {
  let cpage = useLocation();
  const dispatch = useDispatch();
  const triggerValue = useSelector(currentTrigger);
  const guestId = CUSTOM.getDeviceID();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector(getAuth);
  const [hamburger, setHamburger] = useState(false);
  const [cartCount, setCartCount] = useState(0);

  /**
   * Dropdown menu
   */
  const handlehamburger = () => {
    setHamburger(!hamburger);
    if (!hamburger) {
      document.body.classList.add("overflow-hidden");
      document.getElementById("header-box").classList.add("relative");
    } else {
      document.body.classList.remove("overflow-hidden");
      document.getElementById("header-box").classList.remove("relative");
      document.getElementById("header-box").classList.remove("relative");
    }
  };

  /**
   * get cart count
   */
  const getCartCount = async () => {
    try {
      const authData = CUSTOM.loginData();
      const params = { user_id: authData?.id, guest_id: guestId };
      const { data } =
        (await globalRequest(
          API?.CART?.GET_CART_COUNT,
          "get",
          {},
          { params },
          true
        )) || {};
      if (data?.status == "SUCCESS") {
        setCartCount(data?.data?.cartCount);
      }
    } catch (error) {
      console.error("Error in getCartCount:", error);
    }
  };

  // Example usage:
  // await getCartCount();

  /**
   * get cart data
   */
  const getCartData = async () => {
    try {
      dispatch(changeLoader(true));
      const authData = CUSTOM.loginData();
      const params = { user_id: authData?.id, guest_id: guestId };
      const { data } =
        (await globalRequest(
          API?.CART?.GET_CART_DATA,
          "get",
          {},
          { params },
          true
        )) || {};
      if (data?.status === "SUCCESS") {
        addDeleteGetLocalStorage(
          storageKeys?.CART_DATA,
          data?.data?.cartItems,
          "add",
          "single"
        ); 
      } else {
        addDeleteGetLocalStorage(storageKeys?.CART_DATA, {}, "delete");
      }
      dispatch(changeTrigger("loadCartData"));
    } catch (error) {
      addDeleteGetLocalStorage(storageKeys?.CART_DATA, {}, "delete");
      dispatch(changeTrigger("loadCartData"));
    } finally {
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    let loginDatas = ["fetchCartCount", "loggedin"];
    if (loginDatas.includes(triggerValue)) {
      getCartData();
      getCartCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  useEffect(()=>{
    getCartData();
    getCartCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    let loginDatas = ["updateinlocal","fetchCartCount","loadSummay","loadCartData","loadAddressList","loadGiftOccasionList","loadAddressList"];
    if (loginDatas.includes(triggerValue)) {
        setTimeout(()=>{
              dispatch(changeTrigger(""));
        },[500])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  useEffect(() => {
    setTimeout(()=>{
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },[500])
  }, [cpage]);

  /**
   * class set for menu
   */
  const hamburgerStyles = !hamburger ? "" : "is-active";
  const navStyles = !hamburger
    ? "sm:hidden"
    : "sm:flex absolute flex-col items-start bg-white z-[99999] right-0 top-full sm:w-screen bg-white-A700 overflow-auto header-height-xs p-4";

  return (
    <>
      <TopHeader cpage={cpage?.pathname} />
      <header
        id="header-box"
        className={`sticky top-0 z-30 bg-white-A700 flex flex-row font-nunitomedium  items-center justify-center shadow-bs w-full`}
      >
        <div className="relative flex flex-row justify-between w-full md:gap-5 max-w-[1110px] mx-auto md:px-4">
          <div
            className="cursor-pointer flex flex-row items-center py-1.5"
            onClick={() => navigate("/")}
          >
            <Img
              className="h-[62px] w-[114px] w-[auto]"
              src="/images/logo.svg"
              alt="rabbitholelogo"
            />
          </div>
          {cpage?.pathname != "/cart" ? (
            <>
              <NavMenu
                handlehamburger={handlehamburger}
                navStyles={`${navStyles}`}
              />
              <div className="flex flex-row items-center gap-7 md:gap-4 sm:gap-4">
                <Button
                  className="cursor-pointer font-nunitomedium py-1 px-2 sm:hidden border border-pink-800 rounded text-center text-pink-800 text-sm leading-6"
                  hover={true}
                  hoverclass="bg-pink-800"
                  onClick={() => {
                    if (auth?.id == 0) {
                      navigate("/login");
                    } else {
                      navigate("/design-my-cake");
                    }
                  }}
                >
                  {t("designMyCustomCake")}
                </Button>
                <div className="flex flex-col h-6 items-center justify-start w-6">
                  <Img
                    className="h-6 w-6 cursor-pointer"
                    src="/images/img_search.svg"
                    alt="search"
                    onClick={() => navigate("/search")}
                  />
                </div>
                <div className="h-[30px] relative w-[30px] cursor-pointer">
                  <Img
                    className="absolute bottom-[0] h-6 left-[0] w-6 cursor-pointer"
                    src="/images/img_mdicart.svg"
                    alt="mdicart"
                    onClick={() => navigate("/cart")}
                  />
                  {cartCount > 0 ? (
                    <Text
                      className="absolute bg-pink-800 flex h-4 items-center justify-center right-[0] rtl:right-[auto] rtl:left-[0] rounded-[50%] text-[11px] text-center text-white-A700 top-[0] tracking-[0.50px] w-4"
                      size="txtRobotoMedium11"
                    >
                      {cartCount}
                    </Text>
                  ) : null}
                </div>
                <button
                  className={`${hamburgerStyles} hamburger hamburger--slider hidden sm:flex`}
                  type="button"
                  onClick={handlehamburger}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
              </div>
            </>
          ) : null}
        </div>
      </header>
      <Outlet />
    </>
  );
};

Header.defaultProps = {};

export default Header;
