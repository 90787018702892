/**
 * @file This file contains all the English language translations
 * English Language
 * @type {Object}
 * @returns {Object} language - The language object
 */

import common from './ar/common.json';
import err from './ar/err.json';

const ar = () => {
  const language = {
    ...common,
    ...err,
  };
  return language;
}

export default ar