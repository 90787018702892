import React, { useEffect, useState } from "react";
import Footer from "components/Footer";
import CartProductDetail from "./CartProductDetail";
import CartProductStep from "./CartProductStep";
import CartEmpty from "./CartEmpty";
import { updateOrderData, getOrderData } from "../../redux/reducers/orderData";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../redux/reducers/snackbar";
import * as CUSTOM from "../../utils/helper/custom";
import * as APIS from "../../utils/helper/Enum";
import { getAuth } from "../../redux/reducers/loginData";
import { changeTrigger, currentTrigger } from "redux/reducers/trigger";
import {
  updateOrderSummary,
  getOrderSummary,
} from "redux/reducers/orderSummary";
import { changeLoader } from "redux/reducers/loader";
import globalRequest from "utils/global/globalRequest";
import { useNavigate } from "react-router-dom";
import { addDeleteGetLocalStorage, storageKeys } from "utils/global/localData";
import {
  getAddressData,
} from "../../redux/reducers/myAddress";

const Cart = () => {
  //global for all steps
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const orderSummary = useSelector(getOrderSummary);
  const triggerValue = useSelector(currentTrigger);
  const [pageLoaded, setPageLoaded] = useState(false);
  const getAddress = useSelector(getAddressData);
  const [prepTime, setPrepTime] = useState(0);
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const orderData = useSelector(getOrderData);
  const [cartItemError, setCartItemError] = useState(false);
  const [addressListForUser, setAddressListForUser] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [occasionOptions, setOccasionOptions] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [isGetGuestApiRun, setIsGetGuestApiRun] = useState(false);

  /**
   * get order summary data
   */
  const getOrderSummaryData = async () => {
    dispatch(changeLoader(true));
    try {
      const guestId = CUSTOM.getDeviceID();
      const inputData = {
        user_id: auth?.id,
        guest_id: guestId,
        coupon_code: orderData?.couponCode,
        delivery_type: orderData?.delivery_type,
      };
      if (
        orderData?.delivery_mode !== "buying_for_love" &&
        orderData?.delivery_mode
      ) {
        if (orderData?.delivery_mode == "home_office") {
          inputData.userAddresslat =
            orderData?.home_office?.itemDetail?.lattitude;
          inputData.userAddresslong =
            orderData?.home_office?.itemDetail?.longitude;
          inputData.drop_zone_id = 0;
        } else {
          inputData.userAddresslat = orderData?.home_office?.latitude;
          inputData.userAddresslong = orderData?.home_office?.longitude;
          inputData.drop_zone_id = orderData?.home_office?.id;
          inputData.branch_id = orderData?.timeSloat?.branchId || 0;
        }
      }

      const response = await globalRequest(
        APIS?.ORDER?.GET_SUMMARY,
        "get",
        {},
        { params: inputData },
        true
      );

      const dataSummary =
        response?.data?.status == "SUCCESS" ? response?.data?.data : {};
      dispatch(updateOrderSummary(dataSummary));
    } catch (e) {
      dispatch(updateOrderSummary({}));
    }
    dispatch(changeLoader(false));
  };
  
  /**
   * Get guest Saved address
   */
  const getSavedGuestAddress = async () => {
    try {
      const guestId = CUSTOM.getDeviceID();
      dispatch(changeLoader(true));
      let response = await globalRequest(
        APIS?.GUEST?.GET_ADDRESS_LIST,
        "get",
        {},
        { params: { guest_id: guestId } },
        true
      );
      response = response?.data;
      setIsGetGuestApiRun(true);
      if (response?.status == "SUCCESS") {
        let gaddress = [];
        gaddress.push(response?.data);
        setAddressList(gaddress);
      }
    } catch (e) {}
    dispatch(changeLoader(false));
  };

  /**
   *  Get all saved address
   */
  const getSavedAddress = async () => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        APIS?.USERS?.GET_ALL_ADDRESS,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setAddressList(response?.data?.userAddresses);
        setAddressListForUser(response?.data);
      } else {
        setAddressListForUser([]);
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /**
   *  Get Occassions
   */
  const getOccasionOptions = async () => {
    if (auth?.id) {
      try {
        dispatch(changeLoader(true));
        let response = await globalRequest(
          APIS?.OCCASION?.LISTING,
          "get",
          {},
          {},
          true
        );
        response = response?.data;
        if (response?.status == "SUCCESS") {
          let relationArray = [];
          response?.data?.data.map((item) => {
            relationArray.push({
              value: item?.id,
              label: item?.name,
            });
          });
          setOccasionOptions(relationArray);
        }
      } catch (e) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: e?.message,
            snackbarState: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    }
  };

  /**
   *  Get pickup Points
   */
  const getPickupPoint = async () => {
    let inputSearch = {
      search: "",
      page: 1,
      limit: 500,
      lat: defaultAddress?.lattitude,
      long: defaultAddress?.longitude,
    };
    let listOfZone = [];
    try {
      let response = await globalRequest(
        APIS?.DROP_ZONE?.GET_NEAR_ALL,
        "get",
        {},
        { params: inputSearch },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        listOfZone = response?.data?.data;
      }
    } catch (e) {}
    setZoneList(listOfZone);
  };

  /**
   *  Get prep time data
   */
  const getPreTime = async () => {
    let time = 0;
    try {
      dispatch(changeLoader(true));
      let loginUser = CUSTOM.loginData();
      const guest_id = CUSTOM.getDeviceID();
      const user_id = loginUser?.id;
      let response = await globalRequest(
        APIS?.CHECKOUT?.GET_PREP_TIME,
        "get",
        {},
        { params: { guest_id, user_id } }
      );
      response = response?.data;
      if (response.status == "SUCCESS") {
        time = response?.data?.estimated_prep_time;
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    setPrepTime(time);
    dispatch(changeLoader(false));
  };

  /**
   *  Save guest user address
   */
  const saveGuestAddress = async (data) => {
    let addguest = {
      ...getAddress,
      ...data,
      full_name: `${orderData?.userDetails?.first_name} ${orderData?.userDetails?.last_name}`,
      mobile_no: orderData?.userDetails?.mobile_no,
    };
    addguest.guest_id = CUSTOM.getDeviceID();
    try {
      let response = await globalRequest(
        APIS?.GUEST?.ADD_ADDRESS,
        "post",
        addguest,
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        getSavedGuestAddress();
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
  };

   /**
   * this step for payment failed only
   */
   const addValueLocalStorageToRedux = () => {
    if (localStorage.getItem(storageKeys.REGULAR_ORDER_DATA)) {
      let reg_orderData = addDeleteGetLocalStorage(
        storageKeys.REGULAR_ORDER_DATA,
        {},
        "get"
      );
      let reg_zoneList = addDeleteGetLocalStorage(
        storageKeys.REG_ZONE_LIST,
        {},
        "get"
      );
      let reg_addressList = addDeleteGetLocalStorage(
        storageKeys.REG_ADDRESS_LIST,
        {},
        "get"
      );
      let reg_occasionOptions = addDeleteGetLocalStorage(
        storageKeys.REG_OCCASION_LIST,
        {},
        "get"
      );
      try {
        if (reg_zoneList) {
          setZoneList(JSON.parse(reg_zoneList));
        }
        if (reg_addressList) {
          setAddressList(JSON.parse(reg_addressList));
        }
        if (reg_occasionOptions) {
          setOccasionOptions(JSON.parse(reg_occasionOptions));
        }
        if (reg_orderData) {
          dispatch(updateOrderData(JSON.parse(reg_orderData)));
        }
      } catch (e) {}
      removePaymentLocalInfo();
    }
  };

  /**
   * this step for payment failed only
   */
  const removePaymentLocalInfo = () => {
    localStorage.removeItem(storageKeys.REGULAR_ORDER_DATA);
    localStorage.removeItem(storageKeys.REG_ZONE_LIST);
    localStorage.removeItem(storageKeys.REG_ADDRESS_LIST);
    localStorage.removeItem(storageKeys.REG_OCCASION_LIST);
  };

  const checkDifferenceInAddress = (localAddress, dbAddress) => {
    let keyToCheck = {
      address: "address",
      // "lattitude": "lattitude",
      // "longitude": "longitude",
      street_number: "streetNumber",
      floor_apartment: "floorApartment",
      building_number: "buildingNumber",
      zone_number: "zoneNumber",
    };
    if (!localAddress) {
      return false;
    }
    if (!dbAddress) {
      return true;
    }
    if (localAddress) {
      localAddress = JSON.parse(localAddress);
    }
    let keys = Object.keys(keyToCheck);
    let isDifferent = false;
    keys.map((item) => {
      if (localAddress?.[item] != dbAddress?.[keyToCheck?.[item]]) {
        isDifferent = true;
      }
    });
    return isDifferent;
  };

  /**
   * recall address api when new address added
   */
  useEffect(() => {
    if (triggerValue == "loadAddressList") {
      if (auth?.id) {
        getSavedAddress();
      } else {
        getSavedGuestAddress();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  /**
   * on page load get address details
   */
  useEffect(() => {
    if (auth?.id) {
      getSavedAddress();
    } else {
      getSavedGuestAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  /**
   * get pickup point list according to address
   */
  useEffect(() => {
    getPickupPoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAddress]);

  /**
   * set default address
   */
  useEffect(() => {
    if (addressList.length != 0 && auth?.id) {
      let address = addressListForUser?.userAddresses?.find(
        (item) => item?.id === addressListForUser?.defaultAddressId
      );
      if (address) {
        setDefaultAddress(address);
      } else {
        setDefaultAddress(CUSTOM.getDefautAddress());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList, auth]);

  /**
   * load card data
   */
  useEffect(() => {
    if (cartData.length == 0 || triggerValue == "loadCartData") {
      setCartData(CUSTOM.getCartData());
    }
    setPageLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  /**
   * load prep time
   */
  useEffect(() => {
    getPreTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);

  useEffect(() => {
    let arrayData = ["loadPrepTime"];
    if (arrayData.includes(triggerValue)) {
      getPreTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  /**
   * get order summary
   */
  useEffect(() => {
    let arrayData = ["loadCartData", "loadSummay"];
    if (arrayData.includes(triggerValue)) {
      getOrderSummaryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  /**
   * get order summary
   */
  useEffect(() => {
    getOrderSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderData?.delivery_mode,
    orderData?.home_office,
    orderData?.delivery_type,
    orderData?.timeSloat?.branchId
  ]);

  /**
   * load gift occasion list
   */
  useEffect(() => {
    if (triggerValue == "loadGiftOccasionList") {
      getOccasionOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  /**
   * load occassion list
   */
  useEffect(() => {
    getOccasionOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  /**
   * when page load call cart api
   */
  useEffect(() => {
    dispatch(changeTrigger("fetchCartCount"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (triggerValue == "updateinlocal" && Object.keys(orderData).length) {
      addDeleteGetLocalStorage(
        storageKeys.REGULAR_ORDER_DATA,
        orderData,
        "add",
        "single"
      );
      addDeleteGetLocalStorage(
        storageKeys.REG_ZONE_LIST,
        zoneList,
        "add",
        "single"
      );
      addDeleteGetLocalStorage(
        storageKeys.REG_OCCASION_LIST,
        occasionOptions,
        "add",
        "single"
      );
      addDeleteGetLocalStorage(
        storageKeys.REG_ADDRESS_LIST,
        addressList,
        "add",
        "single"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  /**
   * payment check whan failed or success order
   */
  useEffect(() => {
    setTimeout(() => {
      const url = window.location.href;
      const params = new URL(url);
      const payment_status = params.searchParams.get("payment_status");
      if (payment_status !== null) {
        const order_id = params.searchParams.get("order_id");
        const message = params.searchParams.get("message");
        if (payment_status == "success") {
          navigate(`/cart-success/${order_id}`);
          removePaymentLocalInfo();
        } else {
          addValueLocalStorageToRedux();
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: message,
              snackbarState: "error",
            })
          );
        }
        let urlEx = url.split("?");
        window.history.pushState({ html: "", pageTitle: "cart" }, "", urlEx[0]);
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   /**
   * payment check whan failed or success order
   */
   useEffect(() => {
    setTimeout(() => {
      const url = window.location.href;
      const params = new URL(url);
      const lang_change = params.searchParams.get("lang");
      if (lang_change !== null) {
          addValueLocalStorageToRedux();
        }
        let urlEx = url.split("?");
        window.history.pushState({ html: "", pageTitle: "cart" }, "", urlEx[0]);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Checking wether db address and local address is same or not
   */
  useEffect(() => {
    let addressData = addDeleteGetLocalStorage(
      storageKeys.GUEST_ADDRESS,
      {},
      "get"
    );
    if (!auth?.id && addressData && orderData?.accountForm == "completed") {
      if (
        isGetGuestApiRun && (addressList.length == 0 ||
        checkDifferenceInAddress(addressData, addressList[0]))
      ) {
        saveGuestAddress(addressData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressList, orderData]);

  useEffect(() => {
    let ExistDelete = CUSTOM.findInactiveOrDelete(cartData);
    setCartItemError(ExistDelete);
  }, [cartData]);

  return (
    <>
      <div className="bg-gray-50_02 flex flex-col font-nunitoregular items-center justify-start mx-auto w-full h-full md:h-full">
        {/* <Header /> */}
        {/* for empty cart */}
        {pageLoaded && cartData.length == 0 ? <CartEmpty /> : null}
        {/* for empty cart */}
        {pageLoaded && cartData.length ? (
          <div className="pt-7 pb-36 flex flex-row gap-7 sm:flex-col max-w-[1110px] w-full mx-auto md:px-4 items-start">
            <CartProductStep
              orderData={orderData}
              cartData={cartData}
              orderSummary={orderSummary}
              addressList={addressList}
              zoneList={zoneList}
              occasionOptions={occasionOptions}
              prepTime={prepTime}
              cartItemError={cartItemError}
            />
            <CartProductDetail
              cartData={cartData}
              orderSummary={orderSummary}
            />
          </div>
        ) : null}
        <Footer className="absolute bottom-[0] flex font-nunitoregular inset-x-[0] items-center justify-center mx-auto w-full" />
      </div>
    </>
  );
};

export default Cart;
