import React, { useEffect, useRef, useState } from "react";
import Footer from "components/Footer";
import HomeBlog from "components/HomeBlog";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import globalRequest from "../../utils/global/globalRequest";
import { changeLoader } from "../../redux/reducers/loader";
import * as API from "../../utils/helper/Enum";
import { useDispatch, useSelector } from "react-redux";
import * as CUSTOM from "../../utils/helper/custom";
import { getAuth } from "../../redux/reducers/loginData";
import {
  Button,
  Img,
  Line,
  List,
  PagerIndicator,
  Slider,
  Text,
} from "components";

const HomePage = () => {
  const { t } = useTranslation();
  const sliderRef = React.useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const navigate = useNavigate();
  const [blogList, setBlogList] = useState([]);
  const [blogItemCount, setblogItemCount] = useState(0);
  const [sliderState, setsliderState] = React.useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const observer = useRef(null);
  const [animatedSections, setAnimatedSections] = useState([]);

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setMousePosition({ x: clientX / 80, y: clientY / 80 });
  };

  /**
   * Get blogs
   */
  const getBlogData = async () => {
    dispatch(changeLoader(true));
    setblogItemCount(0);
    try {
      let response = await globalRequest(
        `${API?.BLOGS?.GET_BLOCK_BY_CATEGORY_ID}`,
        "get",
        {},
        {
          params: {
            page: 1,
            limit: 10,
            user_id: auth?.id,
          },
        },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setBlogList(response?.data?.data);
        setblogItemCount(response?.data?.paginator?.itemCount);
      } else {
        setBlogList([]);
      }
    } catch (e) {
      setBlogList([]);
    }
    dispatch(changeLoader(false));
  };

  /**
   * like unline blog post
   */
  const likeBlog = async (id) => {
    if (auth?.id > 0) {
      try {
        let response = await globalRequest(
          `${API?.BLOGS?.LIKE_DISLIKE}/${id}`,
          "patch",
          {},
          {},
          true
        );
        response = response?.data;
        if (response?.status == "SUCCESS") {
          setBlogList((prevBlogList) => {
            return prevBlogList.map((blog) => {
              if (blog.id === id) {
                return {
                  ...blog,
                  isLike: !blog?.isLike ? true : false,
                  likes: !blog?.isLike
                    ? Number(blog?.likes) + 1
                    : blog?.likes - 1,
                };
              }
              return blog;
            });
          });
        }
      } catch (e) { }
    }
  };

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      const animatedElements = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => entry.target);

      setAnimatedSections((prevSections) => [
        ...prevSections,
        ...animatedElements,
      ]);
    });

    const sections = document.querySelectorAll(".my-element");
    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);


  useEffect(() => {
    animatedSections.forEach((animatedSection) => {
      animatedSection.classList.add("animation");
    });
  }, [animatedSections]);

  useEffect(() => {
    getBlogData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white-A700 flex flex-col items-center justify-start mx-auto w-full">
        <div className="flex flex-col items-center justify-start w-full">
          <section
            id="section-1"
            onMouseMove={handleMouseMove}
            className="bg-no-repeat flex md:flex-col flex-row gap-[46px] items-center justify-center w-full h-[547px] xs:h-auto xs:py-16 relative image-section"
          >
            <div className="w-full max-w-[1100px] mx-auto md:px-4 xs:w-full flex items-center justify-between relative sm:h-full">
              <div className="flex flex-col items-start justify-center w-1/2 relative z-20 sm:w-full sm:items-center">
                <div className="flex items-center justify-start my-element fade-animation sm:justify-center">
                  <div className="relative">
                    <Img
                      className="absolute h-[52px] left-[-4%] top-[-12%] w-[51px]"
                      src="/images/img_reply.svg"
                      alt="reply"
                    />
                    <Text
                      className="md:text-5xl sm:text-[42px] text-[56px] text-black-900 text-center"
                      size="txtnunitoBold56"
                    >
                      <span className="text-pink-800">{t("cakes")}</span>{" "}
                      {t("withLove")}
                    </Text>
                    <Img
                      className="md:ml-[0] ml-[-10px]"
                      src="/images/img_waves.svg"
                      alt="waves"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center mt-[32px] my-element fade-animation sm:items-center">
                  <div className="flex items-center xs:flex-col justify-start xs:w-full gap-8">
                    <div className="flex flex-col gap-3.5 items-center justify-start xs:w-full">
                      <div className="w-[103px] h-[103px] rounded-full bg-yellow-100 p-3">
                        <div className="w-full h-full rounded-full bg-orange-50_01 flex items-center justify-center drop-shadow-[0px_0px_15.45px_0px_#0000000C]">
                          <Img
                            src="/images/send-gift.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <Button
                        className="bg-yellow-100 cursor-pointer font-nunitomedium h-7 py-[3px] px-3 rounded text-xs !leading-5 text-center text-black-900 shadow-sm group"
                        hover={true}
                        hoverclass="bg-yellow-100"
                        onClick={() => navigate("/all-products")}
                      >
                        <div className="flex items-center gap-1">
                          <span className="group-hover:invert group-hover:!font-light font-normal">
                            {t("sendGift")}
                          </span>
                          <Img
                            className="mt-1"
                            src="/images/home-btn-icon.svg"
                            alt="icon"
                          />
                        </div>
                      </Button>
                    </div>
                    <div className="flex flex-col gap-3.5 items-center justify-start xs:w-full">
                      <div className="w-[103px] h-[103px] rounded-full bg-blue-100 p-3">
                        <div className="w-full h-full rounded-full bg-light_blue-50 flex items-center justify-center drop-shadow-[0px_0px_15.45px_0px_#0000000C]">
                          <Img
                            src="/images/customize-you-cake.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <Button
                        className="bg-blue-100 cursor-pointer font-nunitomedium h-7 py-[3px] px-3 rounded text-xs !leading-5 text-center text-black-900 shadow-sm group"
                        hover={true}
                        hoverclass="bg-blue-100"
                        onClick={() => {
                          if (auth?.id == 0) {
                            navigate("/login");
                          } else {
                            navigate("/design-my-cake");
                          }
                        }}
                      >
                        <div className="flex items-center gap-1">
                          <span className="group-hover:invert group-hover:!font-light font-normal">
                            {t("customizeYouCake")}
                          </span>
                          <Img
                            className="mt-1"
                            src="/images/home-btn-icon.svg"
                            alt="icon"
                          />
                        </div>
                      </Button>
                    </div>
                    <div className="flex flex-col gap-3.5 items-center justify-start xs:w-full">
                      <div className="w-[103px] h-[103px] rounded-full bg-red-100_01 p-3">
                        <div className="w-full h-full rounded-full bg-orange-50_02 flex items-center justify-center drop-shadow-[0px_0px_15.45px_0px_#0000000C]">
                          <Img
                            src="/images/add-your-occasion.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <Button
                        className="bg-red-100_01 cursor-pointer font-nunitomedium h-7 py-[3px] px-3 rounded text-xs !leading-5 text-center text-black-900 shadow-sm group"
                        hover={true}
                        hoverclass="bg-red-100_01"
                        onClick={() => {
                          navigate("/my-order", { state: { tab: 1 } });
                        }}
                      >
                        <div className="flex items-center gap-1">
                          <span className="group-hover:invert group-hover:!font-light font-normal">
                            {t("addYourOccasion")}
                          </span>
                          <Img
                            className="mt-1"
                            src="/images/home-btn-icon.svg"
                            alt="icon"
                          />
                        </div>
                      </Button>
                    </div>
                    {/* <div className="flex flex-col gap-3.5 items-center justify-start xs:w-full">
                      
                      <Button
                        className="bg-transparent hover:border hover:border-black-900 cursor-pointer font-nunitomedium h-12 py-2.5 rounded text-base text-center text-black-900 w-[180px]"
                        hover={true}
                        hoverclass="bg-black-900"
                        onClick={(e) => {
                          navigate("/all-products");
                        }}
                      >
                        {t("yourPerfectCake")}
                      </Button>
                    </div>
                    <div className="flex xs:flex-col xs:w-full">
                      <Line className="bg-black-900 h-[94px] w-px xs:h-px xs:w-full" />
                      <div className="flex flex-col gap-3.5 items-center justify-center px-[30px] py-[20px]">
                        
                        <Button
                          className="bg-transparent hover:border hover:border-black-900 cursor-pointer font-nunitomedium h-12 py-2.5 rounded text-base text-center text-black-900 w-[180px]"
                          hover={true}
                          hoverclass="bg-black-900"
                          onClick={(e) => {
                            navigate("/all-products");
                          }}
                        >
                          {t("sameDashDayDelivery")}
                        </Button>
                      </div>
                      <Line className="bg-black-900 h-[94px] w-px xs:h-px xs:w-full" />
                    </div>
                    <div className="flex flex-col gap-3.5 items-center justify-start px-[30px] py-[20px] xs:w-full">
                      
                      <Button
                        className="bg-transparent hover:border hover:border-black-900 cursor-pointer font-nunitomedium h-12 py-2.5 rounded text-base text-center text-black-900 w-[180px]"
                        hover={true}
                        hoverclass="bg-black-900"
                        onClick={() => {
                          if (auth?.id == 0) {
                            navigate("/login");
                          } else {
                            navigate("/design-my-cake");
                          }
                        }}
                      >
                        {t("customizedCakes")}
                      </Button>
                    </div> */}
                  </div>
                </div>
                <div className="mt-[64px] flex gap-9 items-center justify-start my-element fade-animation relative sm:justify-center">
                  <Button
                    className="bg-black-900 hover:border group hover:border-black-900 cursor-pointer font-nunitomedium h-12 py-2.5 rounded text-base text-center text-white-A700 w-[180px]"
                    onClick={(e) => {
                      navigate("/all-products");
                    }}
                  >
                    <div className="flex items-center gap-1.5">
                      <Img
                        src="/images/cup-cake.svg"
                        alt="icon"
                      />{t("shopNow")}
                    </div>

                  </Button>
                  <Img
                    src="/images/Vector78945.svg"
                    className="-mt-6 sm:absolute -right-24"
                    alt="icon"
                  />
                </div>
              </div>
              <div className="relative w-1/2 sm:absolute sm:m-auto z-10 sm:inset-0 sm:w-full sm:h-full">
                <Img
                  className="w-full max-w-[572px] pulse sm:max-w-full sm:h-full object-contain sm:opacity-50"
                  src="/images/home-banner-img-1.png"
                  alt="cake"
                />
                <Img
                  className="w-full max-w-[572px] absolute h-full inset-0  moving-image  sm:max-w-full sm:h-full object-contain object-right-bottom"
                  src="/images/home-banner-img-2.png"
                  alt="cake"
                  style={{
                    transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)`,
                  }}
                />
                <Img
                  className="w-full max-w-[572px] absolute h-full inset-0  moving-image sm:max-w-full sm:h-full object-contain object-right"
                  src="/images/home-banner-img-3.png"
                  alt="cake"
                  style={{
                    transform: `translate(${mousePosition.y}px, ${mousePosition.x}px)`,
                  }}
                />
                <Img
                  className="w-full max-w-[572px] absolute h-full inset-0  moving-image sm:max-w-full sm:h-full object-contain object-right-top"
                  src="/images/home-banner-img-4.png"
                  alt="cake"
                  style={{
                    transform: `translate(-${mousePosition.x}px, -${mousePosition.y}px)`,
                  }}
                />
                <Img
                  className="w-full max-w-[572px] absolute h-full inset-0  moving-image sm:max-w-full sm:h-full object-contain object-left-top"
                  src="/images/home-banner-img-5.png"
                  alt="cake"
                  style={{
                    transform: `translate(${mousePosition.y}px, ${mousePosition.x}px)`,
                  }}
                />
                <Img
                  className="w-full max-w-[572px] absolute h-full inset-0  moving-image sm:max-w-full sm:h-full object-contain object-left-bottom"
                  src="/images/home-banner-img-6.png"
                  alt="cake"
                  style={{
                    transform: `translate(-${mousePosition.x}px, -${mousePosition.y}px)`,
                  }}
                />
              </div>
            </div>
          </section>
          <section
            id="section-2"
            className="bg-teal-50 flex sm:flex-col flex-row items-center justify-evenly w-full relative overflow-hidden	"
          >
            <div className="bg-teal-50 flex md:flex-1 flex-col items-start justify-center px-24 md:pl-4 py-24  md:px-10 sm:px-4 sm:py-10 xs:py-0 w-1/2 md:w-full">
              <div className="max-w-[460px] w-full md:max-w-full ml-auto rtl:ml-0 rtl:mr-auto">
                <Text
                  className="leading-[56.00px] mt-11 sm:text-4xl md:text-[38px] text-[40px] text-black-900"
                  size="txtnunitoBold40Black900"
                >
                  <>
                    {t("showYourLoveComma")}
                    <br />
                    {t("sendThemCakeExclamationMark")}
                  </>
                </Text>
                <Text
                  className="leading-[24.00px] mt-[30px] text-base text-gray-700 w-full"
                  size="txtnunitoMedium16Gray700"
                >
                  {t("thereIsNoBetterGiftThanThatOfCakeDot")}
                </Text>
                <Button
                  className="bg-black-900 hover:border hover:border-black-900 cursor-pointer font-nunitomedium h-12 mb-[45px] mt-[50px] py-2.5 rounded text-base text-center text-white-A700 w-[180px]"
                  hover={true}
                  hoverclass="bg-teal-50"
                  onClick={(e) => {
                    navigate("/blog-list");
                  }}
                >
                  {t("discover")}
                </Button>
              </div>
            </div>
            <div className="bg-teal-A200 h-[657px] w-1/2 relative overflow-hidden sm:w-full">
              <Img
                className="h-full w-full mx-auto object-cover"
                src="/images/home-s2-img.jpg"
                alt="rectangle19148"
              />
            </div>
            <div
              id="box-animation"
              className={`absolute shadow-2xl bg-white-A700 bottom-[7%] flex flex-row gap-8 inset-x-[0] items-center justify-center mx-auto sm:px-5 px-8 py-3 rounded-[12px] shadow-bs3 w-[357px] my-element fade-animation`}
            >
              <Text
                className="leading-[24.00px] text-base text-black-900 rtl:text-right"
                size="txtnunitoMedium16"
              >
                <>
                  {t("everyoneDeservesLove")}
                  <br />
                  {t("andHappinessDotIncludeYou")}
                </>
              </Text>
              <Img
                className="h-[38px]"
                src="/images/img_favorite.svg"
                alt="favorite"
              />
            </div>
          </section>
          <section id="section-3" className="md:overflow-hidden">
            <div className="flex sm:flex-col flex-row font-nunitobold sm:gap-10 items-center justify-center max-w-[1100px] mt-[100px] sm:mt-[80px] mx-auto md:px-4 w-full relative">
              <Text
                className="leading-[52.00px] sm:text-3xl xs:text-2xl md:text-[38px] text-[40px] text-black-900 text-center font-nunitosemibold rtl:text-center z-[1]"
                size="txtnunitoBold40Black900"
              >
                <>
                  {t("firstOfItsKind")} <br className="sm:hidden" />
                  {t("streamlinedOrderingExperienceDot")}
                </>
              </Text>
              <Img
                className="h-[126px] absolute right-0 z-[0]"
                src="/images/img_vector_pink_200.svg"
                alt="vector"
              />
            </div>
            <Text
              className="leading-[24.00px] mt-2 text-base text-center text-gray-700 w-[44%] sm:w-[74%] xs:w-full mx-auto relative"
              size="txtnunitoMedium16Gray700"
            >
              {t(
                "weHaveCreatedACakeOptimizedOrderingExperienceForYouCommaWhereYourNotesAndWishesAreAlwaysFirstUppercaseDot"
              )}
            </Text>
            <div className="flex md:flex-col flex-row font-nunitosemibold lg:gap-[26px] gap-[46px] items-start justify-center mt-10 md:px-5 w-[1080px] md:w-full mx-auto relative z-[3]">
              <Img
                className="h-[17px] w-4 absolute ltr:left-0 rtl:right-0 top-0"
                src="/images/img_close_blue.svg"
                alt="close"
              />
              <List
                className="sm:flex-col flex-row lg:gap-[26px] gap-[46px] grid xs:grid-cols-1 grid-cols-2 md:mt-0 mt-[23px] w-[48%] md:w-full"
                orientation="horizontal"
              >
                <div className="hover-box flex flex-col items-center justify-start w-full my-element fade-animation">
                  <div className="mt-4 h-[200px] relative w-[200px] always-ltr">
                    <div className=" h-[200px] m-auto w-full">
                      <div className="absolute bg-yellow-100 bottom-[0] h-[200px]  inset-x-[0] mx-auto rounded-[50%] w-[200px]"></div>
                      <div className="hover-line text-xs bg-white-A700 flex flex-row h-[52px] items-end justify-between p-2.5 pt-0 whitespace-nowrap">
                        <Text className="text rotate-[-45deg] font-nunitoregular">
                          {t("youDreamTtWeDrawIt")}
                        </Text>
                        <Img
                          className="h-9 w-9 rotate-[45deg]"
                          src="/images/img_mdicake.svg"
                          alt="mdicake"
                        />
                      </div>
                    </div>
                    <div className="absolute bg-orange-50_01 flex flex-col h-max inset-[0] items-center justify-center m-auto p-[33px] sm:px-5 rounded-[50%] shadow-bs4 w-[150px] z-[999]">
                      <Img
                        className="h-[84px] w-[84px]"
                        src="/images/img_1000f21732642.svg"
                        alt="1000f21732642"
                      />
                    </div>
                  </div>
                  <Text
                    className="mt-[25px] text-black-900 sm:text-xl text-center text-[24px]"
                    size="txtnunitoSemiBold24"
                  >
                    {t("customizedCakes")}
                  </Text>
                  <Text
                    className="leading-[20.00px] mx-auto mt-4 text-center text-gray-700 text-sm w-full"
                    size="txtnunitoRegular14"
                  >
                    {t("weBringYourCakeDreamsToRealityDot")}
                  </Text>
                </div>
                <div className="hover-box flex sm:flex-1 flex-col items-center justify-start sm:mt-0 mt-[116px] md:mt-4 md:gap-0 w-full my-element fade-animation">
                  <div className=" h-[200px] relative w-[200px] always-ltr">
                    <div className=" h-[200px] m-auto w-full">
                      <div className="absolute bg-blue-100 h-[200px]  inset-[0] justify-center m-auto rounded-[50%] w-[200px]"></div>
                      <div className="hover-line text-xs bg-white-A700 flex flex-row h-[52px] items-end justify-between p-2.5 pt-0 whitespace-nowrap">
                        <Text className="text rotate-[-45deg] font-nunitoregular">
                          {t("expressAndColdDelivery")}
                        </Text>
                        <Img
                          className="h-9 w-9 rotate-[45deg]"
                          src="/images/img_mdicake.svg"
                          alt="mdicake"
                        />
                      </div>
                    </div>
                    <div className="absolute bg-light_blue-50 flex flex-col h-max inset-[0] items-center justify-center m-auto p-[33px] sm:px-5 rounded-[50%] shadow-bs4 w-[150px] z-[999]">
                      <Img
                        className="h-[84px] w-[84px]"
                        src="/images/img_badge014.svg"
                        alt="badge014"
                      />
                    </div>
                  </div>
                  <Text
                    className="mt-[25px] text-black-900 sm:text-xl text-center text-[24px]"
                    size="txtnunitoSemiBold24"
                  >
                    {t("sameDayDelivery")}
                  </Text>
                  <Text
                    className="leading-[20.00px] text-center text-gray-700 text-sm mt-4"
                    size="txtnunitoRegular14"
                  >
                    {t("weOfferMultipleModulesOfDelivery")}...
                    {t("alwaysUppercase")}
                  </Text>
                </div>
              </List>
              <List
                className="sm:flex-col flex-row lg:gap-[26px] gap-[46px] grid xs:grid-cols-1 grid-cols-2 md:mt-0 mt-[23px] w-[48%] md:w-full"
                orientation="horizontal"
              >
                <div className="hover-box flex flex-col items-center justify-start w-full my-element fade-animation">
                  <div className=" h-[200px] relative w-[200px] always-ltr">
                    <div className=" h-[200px] m-auto w-full">
                      <div className="absolute bg-teal-50_01 h-[200px]  inset-[0] justify-center m-auto rounded-[50%] w-[200px]"></div>
                      <div className="hover-line text-xs bg-white-A700 flex flex-row h-[52px] items-end justify-between p-2.5 pt-0 whitespace-nowrap">
                        <Text className="text rotate-[-45deg] font-nunitoregular">
                          {t("youShallNotPass")}
                        </Text>
                        <Img
                          className="h-9 w-9 rotate-[45deg]"
                          src="/images/img_mdicake.svg"
                          alt="mdicake"
                        />
                      </div>
                    </div>
                    <div className="absolute bg-gray-50_01 flex flex-col h-max inset-[0] items-center justify-center m-auto p-[33px] sm:px-5 rounded-[50%] shadow-bs4 w-[150px] z-[999]">
                      <Img
                        className="h-[84px] w-[84px]"
                        src="/images/img_badge011.svg"
                        alt="badge011"
                      />
                    </div>
                  </div>
                  <Text
                    className="mt-[25px] text-black-900 sm:text-xl text-center text-[24px]"
                    size="txtnunitoSemiBold24"
                  >
                    {t("qualityControl")}
                  </Text>
                  <Text
                    className="leading-[20.00px] text-center text-gray-700 text-sm mt-4"
                    size="txtnunitoRegular14"
                  >
                    {t(
                      "weEquippedOurProcessWithMultipleDeliveryCheckpointsToMakeSureYouReceiveYourCakeExactlyAsYouWantItExclamationMark"
                    )}
                  </Text>
                </div>
                <div className="hover-box flex sm:flex-1 flex-col items-center justify-start sm:mt-0 mt-[116px] md:mt-4 md:gap-0 w-full my-element fade-animation">
                  <div className="h-[200px] relative w-[200px] always-ltr">
                    <div className=" h-[200px] m-auto w-full">
                      <div className="absolute bg-red-100_01 bottom-[0] h-[200px]  inset-x-[0] mx-auto rounded-[50%] w-[200px]"></div>
                      <div className="hover-line text-xs bg-white-A700 flex flex-row h-[52px] items-end justify-between p-2.5 pt-0 whitespace-nowrap">
                        <Text className="text rotate-[-45deg] font-nunitoregular">
                          {t("alwaysHereForYou")}
                        </Text>
                        <Img
                          className="h-9 w-9 rotate-[45deg]"
                          src="/images/img_mdicake.svg"
                          alt="mdicake"
                        />
                      </div>
                    </div>
                    <div className="absolute bg-orange-50_02 flex flex-col h-max inset-[0] items-center justify-center m-auto p-[33px] sm:px-5 rounded-[50%] shadow-bs4 w-[150px] z-[999]">
                      <Img
                        className="h-[84px] w-[84px]"
                        src="/images/img_rate011.svg"
                        alt="rate011"
                      />
                    </div>
                  </div>
                  <Text
                    className="mt-[25px] text-black-900 sm:text-xl text-center text-[24px]"
                    size="txtnunitoSemiBold24"
                  >
                    <>
                      {t("superb")} <br className="md:hidden" />
                      {t("customerService")}
                    </>
                  </Text>
                  <Text
                    className="leading-[20.00px] text-center text-gray-700 text-sm mt-4"
                    size="txtnunitoRegular14"
                  >
                    {t(
                      "ourCustomerServiceAgentsAreThereForYouAllYouHaveToDoIsContactUs"
                    )}
                  </Text>
                </div>
              </List>
              <Img
                className="h-[17px] w-4 absolute left-[0] bottom-[0]"
                src="/images/img_close_red_300.svg"
                alt="close_One"
              />
            </div>
          </section>
          <section
            id="section-4"
            className="font-nunitomedium sm:h-auto h-[657px] mt-[100px] relative w-full overflow-hidden"
          >
            <div className="sm:h-auto h-[657px] m-auto w-full">
              <div className="sm:h-auto h-[657px] m-auto w-full">
                <div className="flex flex-col h-full items-center justify-start m-auto w-full">
                  <div className="flex flex-row sm:flex-col items-center justify-evenly w-full">
                    <div className="bg-pink-100_01 h-[657px] w-1/2 sm:w-full relative">
                      <Img
                        className="h-full w-full mx-auto object-cover"
                        src="/images/landing-s1-img.png"
                        alt="rectangle19148"
                      />
                    </div>
                    <div className="bg-gray-100 flex md:flex-1 flex-col items-start justify-center py-[55px] px-[80px] md:pr-4 md:px-10 sm:px-5 w-1/2 md:w-full h-full">
                      <div className="flex flex-col items-start justify-start max-w-[460px] w-full md:max-w-full mr-auto  rtl:mr-0 rtl:ml-auto">
                        <Text
                          className="leading-[52.00px] sm:text-4xl md:text-[38px] text-[40px] text-black-900 rtl:text-right"
                          size="txtnunitoBold40Black900"
                        >
                          <>
                            {t("highlyEfficient")}
                            <br />
                            {t("deliverySolution")}
                          </>
                        </Text>
                        <Text
                          className="leading-[24.00px] mt-[30px] text-base text-gray-700 w-full rtl:text-right"
                          size="txtnunitoMedium16Gray700"
                        >
                          {t(
                            "fromMidNightDeliveriesToPickupPointsAndMultipleDeliveryOptionsWeHaveBroughtTogetherTheDeliverySolutionBuiltToGiveYouTheBestCustomerExperienceDot"
                          )}
                        </Text>
                        <Button
                          className="bg-black-900 hover:border hover:border-black-900 cursor-pointer font-nunitomedium h-12 mt-[50px] py-2.5 rounded text-base text-center text-white-A700 w-[180px]"
                          hover={true}
                          hoverclass="bg-gray-100"
                          onClick={(e) => {
                            navigate("/blog-list");
                          }}
                        >
                          {t("discover")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute shadow-2xl bg-white-A700 top-[7%] flex flex-row gap-8 inset-x-[0] items-center justify-center mx-auto sm:px-5 px-8 py-3 rounded-[12px] shadow-bs3 w-[357px] my-element fade-animation">
              <Text
                className="leading-[24.00px] text-base text-black-900 rtl:text-right"
                size="txtnunitoMedium16"
              >
                <>
                  {t("everyoneDeservesLove")}
                  <br />
                  {t("andHappinessDotIncludeYou")}
                </>
              </Text>
              <Img
                className="h-[38px]"
                src="/images/img_favorite.svg"
                alt="favorite_One"
              />
            </div>
          </section>
          <section
            id="section-7"
            className="relative w-full mx-auto md:max-w-full md:px-4 bg-light_blue-50"
          >
            <div className="max-w-[1100px] w-full mx-auto py-28 sm:py-14 flex items-center justify-between md:gap-8 sm:flex-col">
              <div className="flex flex-col items-start sm:items-center sm:order-2 justify-start sm:justify-center max-w-[460px] w-full md:max-w-full mr-auto  rtl:mr-0 rtl:ml-auto">
                <Text
                  className="leading-[52.00px] sm:text-4xl md:text-[38px] text-[40px] text-black-900 rtl:text-right sm:text-center"
                  size="txtnunitoBold40Black900"
                >
                  <>
                    {t("designYourOwnCake")}
                  </>
                </Text>
                <Text
                  className="leading-[24.00px] mt-[30px] text-base text-gray-700 w-full rtl:text-right sm:text-center"
                  size="txtnunitoMedium16Gray700"
                >
                  {t(
                    "youOurNewAndUniqueOfferingWhereYouCanDesignYourOwnCakeExactlyAsYouWouldLikeIt"
                  )}
                </Text>
                <Button
                  className="bg-black-900 hover:border hover:border-black-900 cursor-pointer font-nunitomedium h-12 mt-[50px] py-2.5 rounded text-base text-center text-white-A700 w-[180px]"
                  hover={true}
                  hoverclass="bg-light_blue-50"
                  onClick={(e) => {
                    navigate("/design-my-cake");
                    // navigate("/design-my-custom-cake");
                  }}
                >
                  {t("designNow")}
                </Button>
              </div>
              <Img
                className="w-full max-w-[475px] sm:order-1"
                src="/images/design-your-cake.png"
                alt="design-your-cake"
              />
            </div>
          </section>
          <section
            id="section-5"
            className="max-w-[1100px] relative w-full mx-auto md:max-w-full md:px-4"
          >
            <div className="flex sm:flex-col flex-row font-nunitobold sm:gap-5 items-start justify-center mt-[100px]  w-full">
              <Img
                className="h-14 absolute left-0"
                src="/images/img_vector.svg"
                alt="vector_One"
              />
              <Text
                className="leading-[52.00px] sm:text-4xl xs:text-2xl md:text-[38px] text-[40px] text-black-900 text-center w-full"
                size="txtnunitoBold40Black900"
              >
                <>
                  {t("wePostTrendsAndTutorials")}
                  <br />
                  {t("forModernCakesMakingProcess")}
                </>
              </Text>
              <Img
                className="h-[37px] sm:ml-[0] ml-[136px] sm:mt-0 mt-[52px] absolute right-0"
                src="/images/img_alarm.svg"
                alt="alarm"
              />
            </div>
            <Text
              className="mt-5 text-base text-center text-gray-700 w-[54%] sm:w-[74%] xs:w-full mx-auto"
              size="txtnunitoMedium16Gray700"
            >
              <>
                {t(
                  "hereYouAndWillFindArticlesOnTheLatestWellnessTrendsAndTopicsDeliciousPlantBasedRecipesTipTricksAndMuchMoreExclamationMark"
                )}
              </>
            </Text>
            <div className="">
              <List className="w-full" orientation="horizontal">
                {blogItemCount > 4 ? (
                  <div className="">
                    <Slider
                      autoPlay
                      disableButtonsControls
                      autoPlayInterval={8000}
                      activeIndex={sliderState}
                      responsive={{
                        0: { items: 1 },
                        550: { items: 2 },
                        768: { items: 2 },
                        992: { items: 3 },
                        1050: { items: 4 },
                      }}
                      onSlideChanged={(e) => {
                        setsliderState(e?.item);
                      }}
                      ref={sliderRef}
                      className="flex gap-[30px] max-w-[1110px] mt-[50px] mx-auto  xs:mx-0 w-full"
                      items={blogList.map((items, index) => {
                        return (
                          <HomeBlog
                            keys={index}
                            data={items}
                            userimage={items?.imageLink}
                            likeBlog={likeBlog}
                            viewcount={CUSTOM.formatViewCount(items?.views)}
                            likecount={CUSTOM.formatViewCount(items?.likes)}
                            title={CUSTOM.getdataByLangKey(
                              items?.blogLocales,
                              "",
                              "title"
                            )}
                            description={CUSTOM.getdataByLangKey(
                              items?.blogLocales,
                              "",
                              "shortDescription"
                            )}
                          />
                        );
                      })}
                      renderDotsItem={({ isActive }) => {
                        if (isActive) {
                          return (
                            <div className="inline-block cursor-pointer h-2 bg-black-900 w-4 rounded" />
                          );
                        }
                        return (
                          <div
                            className="inline-block cursor-pointer rounded-[50%] h-2 bg-gray-300 w-2"
                            role="button"
                            tabIndex={0}
                          />
                        );
                      }}
                    />
                    <PagerIndicator
                      className="flex h-2 justify-center mt-[50px] mx-auto w-20"
                      count={blogItemCount}
                      activeCss="inline-block cursor-pointer h-2 bg-black-900 w-4 rounded"
                      activeIndex={sliderState}
                      inactiveCss="inline-block cursor-pointer rounded-[50%] h-2 bg-gray-300 w-2"
                      sliderRef={sliderRef}
                      selectedWrapperCss="inline-block md:ml-[0] mx-[4.00px] sm:ml-[0]"
                      unselectedWrapperCss="inline-block md:ml-[0] mx-[4.00px] sm:ml-[0]"
                    />
                  </div>
                ) : (
                  <>
                    {blogList.length ? (
                      <List
                        className="gap-[30px] grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 grid-cols-4 justify-center max-w-[1110px] mt-[31px] mx-auto md:px-5 w-full mb-8"
                        orientation="horizontal"
                      >
                        {blogList.map((items, index) => {
                          return (
                            <HomeBlog
                              keys={index}
                              data={items}
                              userimage={items?.imageLink}
                              likeBlog={likeBlog}
                              viewcount={CUSTOM.formatViewCount(items?.views)}
                              likecount={CUSTOM.formatViewCount(items?.likes)}
                              title={CUSTOM.getdataByLangKey(
                                items?.blogLocales,
                                "",
                                "title"
                              )}
                              description={CUSTOM.getdataByLangKey(
                                items?.blogLocales,
                                "",
                                "shortDescription"
                              )}
                            />
                          );
                        })}
                      </List>
                    ) : null}
                  </>
                )}
              </List>
            </div>
          </section>
          <section
            id="section-6"
            className="bg-cover bg-no-repeat flex flex-col font-nunitomedium h-[344px] md:h-auto items-start rtl:items-end justify-start max-w-[1110px] mt-[73px] mb-[64px] mx-auto p-[52px] md:px-5 w-full"
            style={{ backgroundImage: "url('images/img_group186.svg')" }}
          >
            <div className="flex flex-col gap-8 items-start justify-start ml-7 md:ml-[0] w-[44%] md:w-full">
              <div className="flex flex-col gap-2 items-start justify-start w-full">
                <Text
                  className="leading-[52.00px] sm:text-4xl md:text-[38px] text-[40px] text-black-900 w-full rtl:text-right"
                  size="txtnunitoBold40Black900"
                >
                  {t("sendYourGiftToLovedOnes")}
                </Text>
                <Text
                  className="leading-[24.00px] text-base text-gray-700 w-[96%] sm:w-full rtl:text-right"
                  size="txtnunitoMedium16Gray700"
                >
                  {t(
                    "addAPersonalizedMessageOrSongAlongWithTheGiftToMakeItMoreSpecial"
                  )}
                </Text>
              </div>
              <Button
                className="bg-black-900 hover:border hover:border-black-900 cursor-pointer h-12 py-2.5 rounded text-base text-center text-white-A700 w-40"
                hover={true}
                hoverclass="bg-light_blue-50"
                onClick={(e) => {
                  navigate("/all-products");
                }}
              >
                {t("sendGift")}
              </Button>
            </div>
          </section>
          <Footer className="flex font-nunitoregular items-center justify-center mt-[65px] md:px-5 w-full" />
        </div>
      </div>
    </>
  );
};

export default HomePage;
