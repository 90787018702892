import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Gift } from "popups/Gift";
import { useDispatch, useSelector } from "react-redux";
import * as CUSTOM from "../../../../../utils/helper/custom";
import { Checkbox } from "@mui/material";
import * as htmlToImage from "html-to-image";
import { changeTrigger } from "redux/reducers/trigger";
import { AddToMyOccasionAddNew } from "popups/AddToMyOccasionAddNew";
import {
  Button,
  Img,
  MobileInput,
  Input,
  Text,
  SelectBoxNew,
} from "components";
import {
  updateOrderData,
  getOrderData,
} from "../../../../../redux/reducers/orderData";

const SendAsAGift = (props) => {
  const { occasionOptions } = props;
  const orderData = useSelector(getOrderData);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [giftOpen, setgiftOpen] = useState(false);
  const [isLastOccassion, setIsLastOccassion] = useState(false);
  const [addToMyOccasionAddNewOpen, setAddToMyOccasionAddNewOpen] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [errors, setErrors] = useState({});
  const [cardForm, setCardForm] = useState({
    sender_name: "",
    recipient_name: "",
    country_code: "+966",
    mobile_no: "",
    email: "",
    occasion_id: 0,
    occasion_name: "",
    message: "",
    link: "",
    show_sender_name: 0,
  });

  /**
   * get occation name by id
   */
  const setoccasion = (id) => {
    let data = occasionOptions.find((item) => item.value == id);
    if (data) {
      setCardForm({ ...cardForm, occasion_id: id, occasion_name: data?.label });
    }
  };

  /**
   * gift  popup opem
   */
  const GiftOpenclick = () => {
    setgiftOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };

  /**
   * send gift form validations
   */
  const validForm = (data) => {
    let error = {};
    if (data?.mobile_no) {
      if (data?.mobile_no.length != 9) {
        error.mobile_no = t("mobile_no_length_error");
      }
    } else {
      error.mobile_no = t("mobile_no_required");
    }
    if (!data?.recipient_name) {
      error.recipient_name = t("recipient_name_required");
    }
    if (!data?.sender_name) {
      error.sender_name = t("sender_name_required");
    }

    if (!data?.occasion_id || data?.occasion_id == 0) {
      error.occasion_id = t("occasion_is_required");
    }
    if (data?.email) {
      if (!CUSTOM.isValidField(data?.email, "email")) {
        error.email = t("email_name_required");
      }
    }
    // else {
    //   error.email = t("email_name_required");
    // }
    if (!data?.message) {
      error.message = t("last_name_required");
    }
    // if (!data?.link) {
    //   error.link = t("url_is_required");
    // } else {
    //   if (!CUSTOM.isValidField(data?.link, "url")) {
    //     error.link = t("url_is_required");
    //   }
    // }
    if (data?.link && !CUSTOM.isValidField(data?.link, "url")) {
      error.link = t("url_is_required");
    }
    return error;
  };

  /*
   * set address data
   */
  // const deliverHear = () => {
  //   dispatch(
  //     updateOrderData({
  //       ...orderData,
  //       delivery_mode_show: "hide",
  //       home_office: {},
  //       buying_for_love: cardForm,
  //       pageStep: 4,
  //       delivery_mode: "buying_for_love",
  //     })
  //   );
  // };


  /**
   * convert gift image
   */
  const convertGiftImage = async () => {
    const giftHtml = document.getElementById("gift-png-image-html");
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    await delay(500);
    if (giftHtml) {
      try {
        const dataUrl = await htmlToImage.toPng(giftHtml)
        return dataUrl;
      } catch (error) {
        return "";
      }
    }
  }

  /**
   * send gift data
   */
  const saveGiftData = async () => {
    let mobileValidate = CUSTOM.isValidMobile(cardForm?.mobile_no, t)
    if (Object.keys(mobileValidate).length > 0) {
      setErrors(mobileValidate);
      return;
    }
    if (Object.keys(validForm(cardForm)).length < 1) {
      let cardImage = await convertGiftImage();
      dispatch(
        updateOrderData({
          ...orderData,
          delivery_mode_show: "hide",
          home_office: {},
          buying_for_love: { ...cardForm, cardHtmlImage: cardImage },
          pageStep: 4,
          delivery_mode: "buying_for_love",
        })
      );
    }
    setgiftOpen(false);
    CUSTOM.scrollTopBody();
    document.body.classList.remove("overflow-hidden");
    document.getElementById("header-box").classList.remove("relative");
  };

  /**
   * runtime check input field validations
   */
  useEffect(() => {
    let status = true;
    if (Object.keys(validForm(cardForm)).length < 1) {
      status = false;
    }
    setDisabledBtn(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardForm]);

  /**
   * click and check tab
   */
  useEffect(() => {
    if (orderData?.delivery_mode == "buying_for_love") {
      setCardForm(orderData?.buying_for_love);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.delivery_mode_show]);

  /**
   * refetch data
   */
  useEffect(() => {
    if (addToMyOccasionAddNewOpen == "refetch") {
      dispatch(changeTrigger("loadGiftOccasionList"));
      setIsLastOccassion(true);
      setAddToMyOccasionAddNewOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToMyOccasionAddNewOpen]);

  useEffect(() => {
    if (isLastOccassion && occasionOptions.length > 0) {
      let value = occasionOptions[0]?.value;
      setCardForm({ ...cardForm, occasion_id: value });
      setIsLastOccassion(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occasionOptions]);

  /**
   * update data in state when reload page
   */
  useEffect(() => {
    if (orderData?.buying_for_love) {
      setCardForm(orderData?.buying_for_love);
    }
  }, [orderData?.buying_for_love]);

  return (
    <>
      <div className="mt-2.5 w-full">
        <Text className="text-base text-black-900 font-nunitomedium mb-6">
          {t("sendAsAGift")}
        </Text>
        <div className="w-full">
          <div className="bg-light_blue-50 flex flex-row gap-3 items-center justify-start p-3 my-6 rounded w-full">
            <Img
              className="h-6 ml-2 rtl:ml-0 rtl:mr-2 sm:ml-[0] w-6"
              src="/images/img_antdesigninfo_black_900.svg"
              alt="antdesigninfo"
            />
            <Text className="leading-4 text-black-900 text-xs w-full font-nunitoregular max-w-[80%]">
              {t(
                "onlyTheRecipientMayChooseTheDeliveryDateTimeAndPlaceWhenGivingTheseItemsAsGift"
              )}
            </Text>
          </div>
          <div className="w-full flex flex-row gap-x-5 xs:flex-col">
            <Input
              required
              label={t("recipientName")}
              value={cardForm?.recipient_name}
              onChange={(e) => {
                setCardForm({
                  ...cardForm,
                  recipient_name: CUSTOM.strFormat(e.target.value, "max:50"),
                });
              }}
            />
            <MobileInput
              required
              label={t("whatsappMobileNumber")}
              value={cardForm?.mobile_no}
              onChange={(e) => {
                setErrors({});
                setCardForm({
                  ...cardForm,
                  mobile_no: CUSTOM.strFormat(e.target.value, "max:9|number"),
                });
              }}
              error={errors?.mobile_no}
            />
          </div>
          <div className="w-full flex flex-row gap-x-5 xs:flex-col">
            <Input
              label={t("email")}
              value={cardForm?.email}
              onChange={(e) => {
                setCardForm({
                  ...cardForm,
                  email: CUSTOM.strFormat(e.target.value, "max:100"),
                });
              }}
            />
            <div className="w-full">
              <SelectBoxNew
                required
                options={occasionOptions}
                label={t("occasion*")}
                value={cardForm?.occasion_id}
                onChange={(e) => {
                  setoccasion(e.target.value);
                }}
              />
              <Button
                className="flex flex-1 flex-row items-center justify-start w-full gap-2 relative top-[-20px]"
                onClick={(e) => {
                  setAddToMyOccasionAddNewOpen(true);
                }}
              >
                <Img
                  className="h-6 w-6"
                  src="/images/img_plus_pink_800.svg"
                  alt="location"
                />
                <Text className="common-pointer text-center text-pink-800 text-sm w-auto font-nunitomedium">
                  {t("addNewOccasion")}
                </Text>
              </Button>
            </div>
          </div>
          <div className="w-full flex flex-row gap-x-5 xs:flex-col">
            <Input
              required
              multiline
              minRows={3}
              value={cardForm?.message}
              label={t("typeYourCardMessageHere")}
              onChange={(e) => {
                setCardForm({
                  ...cardForm,
                  message: CUSTOM.strFormat(e.target.value, "max:180"),
                });
              }}
            />
          </div>
          <div className="w-full flex flex-row items-center gap-x-5 mb-6">
            <Checkbox
              checked={cardForm?.show_sender_name == 1}
              onChange={(e) => {
                if (e.target.checked) {
                  //alert(1);
                  setCardForm({ ...cardForm, show_sender_name: 1 });
                } else {
                  setCardForm({ ...cardForm, show_sender_name: 0 });
                }
              }}
            />
            <Text className="text-base text-black-900 font-nunitomedium  ">
              {t("dontshownameoncart")}
            </Text>
            {/* <SwitchCustom
              checked="false"
              onChange={(e) => {
                alert();
                // if (e.target.checked) {
                //   setCardForm({ ...cardForm, show_sender_name: 1 });
                // } else {
                //   setCardForm({ ...cardForm, show_sender_name: 0 });
                // }
              }}
            /> */}
          </div>
          <div className="w-full flex flex-row gap-x-5 xs:flex-col">
            <Input
              required
              label={t("senderName")}
              value={cardForm?.sender_name}
              onChange={(e) => {
                setCardForm({
                  ...cardForm,
                  sender_name: CUSTOM.strFormat(e.target.value, "max:50"),
                });
              }}
            />
          </div>
          <div className="w-full flex flex-row gap-x-5 xs:flex-col">
            <Text className="text-base text-black-900 font-nunitomedium mt-2">
              {t("shareYourFeelingsWithA")}
            </Text>
          </div>
          <div className="w-full flex flex-row gap-x-5 mt-3 xs:flex-col">
            <Input
              startAdornment={
                <Img
                  className="mr-3 rtl:mr-0 rtl:ml-3 mt-1"
                  src="/images/img_iconeditorinsertlink.svg"
                  alt=""
                />
              }
              value={cardForm?.link}
              onChange={(e) => {
                setCardForm({
                  ...cardForm,
                  link: CUSTOM.strFormat(e.target.value, "max:500"),
                });
              }}
              placeholder={t("pasteALinkToASongOrVideo")}
            />
          </div>
          <div className="w-full max-w-[175px] mt-3 flex flex-row gap-4 mb-[18px]">
            <Button
              className="flex-1 mx-auto min-w-[100px]"
              size="lg"
              variant="FillBlack"
              hover={true}
              hoverclass="bg-white-A700"
              disabled={disabledBtn}
              onClick={GiftOpenclick}
            >
              {t("continue")}
            </Button>
            {/* <Button
              className="w-auto min-w-[140px] mx-auto xs:min-w-[unset] xs:w-full"
              size="lg"
              variant="OutlineBlack"
              onClick={GiftOpenclick}
              disabled={disabledBtn}
              hover={true}
              hoverclass="bg-black-900"
            >
              {t("preview")}
            </Button> */}
          </div>
        </div>
      </div>
      {giftOpen === true ? (
        <Gift closepopup={setgiftOpen} saveGiftData={saveGiftData} Giftdata={cardForm} type="previewCard" />
      ) : null}

      {addToMyOccasionAddNewOpen && (
        <AddToMyOccasionAddNew
          closepopup={setAddToMyOccasionAddNewOpen}
          modalTopIcon="/images/add_to-occasion-icon.svg"
          OccasionTitle={t("addNewOccasion")}
          OccasionParagraph={t("enterBelowDetailsToAddANewOccasionDot")}
        />
      )}
    </>
  );
};

export default SendAsAGift;
