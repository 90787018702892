import { Button, Img, Line, List, Text } from "components";
import DesignMyOwnCakeQuestionOneHeader from "components/DesignMyOwnCakeQuestionOneHeader";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import * as CUSTOM from "../../utils/helper/custom";
import * as API from "../../utils/helper/Enum";
import { useNavigate } from "react-router-dom";
import {
  addDeleteGetLocalStorage,
  storageKeys,
} from "../../utils/global/localData";

const DesignMyOwnCakeSummaryPage = ({
  setActiveId,
  isEdit,
  allInputData,
  questionsData,
}) => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [totalEstimatePrice, setTotalEstimatePrice] = useState(0);
  const [totalTime, setTotalTime] = useState("");
  const navigate = useNavigate();

  /**
   * get group name from list
   */
  const getGroupName = useMemo(
    () => (id) => {
      const groupdata = questionsData.find((items) => items?.id === id);
      return groupdata
        ? CUSTOM.getdataByLangKey(groupdata?.modifierGroupLocales, "", "name")
        : "";
    },
    [questionsData]
  );

  /**
   * get gropup item name from list
   */
  const getGroupItemsName = useMemo(
    () => (id, arrayGroupItem) => {
      const groupdata = questionsData.find((items) => items?.id === id);
      if (!groupdata) {
        return "";
      }
      let allItemName = [];
      groupdata?.modifierGroupItems.forEach((items) => {
        if (arrayGroupItem.includes(items?.id)) {
          allItemName.push(
            CUSTOM.getdataByLangKey(items?.modifierGroupItemLocales, "", "name")
          );
        }
      });
      return allItemName.join(", ");
    },
    [questionsData]
  );

  /**
   * save data
   */
  const saveData = async (type) => {
    try {
      dispatch(changeLoader(true));
      if (type == "saveforlatter") {
        try {
          /**
           * Data for segment
           */
          let response = await globalRequest(
            API?.CUSTOMCAKE?.SAVE_FOR_LATTER,
            "post",
            allInputData,
            {},
            true
          );
          response = response?.data;
          if (response?.status == "SUCCESS") {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarMessage: response?.message,
                snackbarState: "success",
              })
            );
            addDeleteGetLocalStorage(
              storageKeys.ORDER_TAB,
              "mycustomcake",
              "add",
              "single"
            );
            navigate("/my-order");
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarMessage: response?.message,
                snackbarState: "error",
              })
            );
          }
        } catch (e) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: e?.message,
              snackbarState: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      } else {
        let placeORderInput = {
          customCakeId: 0,
          device_type: "web",
          customCakeDetails: {
            cake_details: allInputData?.cake_details,
            upload_image_links: allInputData?.upload_image_links,
            image_link: allInputData?.image_link,
            text_on_cake: allInputData?.textOnCake,
          },
        };
        try {
          let response = await globalRequest(
            API?.CUSTOMCAKE?.PLACE_ORDER,
            "post",
            placeORderInput,
            {},
            true
          );
          response = response?.data;
          if (response?.status == "SUCCESS") {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarMessage: response?.message,
                snackbarState: "success",
              })
            );
            navigate("/my-order");
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarMessage: response?.message,
                snackbarState: "error",
              })
            );
          }
        } catch (e) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: e?.message,
              snackbarState: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
  };

  /**
   * update Data
   */
  const updateData = async (type) => {
    dispatch(changeLoader(true));
    if (type == "saveforlatter") {
      allInputData.text_on_cake = allInputData?.textOnCake;
      try {
        let response = await globalRequest(
          API?.CUSTOMCAKE?.UPDATE_CUSTOM_CAKE,
          "PUT",
          allInputData,
          {},
          true
        );
        response = response?.data;
        if (response?.status == "SUCCESS") {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: response?.message,
              snackbarState: "success",
            })
          );
          addDeleteGetLocalStorage(
            storageKeys.ORDER_TAB,
            "mycustomcake",
            "add",
            "single"
          );
          navigate("/my-order");
        } else {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: response?.message,
              snackbarState: "error",
            })
          );
        }
      } catch (e) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: e?.message,
            snackbarState: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    }
  };

  /**
   * get price and estmate time
   */
  useEffect(() => {
    if (!allInputData) return;
    let totalEstimatePrice = 0;
    let time = 0;
    allInputData.cake_details.forEach((gitems) => {
      const groupdata = questionsData.find(
        (items) => items?.id === gitems?.group_id
      );
      if (groupdata) {
        groupdata.modifierGroupItems.forEach((itemsItem) => {
          if (gitems.items.includes(itemsItem?.id)) {
            time += itemsItem?.prepTime;
            totalEstimatePrice += itemsItem.price;
          }
        });
      }
    });
    setTotalTime(CUSTOM.minutsToHour(time));
    setTotalEstimatePrice(totalEstimatePrice);
  }, [allInputData, questionsData]);

  return (
    <>
      <div className="bg-gray-50_02 flex flex-col font-nunitomedium gap-9 items-start justify-start mx-auto pb-6 w-full">
        <div className="flex flex-col items-center w-full">
          <DesignMyOwnCakeQuestionOneHeader
            className="bg-white-A700 flex flex-row items-center justify-start p-1.5 shadow-bs w-full"
            p0105=""
            p0={"Edit"}
            setActiveId={setActiveId}
            isEdit={isEdit ? true : false}
            exittext={t("exit")}
            allInputData={allInputData}
          />
          <div className="h-1 md:px-5 relative w-full">
            <Line className="bg-gray-300 h-1 m-auto w-full" />
            <Line className="absolute bg-pink-800 h-1 inset-[0] justify-center m-auto w-full" />
          </div>
        </div>
        <div className="flex flex-col items-end md:px-10 sm:px-5 px-[153px] w-full">
          <div className="sm:h-auto md:h-[867px] h-[921px] relative w-[84%] md:w-full">
            <div className="absolute sm:relative bg-white-A700 border border-gray-300 border-solid flex flex-col h-max inset-y-[0] items-center justify-start left-[3%] my-auto p-[33px] sm:px-5 rounded w-3/4 sm:w-[94%]">
              <div className="flex flex-col items-center justify-start mb-2 w-[95%] md:w-full">
                <Text
                  className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
                  size="txtnunitoSemiBold24"
                >
                  {t("customCakeSummary")}
                </Text>
                <Text
                  className="leading-[20.00px] mt-[13px] text-black-900 text-center text-sm w-[86%] sm:w-full"
                  size="txtnunitoRegular14Black900"
                >
                  {t(
                    "youCanEitherSubmitAndPlaceTheOrderByDoingThePaymentOrElseSaveYourCakeForFuturePurposeDot"
                  )}
                </Text>
                <div className="flex flex-col font-nunitomedium items-start justify-start mt-[30px] w-full">
                  <List
                    className="sm:flex-col flex-row gap-5 grid sm:grid-cols-1 grid-cols-2 justify-center w-full"
                    orientation="horizontal"
                  >
                    <div className="bg-light_blue-50 flex flex-1 flex-row gap-4 items-center justify-start sm:ml-[0] p-5 rounded w-full">
                      <Img
                        className="h-8 w-8"
                        src="/images/img_clock_black_900.svg"
                        alt="clock"
                      />
                      <div className="flex flex-col gap-1 items-start justify-start w-auto">
                        <Text
                          className="text-base text-black-900 w-auto"
                          size="txtnunitoMedium16"
                        >
                          {t("estimatedPrepDotTimeColon")}
                        </Text>
                        <Text
                          className="text-base text-gray-700 w-auto"
                          size="txtnunitoRegular16Gray700"
                        >
                          {totalTime} {t("hrsLowerCase")}
                        </Text>
                      </div>
                    </div>
                    <div className="bg-light_blue-50 flex flex-1 flex-row gap-4 items-center justify-start sm:ml-[0] p-5 rounded w-full">
                      <Img
                        className="h-8 w-8"
                        src="/images/img_television.svg"
                        alt="television"
                      />
                      <div className="flex flex-col gap-1 items-start justify-start w-auto">
                        <Text
                          className="text-base text-black-900 w-auto"
                          size="txtnunitoMedium16"
                        >
                          {t("estimatedPriceColon")}
                        </Text>
                        <Text
                          className="text-base text-gray-700 w-auto"
                          size="txtnunitoRegular16Gray700"
                        >
                          {t("sar")} {CUSTOM.setPrice(totalEstimatePrice)}
                        </Text>
                      </div>
                    </div>
                  </List>
                  <div className="flex flex-col gap-6 h-auto items-start justify-between mt-8 w-auto sm:w-full">
                    {allInputData?.cake_details.map((items, index) => {
                      if (items?.items?.length == 0) return null; 
                      return (
                        <div
                          className="flex flex-col gap-1 items-start justify-start w-auto"
                          key={"choice-test" + index}
                        >
                          <Text
                            className="text-base text-black-900 w-auto"
                            size="txtnunitoMedium16"
                          >
                            {getGroupName(items?.group_id)}
                          </Text>
                          <Text
                            className="text-base text-gray-700 w-auto"
                            size="txtnunitoRegular16Gray700"
                          >
                            {getGroupItemsName(items?.group_id, items?.items)}
                          </Text>
                        </div>
                      );
                    })}
                  </div>
                  <Line className="bg-gray-300 h-px mt-7 w-full" />
                  <div className="flex flex-col gap-2.5 items-start justify-start mt-[30px] w-[97%] md:w-full">
                    {allInputData?.textOnCake ? (
                      <>
                        <Text
                          className="text-base text-black-900"
                          size="txtnunitoMedium16"
                        >
                          {t("nameoncake")}
                        </Text>
                        <div className="lflex flex-row gap-4 items-start justify-start w-auto sm:w-ful">
                          <Text
                            className="break-words text-base text-gray-700 w-auto"
                            size="txtnunitoRegular16Gray700"
                          >
                            {allInputData?.textOnCake}
                          </Text>
                        </div>
                      </>
                    ) : null}
                    <Text
                      className="text-base text-black-900"
                      size="txtnunitoMedium16"
                    >
                      {t("referenceImageSlashLink")}
                    </Text>
                    {allInputData?.image_link && (
                      <Text
                        className="text-base text-gray-700 w-auto break-all"
                        size="txtnunitoRegular16Gray700"
                      >
                        <a
                          href={"https://" + allInputData?.image_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {allInputData?.image_link}
                        </a>
                      </Text>
                    )}
                    <div className="flex gap-4 items-start justify-start w-auto sm:w-full flex-wrap">
                      {allInputData?.upload_image_links.map((items, index) => {
                        return (
                          <Img
                            className={`${index}-imglist h-[100px] md:h-auto object-cover rounded-lg w-[100px] sm:w-[75px] sm:h-[75px]`}
                            src={
                              items.includes("://")
                                ? items
                                : process.env.REACT_APP_BASEURL + "/" + items
                            }
                            alt="rectangle19120"
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="flex sm:flex-col flex-row gap-5 items-center justify-center md:ml-[0] ml-[70px] mt-12 w-3/4 md:w-full">
                    {allInputData?.id ? (
                      <>
                        <Button
                          className="border border-black-900 border-solid cursor-pointer h-12 py-2.5 rounded text-base text-black-900 text-center w-[200px]"
                          onClick={(e) => {
                            updateData("saveforlatter");
                          }}
                        >
                          {t("update")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          className="border border-black-900 border-solid cursor-pointer h-12 py-2.5 rounded text-base text-black-900 text-center w-[200px]"
                          onClick={(e) => {
                            saveData("saveforlatter");
                          }}
                        >
                          {t("saveForLater")}
                        </Button>
                        <Button
                          className="bg-black-900 cursor-pointer h-12 py-2.5 rounded text-base text-center text-white-A700 w-[200px]"
                          onClick={(e) => {
                            saveData("placeOrder");
                          }}
                        >
                          {t("placeOrder")}
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Img
              className="absolute bottom-[0] h-[270px] object-cover right-[0] rtl:right-[auto] rtl:left-[0] w-[27%] sm:w-[20%] sm:h-auto"
              src="/images/img_birthdaycake.png"
              alt="birthdaycake"
            />
            <Img
              className="absolute h-11 right-[20%] sm:right-[-2%] top-[3%] w-11"
              src="/images/img_clock_yellow_700.svg"
              alt="clock"
            />
            <Img
              className="absolute h-14 left-[0] top-[10%]"
              src="/images/img_vector.svg"
              alt="vector"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignMyOwnCakeSummaryPage;
