import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Img, Text } from "components";
import { useTranslation } from "react-i18next";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    margin: 0,
    marginLeft: "-20px",
    padding: 0,
    boxShadow: "0 0 25px -5px rgb(0 0 0 / 0.1), 0 0 10px -6px rgb(0 0 0 / 0.1)",
    ".MuiTooltip-arrow": {
      color: "#FFFFFF", // Arrow color
      width: "28px", // Arrow width
      height: "16px", // Arrow height
      top: "-7px", // Position where arrow is pointing
      left: "12px!important", // Position where arrow is pointing
    },
  },
}));

const SliderProductBox = (props) => {
  const {
    CheckUomExistInCart,
    changeQuy,
    setRepeatOrderOpen,
    productId,
    openTooltip,
    setSliderProductId,
    setAddToCartOpen
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className={props.className} key={props?.key}>
        <div className="flex flex-col items-start rtl:items-end justify-start w-full">
          <div
            className="h-[220px] relative w-full cursor-pointer"
            onClick={() => navigate(`/productdetail/${props?.slug}`)}
          >
            <Img
              className="absolute h-[220px] inset-[0] justify-center m-auto object-cover rounded-lg w-full"
              alt="rectangle17564"
              src={props?.productimage}
            />

            {Number(props?.isBestSeller) == 1 ? (
              <div className="bg-red-50_01 font-nunitoregular min-w-[74px] py-1 px-2 rounded text-center text-gray-900_01 text-xs absolute mt-2 ml-2 rtl:ml-0 rtl:mr-2 top-0 ltr:left-0 rtl:right-0">
                {t("bestseller")}
              </div>
            ) : (
              ""
            )}

            {Number(props?.isRecommended) == 1 ? (
              <div className="bg-red-50_01 font-nunitoregular min-w-[74px] py-1 px-2 rounded text-center text-gray-900_01 text-xs absolute mt-2 ml-2 rtl:ml-0 rtl:mr-2 top-0 ltr:left-0 rtl:right-0">
                {t("recommended")}
              </div>
            ) : (
              ""
            )}

            {props?.productSortingBox && (
              <div className="bg-red-50_01 font-nunitoregular min-w-[74px] py-1 px-2 rounded text-center text-gray-900_01 text-xs absolute mt-2 ml-2 rtl:ml-0 rtl:mr-2 top-0 ltr:left-0 rtl:right-0">
                {props?.productsorting}
              </div>
            )}

            {props?.productRatingBox && (
              <div className="bg-red-50_01 flex items-center justify-center min-w-[67px] px-1 py-[3px] rounded absolute mb-2 ml-2 rtl:ml-0 rtl:mr-2 bottom-0 ltr:left-0 rtl:right-0">
                <Img
                  className="h-4 mb-px mr-1"
                  src="/images/img_star.svg"
                  alt="star"
                />
                <div className="font-nunitoregular text-gray-900_01 text-left text-xs">
                  {props?.productrating}
                </div>
              </div>
            )}
          </div>
          <Text
            className="mt-3 text-base text-black-900 cursor-pointer w-full rtl:text-right line-clamp-1"
            size="txtnunitoMedium16"
            onClick={() => navigate(`/productdetail/${props?.slug}`)}
          >
            {props?.productName}
          </Text>
          <div
            className="flex flex-row gap-2 items-center justify-start mt-[13px] w-full cursor-pointer"
            onClick={() => navigate("/productdetail")}
          >
            <Text
              className="text-base text-black-900"
              size="txtnunitoMedium16"
            >
              {props?.discountedPrice}
            </Text>
            <Text
              className="line-through text-gray-700 text-sm"
              size="txtnunitoRegular14"
            >
              {props?.originalPrice}
            </Text>
          </div>
          {!CheckUomExistInCart(productId) && (
            <div className="flex flex-col items-center justify-center mt-5 rounded w-full">
              <Button
                className="border border-black-900 border-solid text-black-900 flex flex-row gap-2 items-center justify-center pl-4 sm:pr-5 pr-6 py-3 rounded w-full"
                hover={true}
                hoverclass="bg-black-900"
                onClick={() => {
                  setSliderProductId(productId)
                  setAddToCartOpen(true);
                }}
              >
                <Img
                  className="h-5 w-5 btn-icon"
                  src="/images/img_mdicart.svg"
                  alt="mdicart"
                />
                <Text
                  className="text-base text-center w-auto"
                  size="txtnunitoMedium16"
                >
                  {t("addToCart")}
                </Text>
              </Button>
            </div>
          )}

          {CheckUomExistInCart(productId) && (
            <div className="border border-black-900 border-solid bg-black-900 flex flex-col items-center justify-center mt-5 rounded w-full">
              <div className="flex flex-row gap-2 items-center justify-center pl-4 sm:pr-5 pr-6 py-3 rounded w-full">
                <HtmlTooltip
                  placement="bottom-start"
                  open={openTooltip?.status && openTooltip?.id == productId}
                  arrow
                  title={
                    <>
                      <div className="py-4 px-5 w-full">
                        <Text className="leading-5 text-black-900 text-sm w-full font-nunitomedium">
                          {t("multiple_customization")}
                        </Text>
                      </div>
                    </>
                  }
                >
                  <div>
                    <Img
                      className="h-5 w-5 cursor-pointer"
                      src="/images/minus-white.svg"
                      alt="minus"
                      onClick={() => {
                        changeQuy(productId, "subtract");
                      }}
                    />
                  </div>
                </HtmlTooltip>

                {/* <Tooltip
                  classes={{ tooltip: classes.customTooltip }}
                  open={openTooltip}
                  onClose={handleTooltipClose}
                  title={t("multiple_customization")}
                  arrow
                  placement="bottom"
                ></Tooltip> */}
                <Text
                  className="text-base text-white-A700 text-center w-[100px]"
                  size="txtnunitoMedium16"
                >
                  {CheckUomExistInCart(productId)?.quantity}
                </Text>
                <Img
                  className="h-5 w-5 cursor-pointer"
                  src="/images/add-white.svg"
                  alt="add"
                  onClick={() => {
                    changeQuy(productId, "add");
                    setRepeatOrderOpen(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

SliderProductBox.defaultProps = {
  productimage: "",
  productSortingBox: "",
  productsorting: "",
  productRatingBox: "",
  productrating: "",
  productName: "",
  discountedPrice: "",
  originalPrice: "",
  buttonAddToCart: "",
  buttonAddRemoveProduct: "",
};

export default SliderProductBox;
