import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, CheckboxCustom, Img, Text } from "components";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import ProductCartCardBox from "components/ProductCartCardBox";
import Breadcrumb from "components/Breadcrumb";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import * as CUSTOM from "../../utils/helper/custom";
import * as APIS from "../../utils/helper/Enum";
import EmptyListOrder from "../ProfilePage/AllTabsOption/EmptyListOrder";
import { useEffect } from "react";
import { currentTrigger } from "redux/reducers/trigger";
let timer = null;
let priceFilter = CUSTOM.ProductPriceFilter();

const AllProductListPage = () => {
  const { t } = useTranslation();
  const loaderStatus = useSelector(currentTrigger);
  const dispatch = useDispatch();
  const [FilterOpen, setFilterOpen] = React.useState(false);
  const [apiLoadStatus, setApiLoadStatus] = useState(true);
  const [productList, setProductList] = useState([]);
  const [cartData, setCartData] = useState([]);
  let [offsetLimit, setOffsetLimit] = useState({ limit: 9, page: 1 });
  const [isLoadMore, setLoadMore] = useState(false);
  const [inputData, setInputData] = useState({
    subcat: [],
    price: [],
    order_by: "isRecommended",
    order_type: "desc",
    subcatid: 0,
    name: "",
    list: "",
  });
  const isLoading = useState(false)[0];

  const handleFilter = () => {
    setFilterOpen(!FilterOpen);
  };

  /**
   * get price in array
   */
  const priceByArray = () => {
    let indexes = inputData?.price;
    const categorizedRanges = [];
    let ranges = priceFilter;
    indexes.forEach((index) => {
      let k = 0;
      for (const range of ranges) {
        if (index == k) {
          categorizedRanges.push(
            `${range.min}-${range.max == "+" ? 9989864564545 : range.max}`
          );
          break;
        }
        k++;
      }
    });
    return categorizedRanges.join(",");
  };

  /**
   * get all products
   */
  const getAllProductList = async (type = "new") => {
    try {
      dispatch(changeLoader(true));
      let params = {
        price: priceByArray(inputData?.price),
        orderType: inputData?.order_type,
        orderBy: inputData?.order_by,
        page: offsetLimit?.page,
        limit: offsetLimit?.limit,
      };
      let response = await globalRequest(
        APIS?.PRODUCTS?.All_PRODUCTS,
        "get",
        {},
        { params: params },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setProductList((prevList) => [...prevList, ...response?.data?.data]);
        setLoadMore(
          response?.data?.data.length < offsetLimit?.limit ? false : true
        );
      } else {
        if (offsetLimit?.page == 1) {
          setProductList([]);
        }
        setLoadMore(false);
      }
    } catch (e) {
      setProductList([]);
    }
    dispatch(changeLoader(false));
    setApiLoadStatus(false);
  };

  /**
   * const load more api
   */
  const loadMoredata = () => {
    let pageCount = offsetLimit?.page + 1;
    offsetLimit.page = pageCount;
    setLoadMore(false);
    setOffsetLimit({ ...offsetLimit, page: pageCount });
    getAllProductList();
  };

  /**
   * Handle infinite scroll
   */
  const handleScroll = (e) => {
    const scrollHeight = e.target.documentElement.scrollHeight;
    const currentHeight =
      e.target.documentElement.scrollTop + window.innerHeight + 450;
    if (currentHeight + 1 >= scrollHeight) {
      let clickMore = document.getElementById("loadmoreItem");
      if (clickMore) {
        clickMore.click();
      }
    }
  };

  const returnActualprice = (actualPrice, sellingPrice) => {
    let result = "";
    if (!actualPrice || actualPrice == sellingPrice) {
      return result;
    }
    return actualPrice;
  };

   /**
   * first time call list api
   */
   useEffect(() => {
    setApiLoadStatus(true);
    setProductList([]);
    offsetLimit.page = 1;
    setOffsetLimit({ ...offsetLimit, page: 1 });
    dispatch(changeLoader(true));
    clearTimeout(timer);
    timer = setTimeout(() => {
      getAllProductList();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);

  useEffect(() => {
    setCartData(CUSTOM.getCartData());
  }, []);

  useEffect(() => {
    if (loaderStatus == "loadCartData") {
      setCartData(CUSTOM.getCartData());
    }
  }, [loaderStatus]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const filterStyle = !FilterOpen
    ? "sm:hidden"
    : "flex bg-white-A700 p-[16px] rounded-lg top-full absolute w-full z-50 m-0 sm:items-start sm:justify-start shadow-lg h-[296px] overflow-y-auto";
  return (
    <>
      <div className="bg-gray-50_02 flex flex-col font-nunitoregular items-center justify-start mx-auto w-full">
        <div className="flex flex-col items-center justify-start w-full max-w-[1110px] mx-auto">
          <Breadcrumb data={inputData} type="allProduct" />
          <div className="flex flex-row xs:flex-wrap font-nunitosemibold md:gap-10 xs:gap-4 items-center xs:items-start justify-between max-w-[1110px] mt-[11px] mx-auto md:px-5 w-full">
            <Text
              className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
              size="txtnunitoSemiBold24"
            >
              {t("allProducts")}
            </Text>
            <div className="flex items-center sortby-dropdown">
              <Text
                className="text-base text-gray-700 w-[106px]"
                size="txtnunitoRegular16"
              >
                {t("sortByColon")}
              </Text>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  {t("recommended")}
                </InputLabel>
                <Select
                  value={inputData?.order_by}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="category"
                  onChange={(e) => {
                    if (e.target.value) {
                      setInputData({
                        ...inputData,
                        order_by: e.target.value,
                        order_type: "desc",
                      });
                    }
                  }}
                >
                  <MenuItem value="isRecommended">{t("recommended")}</MenuItem>
                  <MenuItem value="high_to_low">{t("highToLow")}</MenuItem>
                  <MenuItem value="low_to_high">{t("lowToHigh")}</MenuItem>
                  <MenuItem value="isBestseller">{t("bestSeller")}</MenuItem>
                  <MenuItem value="customerRaiting">
                    {t("customerraiting")}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex sm:flex-col flex-row font-nunitoregular gap-[30px] items-start justify-start max-w-[1123px] my-[26px] mx-auto md:px-5 w-full">
            <div className="sm:h-auto relative w-[24%] sm:w-full">
              <div className="flex flex-col font-nunitomedium gap-[21px] justify-center m-auto w-full">
                <Text
                  className="text-base text-black-900 hidden sm:block"
                  size="txtnunitoMedium16"
                  onClick={handleFilter}
                >
                  {t("FilterUppercase")}11
                </Text>
                <div
                  className={`${filterStyle} flex flex-col font-nunitomedium gap-[21px] justify-center m-auto w-full`}
                >
                  <div className="flex flex-col gap-2 justify-start w-full">
                    <Text
                      className="text-base text-black-900 rtl:text-right"
                      size="txtnunitoMedium16"
                    >
                      {t("FilterByPrice")}
                    </Text>
                    <div className="flex flex-col font-nunitoregular relative">
                      {priceFilter?.map((priceItem, index) => {
                        let labels =
                          priceItem?.max != "+"
                            ? `${t("sar")} ${priceItem?.min} - ${t("sar")} ${
                                priceItem?.max
                              }`
                            : `${t("sar")} ${priceItem?.min} +`;
                        return (
                          <div
                            className="flex flex-row items-center justify-start"
                            key={`keyid-${index}-${Math.random()}`}
                          >
                            <CheckboxCustom
                              label={labels}
                              className="text-black_900 text-sm mr-0 text-left product-list-checkbox rtl:text-right"
                              checked={inputData?.price.includes(index)}
                              onChange={(e) => {
                                let id = index;
                                setInputData((prevData) => {
                                  const existingIndex =
                                    prevData.price.indexOf(id);
                                  if (existingIndex !== -1) {
                                    // ID exists, so remove it
                                    const newprice = [...prevData.price];
                                    newprice.splice(existingIndex, 1);
                                    return { ...prevData, price: newprice };
                                  } else {
                                    // ID doesn't exist, so add it
                                    const newprice = [...prevData.price, id];
                                    return { ...prevData, price: newprice };
                                  }
                                });
                              }}
                            />{" "}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-1 flex-col font-nunitomedium gap-[30px] items-start justify-start w-full md:w-full">
              <div className="flex flex-row items-center justify-start w-auto">
                {inputData?.price?.map((priceItemF) => {
                  let priceItem = priceFilter[priceItemF];
                  let labels =
                    priceItem?.max != "+"
                      ? `${t("sar")} ${priceItem?.min} - ${t("sar")} ${
                          priceItem?.max
                        }`
                      : `${t("sar")} ${priceItem?.min} +`;
                  return (
                    <Button
                      key={`price-p${priceItemF}`}
                      className="cursor-pointer flex h-8 items-center justify-center min-w-[181px] pl-3 mr-2 pr-2 rtl:mr-0 rtl:ml-2 py-1.5 rounded bg-red-50_01"
                      rightIcon={
                        <Img
                          className="h-[18px] mt-px ml-2 rtl:ml-0 rtl:mr-2"
                          src="/images/img_close_gray_900_01.svg"
                          alt="close"
                          onClick={(e) => {
                            setInputData((prevData) => {
                              const existingIndex =
                                prevData.price.indexOf(priceItemF);
                              if (existingIndex !== -1) {
                                // ID exists, so remove it
                                const newprice = [...prevData.price];
                                newprice.splice(existingIndex, 1);
                                return { ...prevData, price: newprice };
                              } else {
                                // ID doesn't exist, so add it
                                const newprice = [
                                  ...prevData.price,
                                  priceItemF,
                                ];
                                return { ...prevData, price: newprice };
                              }
                            });
                          }}
                        />
                      }
                    >
                      <Text
                        className="text-gray-900_01 text-left text-sm rtl:text-right"
                        size="txtnunitoMedium16"
                      >
                        {<>{labels}</>}
                      </Text>
                    </Button>
                  );
                })}
                {inputData?.price.length > 0 ? (
                  <Text
                    className="text-pink-800 text-xs ml-2 rtl:ml-0 rtl:mr-2 cursor-pointer whitespace-nowrap"
                    size="txtnunitoRegular12Pink800"
                    onClick={(e) => {
                      setInputData({ ...inputData, price: [] });
                    }}
                  >
                    {t("clearAllSmallBrackets")}
                  </Text>
                ) : null}
              </div>
              <div className="md:gap-5 gap-[30px] grid xs:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 grid-cols-3 justify-center min-h-[auto] w-full">
                {productList?.map((pitems, pindex) => {
                  return (
                    <ProductCartCardBox
                      productId={pitems?.id}
                      className="bg-white-A700 flex flex-1 flex-col items-center justify-start p-[16px] rounded-lg w-full"
                      productimage={CUSTOM.getImage(
                        pitems?.productUoms?.[0]?.productUomImages?.[0]
                          ?.image || ""
                      )}
                      isRecommended={pitems?.isRecommended}
                      isBestSeller={pitems?.isBestseller}
                      productRatingBox={!!pitems?.averageRating}
                      productrating={`${
                        pitems?.averageRating ? pitems?.averageRating : 0
                      } | ${pitems?.ratingCount ? pitems?.ratingCount : 0}`}
                      productName={`${CUSTOM?.getdataByLangKey(
                        pitems?.productLocales,
                        "",
                        "name"
                      )} - ${pitems?.productUoms?.[0]?.name || ""}`}
                      discountedPrice={
                        <>
                          {t("sar")}{" "}
                          {pitems?.productUoms?.[0]?.sellingPrice || ""}
                        </>
                      }
                      originalPrice={returnActualprice(
                        pitems?.productUoms?.[0]?.actualPrice,
                        pitems?.productUoms?.[0]?.sellingPrice
                      )}
                      buttonAddToCart
                      slug={pitems?.slug}
                      uomData={pitems?.productUoms?.[0]}
                      cartData={cartData}
                    />
                  );
                })}
              </div>
              {isLoadMore ? (
                <Button
                  id="loadmoreItem"
                  className="hide"
                  onClick={(e) => {
                    loadMoredata();
                  }}
                >
                  Load More
                </Button>
              ) : null}
              {isLoading ? (
                <div className="flex h-12 items-center justify-center mt-4 mb-10 rounded-[50%] w-full">
                  <Img
                    src="/images/Loaderanim.png"
                    className="animate-spin"
                    alt="loaderanim"
                  />
                </div>
              ) : null}
              {productList?.length == 0 && apiLoadStatus == false ? (
                <>
                  <EmptyListOrder paragraph={t("noProductFound")} />
                </>
              ) : null}
            </div>
          </div>
          <Footer className="flex font-nunitoregular items-center justify-center mt-[54px] md:px-5 w-full" />
        </div>
      </div>
    </>
  );
};

export default AllProductListPage;
